import styled from 'styled-components'
import { fontLi } from '../../../common'
import { media } from '../../../../utils'
import React from 'react'
import { Title } from '../common/Title'

const SubTitle = styled(Title)`
  font-size: 25px;
  font-weight: 300;
  ${
    media.phone`
       font-size: 20px
    `
    }
`

const HelpText = styled(SubTitle)`
  font-size: 15px;
  ${
    media.phone`
       font-size: 13px
    `
    }
`

const ListItem = styled(fontLi)`
  list-style-type: circle;
  list-style-position: inside;
  text-align: left;
  align-self: stretch;
  font-size: 21px;
  color: white;
  line-height: 1.5em;
  font-weight: 300;
  ${
    media.phone`
       font-size: 15px
    `
    }
`
const VSpace = styled.div`
  height: ${p => p.space ? p.space : 40}px;
`

export const DevelopmentPolicy = () => {
    const content = [
        'Built for managers.',
        'Easy and Fun to use.',
        'The More you use, the more meaningful and valuable results.',
        'Affordable. Free up to 100 WinCs *'
    ]

    return (<>
        <Title>{'Development Policy'}</Title>
        {
            content.map((c, i) => <ListItem key={i}>{c}</ListItem>)
        }
        <VSpace/>
        <HelpText>{"* Because we think less than 100 WinCs registered, it doesn't provide much value to you."}</HelpText>
    </>)
}
