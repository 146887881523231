import React, { Component } from 'react'
import styled from 'styled-components'
import { Button, ClearBGButton, fontDiv, FormContainer, OnBoardContainer } from "../../common/index"
import { Validator } from "../../../utils/Validator"
import { InputTextField } from "../../common/InputTextField"
import { media, showError } from '../../../utils'

const PageContainer = styled(FormContainer)`
  padding: 40px;
  min-height: 200px;
  ${
    media.phone`
        align-self: stretch;
        display: flex;
        flex: 1;
        width: unset;
        justify-content: flex-start;
        padding: 50px 20px;
        margin-top: 0;
    `
    }
`

const Form = styled(FormContainer)`
  padding: 40px;
  min-height: 360px;
    ${
    media.phone`
        padding: 20px;
        width: 100%;
        min-height: unset;
        display: flex;
        flex: 1;
        justify-content: flex-start;
        margin-top: 0;
        `
    }
`

const Title = styled(fontDiv)`
  font-size: 25px;
  text-align: center;
  padding: 2px;
  color: #700cff;
`
const SubTitle = styled(Title)`
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: black;
`

const SuccessMessage = styled(SubTitle)`
  font-size: 18px;
  padding: 30px;
  margin-top: 0;
  color: #700cff;
  line-height: 30px;
  letter-spacing: normal;
  white-space: pre-line;
    ${
    media.phone`
        padding: 0;
    `
    }
`

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    padding: 16px 30px 0 30px;
    ${
    media.phone`
        padding: 10px 30px;
        `
    }
`
const ClearButton = styled(ClearBGButton)``
const VSpace = styled.div`
  height: 15px
`


export class ForgotPasswordPage extends Component {
    constructor(props) {
        super(props)
        console.log(props.history)
        this.state = {
            username: '',
            successMessage: undefined,
            errors: {
                username: ''
            }
        }
    }

    _onChangeText = (e) => {
        let {name, value} = e.target
        let errors = this.state.errors
        errors[name] = (value.length === 0 || Validator.validateEmail(value)) ? '' : 'Please enter valid email address'
        this.setState({...this.state, [name]: value, errors: {...errors}})
    }

    _resetPassword = (e) => {
        e.preventDefault()
        const {username} = this.state
        if (!Validator.validateEmail(username)) {
            alert('Please enter valid username')
            return
        }
        this.props.startLoading()
        this.props.resetPassword(username)
            .then((res) => {
                this.props.stopLoading()
                this.setState({
                    ...this.state,
                    successMessage: res.payload.data.message + '\nRedirecting to Login...'
                })
                setTimeout(this._goBack, 2500)
            })
            .catch(e => {
                this.props.stopLoading()
                showError(e)
            })
    }

    _goBack = () => {
        this.props.history.replace('/login')
        this.props.history.goBack()
    }

    _successUI = () => {
        return (
            <PageContainer>
                <SuccessMessage>{this.state.successMessage}</SuccessMessage>
                {/*<Button width={228} type={'submit'}*/}
                {/*onClick={this._goBack}>{'GO BACK'}</Button>*/}
            </PageContainer>
        )
    }

    render() {
        const {
            username,
            successMessage,
            errors
        } = this.state
        return (
            <OnBoardContainer solidBackgroundOnMobile>
                <input type="hidden" value="anyValue"/>
                {
                    successMessage ? this._successUI() :
                        <Form onSubmit={this._resetPassword}>
                            <Title>{'Forgot your Password?'}</Title>
                            <SubTitle>{'Enter your email address below and we’ll get you back on track'}</SubTitle>
                            <InputTextField
                                error={Boolean(errors.username)}
                                label={'Email Address'}
                                value={username}
                                name={'username'}
                                placeholder="Email Address"
                                helperText={errors.username ? errors.username : ' '}
                                onChange={this._onChangeText}
                            />
                            <ButtonsContainer>
                                <Button style={{flex: 1}}
                                        disabled={!Validator.validateEmail(username)}
                                        type={'submit'}>{'REQUEST RESET LINK'}</Button>
                                <VSpace/>
                                <ClearButton style={{flex: 1, alignSelf: 'stretch'}}
                                             onClick={this.props.history.goBack}>{'CANCEL'}</ClearButton>
                            </ButtonsContainer>

                        </Form>
                }
            </OnBoardContainer>
        )
    }
}