import React from 'react'
import styled from 'styled-components'
import { fontDiv } from './RootFontComponents'

const Container = styled(fontDiv)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-weight: 400;
`
export const NoData = () => {
    return (
        <Container>
            {'No Data\nAvailable'}
        </Container>
    )
}