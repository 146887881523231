import React, { Component } from 'react'
import { Bar, BarChart, Brush, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import PropTypes from 'prop-types'
import { GraphContainer } from "./common/GraphContainer"
import { BarImageLabel, BarLabel, MemberToolTip } from "./common"
import { Images } from "../../../assets"
import styled from "styled-components"
import { Clickable } from '../../common'

const SortButton = styled(Clickable)`
  padding: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
`

const Logo = styled.img`
  height: auto;
  width: 20px;
`

class MemberGraph extends Component {
    constructor(props) {
        super(props)
        this.dataSort = true
        let displayData = this._transformData(props.data, props.valueKey)
        this.state = {data: displayData}
        this.maxValue = Math.max.apply(Math, displayData.map(e => e.value))
    }

    _sortButton = () => {
        return (
            <SortButton onClick={this._sortData}>
                <Logo src={Images.sort}/>
            </SortButton>
        )
    }

    _sortData = () => {
        let dataCopy = this.state.data.slice()
        dataCopy.sort((a, b) => this.dataSort ? a.value - b.value : b.value - a.value)
        this.setState({data: dataCopy})
        this.dataSort = !this.dataSort
    }

    _customizeBar = (entry, index) => {
        let current = entry.value
        let fillAlpha = (1 / this.maxValue) * (current + 2)
        let fillColor = `rgba(112, 12, 255, ${fillAlpha}`
        return (
            <Cell key={`cell-${index}`} fill={fillColor}/>
        )
    }

    _transformData = (data, valueKey) => {
        return data.map((anyData, index) => ({
            id: anyData.peopleId,
            name: anyData.name,
            value: anyData[valueKey],
            pIndex: index
        }))
    }

    _onClickBar = ({id}) => {
        this.props.onClickPerson(id)
    }

    render() {
        let {isFlex, title, tooltip} = this.props
        const {data} = this.state
        let barParams = data.length > 11 ? {barSize: 30} : {barGap: 20}
        return (
            <GraphContainer title={title}
                            isFlex={isFlex}
                            showIndicator={false}
                            actionComponent={this._sortButton()}>
                <ResponsiveContainer height='100%' width='100%' aspect={undefined}>
                    <BarChart data={data} {...barParams}>
                        <Bar dataKey="value" fill={"#700cff"}
                             onClick={this._onClickBar}
                             animationDuration={1000}
                             radius={2}
                             label={<BarLabel/>}>
                            {
                                data.map(this._customizeBar)
                            }
                        </Bar>
                        <Tooltip cursor={false}
                                 content={<MemberToolTip tooltip={tooltip}/>}/>
                        <XAxis height={30} axisLine={false} tickLine={false}
                               tick={params => <BarImageLabel {...params} data={data}
                                                              onClick={this._onClickBar}/>}/>
                        {
                            data.length > 11 && <Brush height={5} stroke={"#700cff"}
                                                       startIndex={0}
                                                       endIndex={10}/>
                        }

                        <YAxis scale={'linear'} hide/>
                    </BarChart>
                </ResponsiveContainer>
            </GraphContainer>
        )
    }
}

MemberGraph.propTypes = {
    data: PropTypes.array.isRequired,
    isFlex: PropTypes.bool,
    title: PropTypes.string.isRequired,
    valueKey: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    onClickPerson: PropTypes.func.isRequired
}

MemberGraph.defaultProps = {
    data: [],
    isFlex: false
}

export { MemberGraph }
