import React, { Component } from 'react'
import styled from 'styled-components'
import { OnBoardContainer } from "../../common"
import { media } from '../../../utils'
import { LoginButton, SignUpButton } from './common/buttons'
import { IntroBar } from './common/IntroBar'
import { Contact, DevelopmentPolicy, Home, HowItWorks, What, Why } from './content-pages'
import Pdf from '../../../documents/terms.pdf'
import { Images } from '../../../assets'

const ContentContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    ${
    media.phone`
        max-width: unset;
        margin: 0 20px;
    `
}
    ${
    media.tablet`
        max-width: 600px;
    `
}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  align-items: center;
  ${
    media.phone`
        flex: 1;
    `
}
`

const DisplayImage = styled.img`
  align-self: stretch;
  display: flex;
  max-width: 300px;
  object-fit: contain;
  margin: 50px;
  ${
    media.phone`
        display: none;
    `
}
`

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px;
    align-self: flex-end;
`

const HSpace = styled.div`
  width: ${p => p.space ? p.space : 20}px;
  ${
    media.phone`
        width: 0;
    `
}
`

export class WelcomePage extends Component {
    constructor() {
        super()
        this.state = {
            pages: [
                {
                    content: <Home onGetStarted={this._goToSignUpPage}/>,
                    image: undefined
                },
                {
                    content: <What/>,
                    image: Images.whatIsWinc
                }, {
                    content: <HowItWorks/>,
                    image: Images.how
                }, {
                    content: <Why/>,
                    image: Images.why
                }, {
                    content: <DevelopmentPolicy/>,
                    image: Images.developmentPolicy
                }, {
                    content: undefined,
                    image: undefined
                }, {
                    content: <Contact/>,
                    image: Images.contact
                }
            ],
            currentIndex: 0
        }
    }

    _goToLoginPage = () => {
        this.props.history.push('/login')
    }

    _goToSignUpPage = () => {
        this.props.history.push('/signup')
    }

    _onSelectFeature = (index) => {
        if (!this.state.pages[index].content) {
            window.open(Pdf, '_blank')
            index = 0
        }
        this.setState(state => ({...state, currentIndex: index}))
    }

    render() {
        const {pages, currentIndex} = this.state
        const {content, image} = pages[currentIndex]
        return (
            <OnBoardContainer headerComps={
                <>
                    <IntroBar onSelectFeature={this._onSelectFeature} currentIndex={currentIndex}/>
                    <ButtonsContainer>
                        <LoginButton onClick={this._goToLoginPage}>{'LOGIN'}</LoginButton>
                        <HSpace/>
                        <SignUpButton onClick={this._goToSignUpPage}>{'SIGN-UP'}</SignUpButton>
                    </ButtonsContainer>
                </>
            }>
                <ContentContainer>
                    <Content>{content}</Content>
                    {image && <DisplayImage src={image}/>}
                </ContentContainer>
            </OnBoardContainer>
        )
    }
}
