import styled from 'styled-components'
import { media } from '../../../../utils'
import React from 'react'
import { Title } from '../common/Title'

const SubTitle = styled(Title)`
  font-size: 25px;
  font-weight: 300;
  padding: 0;
  ${
    media.phone`
       font-size: 20px
    `
    }
`

export const What = () => (
    <>
        <Title>{"What's a WinC?"}</Title>
        <SubTitle>{"WinC is the web base cloud service to achieve manager success through expanding manager's power of recognition"}</SubTitle>
    </>
)