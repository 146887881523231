import React from 'react'
import { AppContext } from '../context'

export const WithLoaderContext = (Component) => {
    return props => (
        <AppContext.Consumer>
            {(contextProps) =>
                <Component {...props} {...contextProps}
                           ref={props.innerRef}/>
            }
        </AppContext.Consumer>
    )
}

