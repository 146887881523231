import React, { Component } from "react"
import { HexagonText } from '../../../common'

export class BarImageLabel extends Component {
    render() {
        const {x, y, data, index, onClick} = this.props
        let {name, pIndex = index} = data[index]
        name = name === null ? 'XX' : name
        let barSize = 35.5
        let xPosition = x - barSize / 2 - 1
        let yPosition = y - barSize / 2 - 10
        return (
            <HexagonText name={name}
                         index={pIndex}
                         size={barSize}
                         fontSize={12}
                         position={{x: xPosition, y: yPosition}}
                         onClick={() => onClick(data[index])}
                         charCount={3}/>
        )
    }
}
