import React from 'react'
import styled from 'styled-components'
import { Clickable, fontDiv } from '../../../common'
import { media } from '../../../../utils'

const Wrapper = styled(fontDiv)`
  height: 40px;
  border: 0.1px solid #f0e6ff;
  border-left: 0;
  border-right: 0;
  display: none;
  ${
    media.phone`
        display: ${({showSelection = false}) => showSelection ? `flex` : `none`};
        justify-content: space-evenly;
        padding: 0;
        margin-top: 15px;
        top: 0;
        align-self: stretch;
    `
}
`

const CatCount = styled(fontDiv)`
    align-items: center;
    display: flex;
    color: white;
    font-size: 9px;
    align-self: center;
    background-color: ${({selected = false}) => selected ? `#700cff` : `#9f91a4`};
    margin-left: 10px;
    height: 20px;
    border-radius: 10px;
    padding: 0 12px;
`

const Button = styled(Clickable)`
  color: ${({selected = false}) => selected ? `#700cff` : `#9f91a4`};
  font-weight: 500;
  border-top: ${({selected = false}) => selected ? `2px solid #700cff` : `2px solid transparent`};
  padding: 0 30px;
  font-size: 12px;
`

export const MobileSelection = ({selectedIndex = 0, categoryCount = 0, onSelectMobileView, showSelection}) => {
    return (
        <Wrapper showSelection={showSelection}>
            <Button selected={selectedIndex === 0}
                    onClick={() => onSelectMobileView(0)}>{"OVERVIEW"}</Button>
            <Button selected={selectedIndex === 1} onClick={() => onSelectMobileView(1)}>
                CATEGORIES
                <CatCount selected={selectedIndex === 1}>{categoryCount}</CatCount>
            </Button>
        </Wrapper>
    )
}
