import React, { Component } from 'react'
import styled from 'styled-components'
import { Button, ClearBGButton, fontDiv, FormContainer, OnBoardContainer } from "../../common"
import { Validator } from "../../../utils/Validator"
import { InputTextField } from "../../common/InputTextField"
import { Images } from "../../../assets"
import { media, showError } from '../../../utils'
import Pdf from '../../../documents/terms.pdf'

const Form = styled(FormContainer)`
  min-height: 531px;
  padding: 30px;
  width: 500px;
  margin-top: 60px;
  ${
    media.phone`
        padding: 20px;
        width: 100%;
        min-height: unset;
        display: flex;
        flex: 1;
        justify-content: flex-start;
        margin-top: 0;
        `
    }
`

const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled(fontDiv)`
  align-self: flex-start;
  font-size: 25px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #700cff;
  padding: 5px 0;
`
const SubTitle = styled(Title)`
  font-size: 15px;
  font-weight: normal;
  color: #9f91a4;
`
const Logo = styled.img`
  align-self: flex-end;
  height: 66px;
  width: auto;
`
const TermsContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
  align-self: stretch;
  align-items: center;
  margin: 10px 0 0 0;
`
const Selection = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${p => p.selected ? p.theme.primary : 'gray'};
  display: flex;
  align-items: center;
  justify-content: center;
`

const Icon = styled.img`
  width: 20px;
  margin: 0;
  align-self: center;
  -webkit-user-select: none;
  user-select: none;
`
const TermsText = styled(fontDiv)`
  font-size: 13px;
  font-weight: normal;
  text-align: left;
  padding: 0 3px 0 5px;
  -webkit-user-select: none;
  user-select: none;
`
const StyledLink = styled(TermsText)`
  padding: 0;
  color: #700cff;
  :hover {
    text-decoration: underline;
    cursor:pointer;
  }
`
const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    padding: 16px 80px 0 80px;
    ${
    media.phone`
        flex-direction: row-reverse;
        padding: 20px 0;
        `
    }
`

const SignupButton = styled(Button)``
const ClearButton = styled(ClearBGButton)``
const VSpace = styled.div`
  height: 10px
`


const passwordHelpMessage = 'Eight or more characters, at least one capital, one number'

export class SignupPage extends Component {
    constructor() {
        super()
        this.state = {
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            retypedPassword: '',
            selected: false,
            errors: {
                email: '',
                firstName: '',
                lastName: '',
                password: '',
                retypedPassword: ''
            }
        }
    }

    _errorMessage = (inputType, value) => {
        if (value.length === 0) {
            return ''
        }
        switch (inputType) {
            case 'email':
                if (!Validator.validateEmail(value)) {
                    return 'Please enter valid email'
                }
                break
            case 'firstName':
            case 'lastName':
                return ''
            case 'retypedPassword':
                if (value !== this.state.password) {
                    return 'Passwords did not match'
                }
                break
            default:
                if (!Validator.validatePassword(value)) {
                    return 'Please enter valid password'
                }
        }
        return ''
    }

    _onChangeText = (e) => {
        if (!e.isTrusted) return
        let {name, value} = e.target
        let errors = this.state.errors
        errors[name] = this._errorMessage(name, value)
        this.setState({...this.state, [name]: value, errors: {...errors}})
    }

    _onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this._registerUser(e)
        }
    }

    _registerUser = (e) => {
        e.preventDefault()
        const {
            email,
            firstName,
            lastName,
            password,
        } = this.state

        /*
        if (!Validator.validateEmail(email)) {
            alert('Please enter valid work email')
            return
        }

        if (firstName.length === 0) {
            alert('Please enter first name')
            return
        }

        if (lastName.length === 0) {
            alert('Please enter last name')
            return
        }

        if (!Validator.validatePassword(password)) {
            alert('Please enter password')
            return
        }

        if (password !== retypedPassword) {
            alert("Passwords don't match")
            return
        }
        */
        let user = {email, firstName, lastName, password}
        const {startLoading, stopLoading, registerUser} = this.props
        startLoading()
        registerUser(user)
            .then(() => {
                stopLoading()
                alert("You have been registered successfully. Please verify your email before you login.")
                this.props.history.push('/login')
            })
            .catch(e => {
                stopLoading()
                showError(e)
            })
    }

    _areAllFieldsValid = () => {
        const {
            email,
            firstName,
            lastName,
            password,
            retypedPassword,
            selected
        } = this.state
        const {validateEmail, validatePassword} = Validator
        return validateEmail(email)
            && validatePassword(password)
            && firstName.length > 0
            && lastName.length > 0
            && password === retypedPassword
            && selected
    }

    _showTermsAndConditions = () => {
        window.open(Pdf, '_blank')
    }

    _checkTerms = () => {
        this.setState({...this.state, selected: !this.state.selected})
    }

    render() {
        const {
            email,
            firstName,
            lastName,
            password,
            retypedPassword,
            errors,
            selected
        } = this.state
        return (
            <OnBoardContainer solidBackgroundOnMobile>
                <input type="hidden" value="anyValue"/>
                <Form onSubmit={this._registerUser} onKeyPress={this._onKeyPress}>
                    <ContentHeader>
                        <TextContainer>
                            <Title>{'Get Started for free'}</Title>
                            <SubTitle>{'No Credit card needed.'}</SubTitle>
                        </TextContainer>
                        <Logo src={Images.signup}/>
                    </ContentHeader>

                    <InputTextField
                        name={'firstName'}
                        error={Boolean(errors.name)}
                        label={'First Name'}
                        value={firstName}
                        placeholder="First name"
                        helperText={errors.name ? errors.name : ' '}
                        onChange={this._onChangeText}
                    />

                    <InputTextField
                        name={'lastName'}
                        error={Boolean(errors.name)}
                        label={'Last Name'}
                        value={lastName}
                        placeholder="Last Name"
                        helperText={errors.name ? errors.name : ' '}
                        onChange={this._onChangeText}
                    />

                    <InputTextField
                        name={'email'}
                        error={Boolean(errors.email)}
                        label={'Work Email'}
                        value={email}
                        placeholder="Work email address"
                        helperText={errors.email ? errors.email : ' '}
                        onChange={this._onChangeText}
                    />

                    <InputTextField
                        type={'password'}
                        name={'password'}
                        error={Boolean(errors.password)}
                        label={'Password'}
                        value={password}
                        placeholder="Password"
                        helperText={errors.password ? errors.password : passwordHelpMessage}
                        onChange={this._onChangeText}
                    />

                    <InputTextField
                        type={'password'}
                        name={'retypedPassword'}
                        error={Boolean(errors.retypedPassword)}
                        label={'Retype Password'}
                        value={retypedPassword}
                        placeholder="Retype Password"
                        helperText={errors.retypedPassword ? errors.retypedPassword : ' '}
                        onChange={this._onChangeText}
                    />
                    <TermsContainer>
                        <Selection selected={selected} onClick={this._checkTerms}>
                            <Icon src={Images.checkmark}/>
                        </Selection>
                        <TermsText
                            onClick={this._checkTerms}>{"By signing up, you agree to our"}</TermsText>
                        <StyledLink
                            onClick={this._showTermsAndConditions}>{'Terms of Use'}</StyledLink>
                    </TermsContainer>
                    <ButtonsContainer>
                        <SignupButton style={{flex: 1}}
                                      disabled={!this._areAllFieldsValid()}
                                      type={'submit'}>{'SIGNUP'}</SignupButton>
                        <VSpace/>
                        <ClearButton style={{flex: 1, alignSelf: 'stretch'}}
                                     onClick={this.props.history.goBack}>{'CANCEL'}</ClearButton>
                    </ButtonsContainer>
                </Form>
            </OnBoardContainer>
        )
    }
}