import styled from 'styled-components'
import { fontDiv } from '../../../common'
import { media } from '../../../../utils'

export const Title = styled(fontDiv)`
  font-size: 60px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 0 0 20px 0;
  ${
    media.phone`
       font-size: 35px;
       padding: 0 0 20px 0;
    `
    }
  ${
    media.tablet`
       font-size: 60px;
       padding: 0 0 20px 0;
       
    `
    }
`
