import styled from "styled-components"

export const FullPageContainer = styled.div`
  padding-top: 50px;
  background-color: #fbf8ff;
  top: 0;
  left: 0;
`

export const AbsoluteFullPageContainer = styled(FullPageContainer)`
  height: 100vh;
`
