import { bindActionCreators } from "redux"
import * as actions from "../../../actions/categories"
import { connect } from 'react-redux'
import { CategoryPage } from './CategoryPage'
import { WithLoaderContext } from '../../WithLoaderContext'

const mapStateToProps = (state) => ({
    selectedCategory: state.selectedTeamCategory
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(actions, dispatch)
}

export const ConnectedTeamLevelCategoryPage = connect(mapStateToProps, mapDispatchToProps)(WithLoaderContext(CategoryPage))