import React, { Component } from 'react'
import styled from 'styled-components'
import {
    AbsoluteFullPageContainer,
    AlertDialog,
    Button,
    ClearBGButton,
    CustomNumberFormat,
    fontDiv,
    FormContainer,
    InputTextField
} from "../../common"
import { Validator } from "../../../utils/Validator"
import { getDateFromString, showError, toUTCDate } from '../../../utils'

const PageContainer = styled(AbsoluteFullPageContainer)`
  display: flex;
  justify-content: center;
`

const Form = styled(FormContainer)`
  min-height: 417px;
  padding: 80px;
  margin: 8px 0 0 0;
  display: flex;
  align-items: unset;
  width: unset;
 `

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
`

const StickyContainer = styled.div`
  position: -webkit-sticky;
  position: sticky;
  padding: 30px 5px;
  margin: 0 -5px;
  flex: unset;
  top: 50px;
  z-index: 2;
  background-color: #fbf8ff;
`

const Title = styled(fontDiv)`
  font-size: 35px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #450b96;
`

const ButtonsContainer = styled.div`
  margin-top: 20px;
  margin-bottom: -20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export class AddEditPersonPage extends Component {

    constructor(props) {
        super(props)
        const {selectedUser = {}} = props
        this.isEditMode = selectedUser.id !== undefined
        let {email = '', name = '', birthDate = '', hobbies = ''} = selectedUser
        birthDate = this.isEditMode && birthDate != null && birthDate.length > 0 ? getDateFromString(birthDate, 'MM/DD/YYYY') : ''
        this.state = {
            email, name, birthday: birthDate, hobbies, errors: {
                email: '', name: '', birthday: '', hobbies: ''
            }
        }
        this.stateCopy = {}
        if (this.isEditMode) {
            let {errors, ...rest} = this.state
            this.stateCopy = rest
        }
    }

    _onChangeText = (e) => {
        if (!e.isTrusted) return
        let {name, value} = e.target
        let errors = this.state.errors
        errors[name] = this._errorMessage(name, value)
        this.setState({...this.state, [name]: value, errors: {...errors}}, () => {
            console.log(this.state)
        })

    }

    _errorMessage = (inputType, value) => {
        if (value.length === 0) {
            return ''
        }
        switch (inputType) {
            case 'email':
                if (!Validator.validateEmail(value)) {
                    return 'Please enter valid email'
                }
                break
            case 'birthday':
                if (!Validator.validateDate(value)) {
                    return 'Please enter birthday in the format MM/DD/YYYY'
                }
                break
            default:
        }
        return ''
    }

    _onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this._createOrUpdateUser(e)
        }
    }

    _createOrUpdateUser = (e) => {
        e.preventDefault()
        const {name, email, birthday, hobbies} = this.state
        let person = {
            email, name, birthDate: birthday.length > 0 ? toUTCDate(birthday) : null, hobbies
        }
        if (this.isEditMode) {
            person = {...person, id: this.props.selectedUser.id}
        }
        console.log(person)
        const {createUser, updateUser, startLoading, stopLoading} = this.props
        let userApiCall = this.isEditMode ? updateUser : createUser
        startLoading()
        userApiCall(person)
            .then(r => {
                stopLoading()
                this.alert.showContent('Success!', `User has been ${this.isEditMode ? 'updated' : 'created'} successfully.`, this.props.history.goBack)
            })
            .catch(e => {
                stopLoading()
                showError(e)
            })
    }

    _canUpdate = () => {
        const {name, birthday, hobbies} = this.state
        if (birthday.length > 0 && !Validator.validateDate(birthday)) {
            return false
        }
        return name !== this.stateCopy.name || birthday !== this.stateCopy.birthday || hobbies !== this.stateCopy.hobbies
    }

    _areAllFieldsValid = () => {
        const {
            email, name, birthday
        } = this.state

        if (birthday.length > 0 && !Validator.validateDate(birthday)) {
            return false
        }
        const {validateEmail} = Validator
        return validateEmail(email) && name.length > 0
    }

    render() {
        const {
            email, name, hobbies, birthday, errors
        } = this.state
        return (
            <PageContainer>
                <ContentContainer>
                    <StickyContainer>
                        <Title>{`${this.isEditMode ? 'Update' : 'Add'} Person`}</Title>
                    </StickyContainer>
                    <Form onSubmit={this._createOrUpdateUser} onKeyPress={this._onKeyPress}>
                        <InputTextField
                            name={'name'}
                            error={Boolean(errors.name)}
                            label={'Full Name'}
                            value={name}
                            placeholder="Name"
                            helperText={errors.name ? errors.name : ' '}
                            onChange={this._onChangeText}
                        />
                        <InputTextField
                            name={'email'}
                            disabled={this.isEditMode}
                            error={Boolean(errors.email)}
                            label={'Work Email'}
                            value={email}
                            placeholder="Work email address"
                            helperText={errors.email ? errors.email : ' '}
                            onChange={this._onChangeText}
                        />
                        <InputTextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name={'birthday'}
                            error={Boolean(errors.birthday)}
                            label={'Birthday'}
                            helperText={errors.birthday ? errors.birthday : ' '}
                            onChange={this._onChangeText}
                            value={birthday}
                            InputProps={{
                                inputComponent: CustomNumberFormat,
                            }}
                        />
                        <InputTextField
                            type={'text'}
                            name={'hobbies'}
                            error={Boolean(errors.hobbies)}
                            label={'Hobbies'}
                            value={hobbies}
                            placeholder="Hobbies"
                            helperText={errors.hobbies ? errors.hobbies : ' '}
                            onChange={this._onChangeText}
                        />
                        <ButtonsContainer>
                            <ClearBGButton width={150}
                                           onClick={this.props.history.goBack}>{'CANCEL'}</ClearBGButton>
                            <Button
                                width={228}
                                disabled={this.isEditMode ? !this._canUpdate() : !this._areAllFieldsValid()}
                                type={'submit'}>{`${this.isEditMode ? 'Update' : 'Add'} Person`}</Button>
                        </ButtonsContainer>
                    </Form>
                </ContentContainer>
                <AlertDialog ref={c => this.alert = c} title={'Hello'} message={'My Message'}/>
            </PageContainer>)
    }
}
