import styled from "styled-components"
import { fontButton } from "./RootFontComponents"

export const Clickable = styled(fontButton)`
  outline: none;
  border: none;
  display: flex;
  background: none;
  margin: 0;
  align-items: center;
  vertical-align: middle;
  transition-duration: 0.4s;
  transform: scale(1.0);
  box-sizing: border-box;
  :active {
      border: none;
      transform: scale(0.97);
  }
  :hover {
    cursor:pointer;
  }
  :focus {
    outline: none;
  }
`
