import { bindActionCreators } from "redux"
import * as actions from "../../../actions/user"
import { connect } from 'react-redux'
import { AccountPage } from './AccountPage'
import { WithLoaderContext } from '../../WithLoaderContext'

const mapStateToProps = (state) => {
    return {...state.authentication}
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(actions, dispatch)
}

export const ConnectedAccountPage = connect(mapStateToProps, mapDispatchToProps)(WithLoaderContext(AccountPage))