import { bindActionCreators } from "redux"
import * as actions from "../../../actions/team"
import { connect } from 'react-redux'
import { DashboardPage } from "./DashboardPage"
import { WithLoaderContext } from '../../WithLoaderContext'

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(actions, dispatch)
}

export const ConnectedDashboardPage = connect(mapStateToProps, mapDispatchToProps)(WithLoaderContext(DashboardPage))