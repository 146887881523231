import React, { Component } from 'react'
import styled from 'styled-components'
import {
    Button,
    ClearBGButton,
    fontButton,
    fontDiv,
    FormContainer,
    OnBoardContainer
} from "../../common"
import { Validator } from "../../../utils/Validator"
import { InputTextField } from "../../common/InputTextField"
import { Images } from "../../../assets"
import { media, showError } from '../../../utils'

const Form = styled(FormContainer)`
  min-height: 417px;
  padding: 40px;
  margin-top: 60px;
  ${
    media.phone`
        padding: 20px;
        width: 100%;
        min-height: unset;
        display: flex;
        flex: 1;
        justify-content: flex-start;
        margin-top: 0;
        `
}
`

const TitleHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
`

const Logo = styled.img`
  align-self: flex-end;
  height: 94px;
  width: auto;
  ${
    media.phone`
       height: 70px; 
        
       `
}
`

const Title = styled(fontDiv)`
  font-size: 25px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #700cff;
`

const ForgotPasswordButton = styled(fontButton)`
  font-size: 12px;
  height: 20px;
  margin: -5px 0 5px 0;
  justify-content: flex-end;
  text-align: right;
  align-self: stretch;
  box-sizing: border-box;
  padding: 0;
  outline: none;
  border: 0;
  background: none;
  color: #9f91a4;
  :active {
      border: none;
      background: none;
      color: gray;
  }
`

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    padding: 16px 40px 0 40px;
    ${
    media.phone`
        flex-direction: row-reverse;
        padding: 20px 0;
        `
}
`

const VSpace = styled.div`
  height: 10px
`


export class LoginPage extends Component {
    constructor() {
        super()
        this.state = {
            username: '',
            password: '',
            errors: {
                username: '',
                password: ''
            }
        }
    }

    _errorMessage = (inputType, value) => {
        if (value.length === 0) {
            return ''
        }
        switch (inputType) {
            case 'username':
                if (!Validator.validateEmail(value)) {
                    return 'Please enter valid email'
                }
                break
            default:
                if (!Validator.validatePassword(value)) {
                    return 'Please enter valid password'
                }
        }
        return ''
    }

    _onChangeText = (e) => {
        if (!e.isTrusted) return
        let {name, value} = e.target
        let errors = this.state.errors
        errors[name] = this._errorMessage(name, value)
        this.setState({...this.state, [name]: value, errors: {...errors}})
    }

    _goToForgotPassword = () => {
        this.props.history.push('/forgot-password')
    }

    _onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this._login(e)
        }
    }

    _login = (e) => {
        e.preventDefault()
        this.props.logout()
        const {username, password} = this.state
        if (!Validator.validateEmail(username)) {
            alert('Please enter username')
            return
        }

        if (!Validator.validatePassword(password)) {
            alert('Please enter valid password')
            return
        }

        this.props.startLoading()
        this.props.login(username, password).then(() => {
            this.props.stopLoading()
            this.props.history.push('/dashboard')
        })
            .catch(e => {
                this.props.stopLoading()
                showError(e)
            })
    }

    _areAllFieldsValid = () => {
        return Validator.validateEmail(this.state.username) && Validator.validatePassword(this.state.password)
    }

    _cancelLogin = () => {
        this.props.history.replace('/')
    }

    render() {
        const {
            username,
            password,
            errors
        } = this.state
        return (
            <OnBoardContainer solidBackgroundOnMobile>
                <input type="hidden" value="anyValue"/>
                <Form onSubmit={this._login} onKeyPress={this._onKeyPress}>
                    <TitleHeader>
                        <Title>{'Login'}</Title>
                        <Logo src={Images.lockIcon}/>
                    </TitleHeader>
                    {/*<SubTitle>{'Enter your details below.'}</SubTitle>*/}
                    <InputTextField
                        error={Boolean(errors.username)}
                        label={'Email'}
                        value={username}
                        name={'username'}
                        placeholder="Email"
                        helperText={errors.username ? errors.username : ' '}
                        onChange={this._onChangeText}
                    />
                    <InputTextField
                        type={'password'}
                        name={'password'}
                        error={Boolean(errors.password)}
                        label={'Password'}
                        value={password}
                        placeholder="Password"
                        helperText={errors.password ? errors.password : ' '}
                        onChange={this._onChangeText}
                    />
                    <ForgotPasswordButton
                        onClick={this._goToForgotPassword}>{'Forgot Password'}</ForgotPasswordButton>
                    <ButtonsContainer>
                        <Button style={{flex: 1}} disabled={!this._areAllFieldsValid()}
                                type={'submit'}>{'LOGIN'}</Button>
                        <VSpace/>
                        <ClearBGButton style={{flex: 1, alignSelf: 'stretch'}}
                                       onClick={this._cancelLogin}>{'CANCEL'}</ClearBGButton>
                    </ButtonsContainer>
                </Form>
            </OnBoardContainer>
        )
    }
}
