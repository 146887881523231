import React, { Component } from 'react'
import {
    CartesianGrid,
    Cell,
    Label,
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts'
import PropTypes from 'prop-types'
import { CustomToolTip, tickStyle } from "./common"
import { GraphContainer } from "./common/GraphContainer"

class ScatterGraph extends Component {
    constructor(props) {
        super(props)
        this.maxY = Math.max.apply(Math, props.data.map(e => e.totalRatio))
    }

    AxisY = () => {
        return (
            <YAxis type="number" dataKey={'totalRatio'}
                   tickLine={false}
                   width={45}
                   offset={0}
                   tick={tickStyle}
            >
                <Label fill={'#b1a5c5'}
                       value="Score"
                       angle={-90}
                       fontSize={14}
                       fontFamily={"Ubuntu, monospace"}
                       position="insideLeft"/>
            </YAxis>
        )
    }

    AxisX = () => {
        return (
            <XAxis type="number"
                   dataKey={'totalWincs'}
                   tickLine={false}
                   height={40}
                   tick={tickStyle}
            >
                <Label fill={'#b1a5c5'}
                       value="WinCs"
                       fontSize={14}
                       fontFamily={"Ubuntu, monospace"}
                       fontWeight={400}
                       position="insideBottom"/>
            </XAxis>
        )
    }

    _onClick = ({peopleId}) => {
        this.props.onClickPerson(peopleId)
    }

    render() {
        let {data} = this.props
        return (
            <GraphContainer title={'SCORE OVER WINCS'} isFlex={true} showIndicator={false}>
                <ResponsiveContainer height='100%' width='100%' aspect={undefined}>
                    <ScatterChart margin={{top: 15, left: 10, right: 25, bottom: 0}}>
                        {this.AxisX()}
                        {this.AxisY()}
                        <CartesianGrid stroke={'lightgray'} strokeWidth={0.5}/>
                        <Tooltip cursor={false} content={<CustomToolTip/>}/>
                        <Scatter data={data} onClick={this._onClick}>
                            {
                                data.map((entry, index) => {
                                    return <Cell key={`cell-${index}`}
                                                 fill={entry.totalRatio < this.maxY / 2 ? "#e01f8f" : "#700cff"}/>
                                })
                            }
                        </Scatter>
                    </ScatterChart>
                </ResponsiveContainer>
            </GraphContainer>
        )
    }
}

ScatterGraph.propTypes = {
    data: PropTypes.array.isRequired,
    onClickPerson: PropTypes.func.isRequired
}

ScatterGraph.defaultProps = {
    data: []
}

export { ScatterGraph }
