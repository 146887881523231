import { accountId, teamId } from './utils'

export class FeedbackService {
    static saveFeedback(feedback) {
        let url = '/feedback'
        return {
            url, data: {
                ...feedback, accountId: accountId(), teamId: teamId()
            }, method: 'POST'
        }
    }
}