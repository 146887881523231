import React, { Component } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles'

const theme1 = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiSelect: {
            select: {
                "&:focus": {
                    background: "transparent"
                }
            }
        }
    }
})

const styles = theme => {
    return {
        dropdownStyle: {
            fontFamily: 'Ubuntu, monospace',
        },
        input: {
            color: '#700cff',
            fontFamily: 'Ubuntu, monospace',
            fontSize: 18,
            fontWeight: 500
        },
        icon: {
            fill: '#700cff',
            alignSelf: 'flex-end'
        },
        title: {
            color: '#700cff',
            fontFamily: 'Ubuntu, monospace',
            fontSize: 18
        },
        optionsList: {
            fontFamily: 'Ubuntu, monospace',
            fontSize: 18
        },
        selected: {
            backgroundColor: "transparent !important",
            color: '#700cff',
            fontFamily: 'Ubuntu, monospace',
        },
        disabled: {
            backgroundColor: 'red'
        }
    }
}

export class CatDropdown extends Component {
    handleChange = event => {
        let category = this.props.list.filter(c => c.id === event.target.value)[0]
        this.props.onSelectCategory(category)
    }

    _renderList = () => {
        const {classes, list} = this.props
        return list.map((c, i) => {
            return <MenuItem
                value={c.id}
                key={i}
                classes={{
                    root: classes.optionsList,
                    selected: classes.selected
                }}>{c.name}</MenuItem>
        })
    }

    render() {
        const {classes, selected} = this.props
        let selectedValue = selected.id ? selected.id : 'none'
        return (
            <MuiThemeProvider theme={theme1}>
                <Select
                    style={{display: 'flex', flex: 1}}
                    disableUnderline
                    value={selectedValue}
                    onChange={this.handleChange}
                    inputProps={{
                        classes: {
                            icon: classes.icon,
                            select: classes.input,
                        },
                    }}
                >
                    <MenuItem value="none"
                              classes={{
                                  root: classes.title,
                                  selected: classes.selected,
                              }}
                              disabled>
                        {'Select Category'}
                    </MenuItem>
                    {
                        this._renderList()
                    }
                </Select>
            </MuiThemeProvider>
        )
    }
}

export const CategoryDropdown = withStyles(styles)(CatDropdown)

CategoryDropdown.defaultProps = {
    categories: [],
    selected: {},
    onSelectCategory: () => {
    }
}
