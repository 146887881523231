import { bindActionCreators } from "redux"
import * as userActions from "../../../actions/user"
import * as wincActions from "../../../actions/wincs"
import { connect } from 'react-redux'
import { WincListPage } from './WincListPage'
import { WithLoaderContext } from '../../WithLoaderContext'

const mapStateToProps = (state) => ({
    profileInfo: state.selectedUser
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({...userActions, ...wincActions}, dispatch)
}

export const ConnectedWincListPage = connect(mapStateToProps, mapDispatchToProps)(WithLoaderContext(WincListPage))