import { fontDiv, HexagonText } from "../../../common"
import React from "react"
import PropTypes from 'prop-types'
import styled from "styled-components"

const HexagonalContainer = styled.div`
    position: absolute;
    align-self: flex-end;
    margin-top: -37px;
    margin-left: -40px;
    box-sizing: border-box;
`

const WincIndicator = styled(fontDiv)`
    width: ${p => p.size}px;
    height: ${p => p.size}px;
    background-color: #5b12c2;
    align-self: flex-end;
    position: absolute;
    border-radius: ${p => (p.size + 4) / 2}px;
    bottom: -3px;
    right: -2px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
    font-size: 10px;
`

export const PersonInitial = (props) => {
    let {wincCount, ...rest} = props
    return (
        <HexagonalContainer>
            <HexagonText {...rest}/>
            <WincIndicator size={28}>{wincCount}</WincIndicator>
        </HexagonalContainer>)
}

PersonInitial.propTypes = {
    name: PropTypes.string.isRequired,
    index: PropTypes.number,
    size: PropTypes.number,
    fontSize: PropTypes.number,
    wincCount: PropTypes.number
}

PersonInitial.defaultProps = {
    name: 'Sriram Reddy', index: 0, size: 70, fontSize: 20, wincCount: 0
}