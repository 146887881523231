import { accountId, teamId } from './utils'

export class UserService {
    static updateAccountUser(user) {
        let url = '/accounts'
        return {
            url, data: {
                ...user
            }, method: 'PUT'
        }
    }

    static createUser(person) {
        let url = '/people'
        return {
            url, data: {
                ...person, accountId: accountId(), teamId: teamId()
            }, method: 'POST'
        }
    }

    static updateUser(person) {
        let url = '/people'
        return {
            url, data: {
                ...person, accountId: accountId(), teamId: teamId()
            }, method: 'PUT'
        }
    }

    static deleteUser(person) {
        let url = `/people/${accountId()}/${teamId()}/${person.id}`
        return {
            url, method: 'DELETE'
        }
    }

    static getTeamWincsSummary() {
        ///people/wincs/18/17
        let url = `/people/wincs/${accountId()}/${teamId()}`
        return {
            url, method: 'GET'
        }
    }

    static getIndividualWincSummary(userId) {
        let url = `/people/wincs/${accountId()}/${teamId()}/${userId}`
        return {
            url, method: 'GET'
        }
    }

    static getIndividualWincDetails(userId) {
        let url = `/people/wincdetails/${accountId()}/${teamId()}/${userId}`
        return {
            url, method: 'GET'
        }
    }

    static getIndividualWincsForCategory(userId, categoryId) {
        let url = `/wincs/${accountId()}/${teamId()}/${userId}/${categoryId}`
        return {
            url, method: 'GET'
        }
    }

    static getIndividualCategoriesInfo(userId) {
        let url = `/people/wincs/categories/${accountId()}/${teamId()}/${userId}`
        return {
            url, method: 'GET'
        }
    }

    static getIndividualWincsOvertime(userId, fromDate, toDate) {
        let url = `/people/wincs/overtime/${accountId()}/${teamId()}/${userId}?start=${fromDate}&end=${toDate}`
        return {
            url, method: 'GET'
        }
    }
}