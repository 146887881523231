import { getColor } from '../../theme'
import { StringUtils } from '../../utils'
import React from 'react'
import PropTypes from 'prop-types'

export const HexagonText = (props) => {
    const {
        name, index, size, fontSize, charCount, position: {x, y}, fill, onClick = () => {
        }
    } = props
    return (
        <svg x={x} y={y} width={size} height={size} fill={fill ? fill : getColor(index)}
             onClick={onClick}
             strokeWidth={10}>
            <svg viewBox="0 0 31 35">
                <path
                    d="M17.158 1.442l11.343 6.443a4 4 0 0 1 2.024 3.478v12.863a4 4 0 0 1-1.985 3.455l-11 6.413a4 4 0 0 1-4.03 0l-11-6.413a4 4 0 0 1-1.985-3.455V11.302A4 4 0 0 1 2.47 7.87l10.657-6.382a4 4 0 0 1 4.031-.046z"/>

            </svg>
            <text x="50%" y="50%"
                  textAnchor="middle"
                  fill="white"
                  alignmentBaseline={'central'}
                  fontFamily={'Ubuntu, monospace'} fontSize={fontSize}
                  fontWeight={500}
            >
                {StringUtils.getIntitials(name, charCount)}
            </text>
        </svg>
    )
}

HexagonText.propTypes = {
    name: PropTypes.string.isRequired,
    index: PropTypes.number,
    size: PropTypes.number,
    fontSize: PropTypes.number,
    charCount: PropTypes.number,
    position: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
    }),
    fill: PropTypes.string
}

HexagonText.defaultProps = {
    index: 0,
    size: 40,
    fontSize: 13,
    charCount: 2,
    position: {
        x: 0,
        y: 0
    }
}
