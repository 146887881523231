import React, { Component } from 'react'
import styled from "styled-components"
import { Images } from "../../assets"
import { Clickable } from './Clickable'
import { withRouter } from 'react-router-dom'
import { media } from '../../utils'

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    min-height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -2;
    background:url(${Images.bgImage}) no-repeat center;
    background-size:  cover;
    ${
    media.phone`
        ${
        p => p.solidBackgroundOnMobile ?
            `
            background: unset;
            background-color: ${p.theme.primary};
            ` : ``
    }
        `
}
`

const Header = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  min-height: 85px;
  ${
    media.phone`
    min-height: unset;
    ${
        p => p.solidBackgroundOnMobile ?
            `height: 50px;
            min-height: unset;
            ` : ``
    }
    `
}
`

const ButtonBase = styled(Clickable)`
  align-self: center;
  ${
    media.phone`
        margin-left: 0; 
        `
}
`
const AppLogo = styled.img`
  width: 100px;
  height: auto;
  ${
    media.phone`
        width: 65px; 
        `
}
`

class OnBoardContainerCompo extends Component {
    _goToHomePage = () => {
        if (!this.props.history) {
            return
        }
        this.props.history.replace('/')
    }

    render() {
        const {
            children,
            headerComps
        } = this.props
        return (
            <PageContainer {...this.props}>
                <Header {...this.props}>
                    <ButtonBase onClick={this._goToHomePage}>
                        <AppLogo src={Images.wincLogo}/>
                    </ButtonBase>
                    {
                        headerComps
                    }
                </Header>
                {children}
            </PageContainer>
        )
    }
}

export const OnBoardContainer = withRouter(OnBoardContainerCompo)
