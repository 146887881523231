import styled from "styled-components"

export const PersonContainer = styled.div`
    display: flex;
    flex: 1;
    align-self: stretch;
    flex-direction: column;
    border-radius: 4px;
    background-color: white;
    height: 224px;
    box-sizing: border-box;
    box-shadow: 0 1px 5px 0 rgba(179, 168, 181, 0.23);
    &:hover {
        box-shadow: 0 2px 7px 5px rgba(179, 168, 181, 0.33);
        transform: scale(1.0);
        transition-duration: 0.4s;
    }
    &:active {
        transform: scale(0.97);
        transition-duration: 0.4s;
    }
`