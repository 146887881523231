import styled from 'styled-components'
import MuiButton from '@material-ui/core/Button'

const _buttonStyle = (p) => {
    let {button} = p.theme
    const {activeBg, inactiveBg, inactiveText, activeText} = button
    let defStyle = {
        borderRadius: 20,
        backgroundColor: p.disabled ? inactiveBg : activeBg,
        color: p.disabled ? inactiveText : activeText,
        width: p.width ? p.width : undefined,
        fontSize: 12,
        fontWeight: 500,
        fontFamily: 'Ubuntu, monospace',
        alignSelf: 'stretch'
    }
    let style = p.style ? p.style : {}
    return {...defStyle, ...style}
}

export const Button = styled(MuiButton).attrs({
    variant: "contained",
    disableFocusRipple: true,
    disableRipple: true,
    style: _buttonStyle
})`
  display: flex;
  border: none;
  outline: none;
  box-sizing: border-box;
  align-self: stretch;
`

export const ClearBGButton = styled(MuiButton).attrs({
    color: 'primary',
    disableFocusRipple: true,
    disableRipple: true,
    style: p => {
        let defStyle = {
            borderRadius: 20,
            fontSize: 12,
            fontWeight: 'bold',
            width: p.width ? p.width : undefined,
            fontFamily: 'Ubuntu, monospace'
        }
        let style = p.style ? p.style : {}
        return {...defStyle, ...style}
    }
})`
  display: flex;
  outline: none;
  box-sizing: border-box;
  :hover {
    cursor:pointer;
  }
`
