import React, { Component } from 'react'
import styled from 'styled-components'
import { AlertDialog, CountTitle, fontDiv, FullPageContainer } from "../../common"
import { WinC } from "./helpers/WinC"
import { PersonHeader } from './PersonHeader'
import { showError } from '../../../utils'

const Grid = styled.div`
  display: grid;
  flex: 1;
  grid-template-columns: repeat(auto-fill, minmax(320px, auto));
  grid-template-rows: repeat(auto-fill, minmax(50px, auto));
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  background-color: transparent;
`
const GridContainer = styled.div`
  float: none;
  margin: auto;
  display: flex;
  max-width: 1040px;
  padding: 30px 0 30px 0 !important;
`
export const Title = styled(fontDiv)`
  font-size: 35px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5f5662;
`
export const CatHeader = styled.div`
  display: flex;
  grid-column: 1 / -1;
  align-items: center;
`

export class WincListPage extends Component {
    constructor(props) {
        super(props)
        let {profileInfo = {}} = props
        let {selectedCategory = {}} = profileInfo
        let {category = 'N/A'} = selectedCategory
        this.state = {wincs: [], category}
        this.wincTobeDeleted = undefined
        this.alertActions = [
            {
                title: 'CANCEL',
                positive: true,
            },
            {
                title: 'DELETE',
                positive: false,
                onPress: this._deleteWinc
            }
        ]
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.props.resetSelectedWinc()
        this._loadWincForSelectedCategory()
    }


    _loadWincForSelectedCategory = () => {
        let {categoryId = 0} = this.props.match.params
        if (categoryId === 0) {
            return
        }
        let userId = this.props.profileInfo.id
        this.props.startLoading()
        this.props.getIndividualWincsForCategory(userId, categoryId)
            .then(response => {
                this.props.stopLoading()
                this.setState({...this.state, wincs: response.payload.data})
            })
            .catch(e => {
                this.props.stopLoading()
                showError(e)
            })
    }

    _wincsUI = () => {
        return this.state.wincs.map((wincInfo, index) => <WinC
            index={index}
            key={`${index}`}
            info={wincInfo}
            deleteWinc={this._askBeforeDelteWinC}
            editWinc={this._editWinc}/>)
    }

    _onClickWinC = () => {
        //this.props.history.push(this.props.location.pathname + '/Honesty')
    }

    _addOrEditWinc = () => {
        this.props.history.push('/add-or-edit-winc')
    }

    _editWinc = (winc) => {
        this.props.selectWincToEdit(winc)
        this._addOrEditWinc()
    }

    _askBeforeDelteWinC = (winc) => {
        this.wincTobeDeleted = winc
        this.alert.showContent('Are you sure?', 'You want to delete the WinC?')
    }

    _deleteWinc = () => {
        if (this.wincTobeDeleted) {
            this.props.startLoading()
            this.props.deleteWinc(this.wincTobeDeleted)
                .then(() => {
                    this.props.stopLoading()
                    this._loadWincForSelectedCategory()
                })
                .catch(e => {
                    this.props.stopLoading()
                    showError(e)
                })
        }
    }

    render() {
        let {category, wincs} = this.state
        return (
            <FullPageContainer>
                <PersonHeader {...this.props}/>
                <GridContainer>
                    <Grid>
                        <CatHeader>
                            <CountTitle title={category} count={wincs.length}
                                        onClickBack={this.props.history.goBack}/>
                        </CatHeader>
                        {this._wincsUI()}
                    </Grid>
                </GridContainer>
                <AlertDialog ref={c => this.alert = c} title={''} message={''}
                             actions={this.alertActions}/>
            </FullPageContainer>)
    }
}