import React, { Component } from 'react'
import styled from "styled-components"
import { fontInput, fontTextArea } from "../../../common"
import { CategoryDropdown } from "./CategoryDropdown"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  box-shadow: 0 1px 5px 0 rgba(179, 168, 181, 0.23);
  background-color: #ffffff;
  padding: 15px;
  box-sizing: border-box;
`

const TitleInput = styled(fontInput)`
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #5f5662;
  margin: 10px 0 10px 0;
  ::placeholder {
    color: #b1a5c5;
  }
`

const DescriptionInput = styled(fontTextArea)`
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #5f5662;
  box-sizing: border-box;
  border: 1px solid #f4f3f4;
  height: 91px;
  border-radius: 2px;
  padding: 10px;
  resize: none;
  ::placeholder {
    color: #b1a5c5;
  }
`

export class SelectCategory extends Component {
    render() {
        const {
            list,
            selected,
            onChangeTitle,
            onChangeDescription,
            wincTitle,
            wincDescription,
            onSelectCategory
        } = this.props
        return (
            <Container>
                <CategoryDropdown list={list} selected={selected}
                                  onSelectCategory={onSelectCategory}/>
                <TitleInput onChange={e => onChangeTitle(e.target.value)}
                            placeholder={'Enter title here..'}
                            value={wincTitle}/>
                <DescriptionInput onChange={e => onChangeDescription(e.target.value)}
                                  value={wincDescription}
                                  placeholder={'Description...'}/>
            </Container>
        )
    }
}
