import React from 'react'
import styled from 'styled-components'
import { fontDiv } from "../../../common"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const GradientIndicator = styled.div`
  height: ${p => p.height}px;
  border-radius: ${p => p.height / 2}px;
  background-image: linear-gradient(to right, rgba(224, 69, 203, 0.07), #e045cb);
`

const Label = styled(fontDiv)`
  font-size: 11px;
  font-weight: ${p => p.fontWeight};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9081a5;
`

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0 0 0;
`

export const SeverityIndicator = (props) => {
    const {leftLabel, rightLabel, ...rest} = props
    return (
        <Container>
            <GradientIndicator {...rest}/>
            <LabelContainer>
                <Label {...rest}>{leftLabel.toUpperCase()}</Label>
                <Label {...rest}>{rightLabel.toUpperCase()}</Label>
            </LabelContainer>
        </Container>
    )
}

SeverityIndicator.defaultProps = {
    leftLabel: 'Lowest',
    rightLabel: 'Highest',
    fontWeight: 500,
    height: 4
}
