import { accountId, teamId } from './utils'

export class CategoriesService {
    static addCategory(category) {
        let url = `/categories/${accountId()}/${teamId()}`
        return {
            url, method: 'POST', data: {name: category.name}
        }
    }

    static updateCategory(category) {
        let url = `/categories/${accountId()}/${teamId()}/${category.id}`
        return {
            url, method: 'PATCH', data: {name: category.name}
        }
    }

    static deleteCategory(category) {
        let url = `/categories/${accountId()}/${teamId()}/${category.id}`
        return {
            url, method: 'DELETE'
        }
    }

    static transferWincsToCategory(transferData) {
        let url = `/categories/swap`
        return {
            url, method: 'PUT', data: {...transferData, accountId: accountId(), teamId: teamId()}
        }
    }

    static listCategories() {
        let url = `/categories/${accountId()}/${teamId()}`
        return {
            url, method: 'GET'
        }
    }


    static getWincInfoForAllCategories() {
        let url = `/categories/wincs/${accountId()}/${teamId()}`
        return {
            url, method: 'GET'
        }
    }

    static getWincsInfoForIndividualCategory(categoryId) {
        let url = `/categories/wincs/${accountId()}/${teamId()}/${categoryId}`
        return {
            url, method: 'GET'
        }
    }

    static getPeopleWithWincsFromCategory(categoryId) {
        let url = `/wincs/people/${accountId()}/${teamId()}/${categoryId}`
        return {
            url, method: 'GET'
        }
    }

    static testGetApi() {
        let url = '/users/health'
        return {
            url, method: 'GET'
        }
    }
}