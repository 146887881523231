import { TeamService } from '../services/TeamService'

const TEAM_SERVICE = 'team-service'
export const getTeamTotals = () => {
    return {
        type: TEAM_SERVICE + '/GET_TEAM_TOTALS',
        payload: {
            request: TeamService.getTeamTotals()
        }
    }
}

export const getTeamTotalsByPerson = () => {
    return {
        type: TEAM_SERVICE + '/GET_TEAM_TOTALS_BY_PERSON',
        payload: {
            request: TeamService.getTotalsOfPeopleInTheTeam()
        }
    }
}

export const getTeamTotalWincCount = () => {
    return {
        type: TEAM_SERVICE + '/GET_TEAM_TOTAL_WINC_COUNT',
        payload: {
            request: TeamService.getTeamTotalWincCount()
        }
    }
}

export const getTotalWincAndRatioByPerson = () => {
    return {
        type: TEAM_SERVICE + '/GET_TOTALS_BY_PERSON',
        payload: {
            request: TeamService.getTotalWincAndRatioByPerson()
        }
    }
}

export const getTeamWincsOverTime = (from, to) => {
    return {
        type: TEAM_SERVICE + '/GET_TEAM_WINCS_OVERTIME',
        payload: {
            request: TeamService.getTeamWincsOverTime(from, to)
        }
    }
}

export const getCumulativeTeamWincsOverTime = (from, to) => {
    return {
        type: TEAM_SERVICE + '/GET_CUMMULATIVE_TEAM_WINCS_OVERTIME',
        payload: {
            request: TeamService.getCumulativeTeamWincsOverTime(from, to)
        }
    }
}