import { bindActionCreators } from "redux"
import * as actions from "../../../actions/categories"
import { connect } from 'react-redux'
import { CategorySwapPage } from './CategorySwapPage'
import { WithLoaderContext } from '../../WithLoaderContext'

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(actions, dispatch)
}

export const ConnectedCategorySwapPage = connect(mapStateToProps, mapDispatchToProps)(WithLoaderContext(CategorySwapPage))