import React, { Component } from 'react'
import styled from 'styled-components'
import { fontDiv } from '../../../common'
import { PropTypes } from 'prop-types'
import { media } from '../../../../utils'
import { HelpMenu } from './HelpMenu'

const Container = styled(fontDiv)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`

const DesktopWrapper = styled.div`
  display: flex;
  ${
    media.phone`
        display: none
    `
    }
  
  ${
    media.tablet`
        display: none;
    `
    }
  
    ${
    media.desktop`
    `
    }
`

const NonDesktopWrapper = styled.div`
  display: none;
  ${
    media.phone`
        display: flex;
    `
    }
  ${
    media.tablet`
        display: flex;
    `
    }
  ${
    media.desktop`
        display: none;
    `
    }
`

const Feature = styled(fontDiv)`
  padding: 10px 30px;
  font-size: 18px;
  color: ${({isSelected = false}) => isSelected ? `white` : `gray`}
  ${
    ({isSelected = false}) => isSelected ? `text-decoration: underline;` : `text-decoration: unset;`
    }
  :hover {
    text-decoration: underline;
    cursor:pointer;
  }
`

export class IntroBar extends Component {
    constructor(props) {
        super(props)
        const {currentIndex = 0} = props
        this.state = {
            topics: ['Home', "What's a WinC", 'How WinC Works', 'Why WinC', "Development Policy", 'Terms of Use', "Contact"],
            currentIndex
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.currentIndex !== nextProps.currentIndex) {
            this.setState(state => ({...state, currentIndex: nextProps.currentIndex}))
        }
    }

    render() {
        const {topics, currentIndex} = this.state
        return (
            <Container>
                <NonDesktopWrapper>
                    <HelpMenu topics={topics} currentIndex={currentIndex}
                              onSelectFeature={this.props.onSelectFeature}/>
                </NonDesktopWrapper>
                <DesktopWrapper>
                    {
                        topics.map((f, i) => (
                            <Feature key={i} isSelected={i === currentIndex}
                                     onClick={() => this.props.onSelectFeature(i)}
                            >{f}</Feature>))
                    }
                </DesktopWrapper>
            </Container>
        )
    }
}

IntroBar.propTypes = {
    onSelectFeature: PropTypes.func.isRequired
}
