import styled from 'styled-components'
import { fontLink } from '../../../common'
import { media } from '../../../../utils'
import React from 'react'
import { Title } from '../common/Title'

const SubTitle = styled(Title)`
  font-size: 25px;
  font-weight: 300;
  padding: 20px 0;
  ${
    media.phone`
       font-size: 20px
    `
    }
`

const StyledLink = styled(fontLink)`
  padding: 0;
  font-size: 18px;
  color: ${({theme}) => theme.secondary};
  :hover {
    text-decoration: underline;
    cursor:pointer;
  }
`

export const Contact = () => {
    let email = 'contact@winc.works'
    return (
        <>
            <Title>{'Contact'}</Title>
            <SubTitle>{'You can contact us through the following email address'}</SubTitle>
            <StyledLink href={`mailto:${email}`}>{email}</StyledLink>
        </>
    )
}