import { AuthenticationService } from '../services'
import { userConstants } from "../constants/userConstants"

export const login = (username, password) => {
    const {LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE} = userConstants
    return {
        types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
        payload: {
            request: AuthenticationService.login(username, password)
        }
    }
}

export const resetPassword = (username) => {
    const {FORGOT_PASSWORD_REQUEST} = userConstants
    return {
        type: FORGOT_PASSWORD_REQUEST,
        payload: {
            request: AuthenticationService.resetPassword(username)
        }
    }
}

export const forceResetPassword = (user) => {
    const {FORCE_RESET_PASSWORD} = userConstants
    return {
        type: FORCE_RESET_PASSWORD,
        payload: {
            request: AuthenticationService.forceChangePassword(user)
        }
    }
}

export const registerUser = (user) => {
    const {REGISTER_REQUEST} = userConstants
    return {
        type: REGISTER_REQUEST,
        payload: {
            request: AuthenticationService.register(user)
        }
    }
}


export const logout = () => {
    return dispatch => dispatch({
        type: userConstants.LOGOUT
    })
}