import React from 'react'
import NumberFormat from 'react-number-format'
import styled from 'styled-components'

const NumberFormatter = styled(NumberFormat)`
  font-family: Ubuntu, monospace;
  outline: none;
  border: none;
  font-size: 14px;
  padding: 14px 5px;
  flex: 1;
  background-color: #f9f7fa;
`
export const CustomNumberFormat = (props) => {
    const {inputRef, onChange, name, value} = props
    return (
        <NumberFormatter
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.formattedValue,
                        name
                    },
                    isTrusted: true
                })
            }}
            value={value}
            format="##/##/####"
            placeholder="MM/DD/YYYY"
        />
    )
}
