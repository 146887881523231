export const appTheme = {
    primary: '#700cff',
    secondary: '#e045cb',
    thirtiary: '#59dfb8',
    error: '#ff26b4',
    input: {
        active: '#700cff',
        error: '#ff26b4',
        default: '#9081a5'
    },
    button: {
        activeText: 'white',
        inactiveText: '#b1a5c5',
        activeBg: '#700cff',
        inactiveBg: '#9081a5'
    }
}