import { store } from '../../store'

const accountId = () => {
    return store.getState().authentication.user.accountId
}

const teamId = () => {
    return store.getState().authentication.user.teamId
}

const accountUserId = () => {
    return store.getState().authentication.user.id
}

export { accountId, teamId, accountUserId }