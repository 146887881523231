import React, { Component } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { ConnectedTeamCategoriesPage } from "./categories/CategoriesPage.container"
import { ConnectedDashboardPage } from "./main-dashboard/dashboard.container"
import {
    AboutPage,
    ConnectedAccountPage,
    ConnectedCategoriesSettingsPage,
    ConnectedCategorySwapPage,
    SettingsPage
} from "../settings"
import { PrivateRoute } from "../../utils/PrivateRoute"
import { AuthCache } from "../../utils/AuthCache"
import { ConnectedTeamLevelCategoryPage } from "./categories/TeamLevelCategoryPage.container"
import { ConnectedAddEditWincPage } from "./person/AddEditWincPage.container"
import { ConnectedAddPersonPage } from "./people/AddEditPersonPage.container"
import { ConnectedNavigationBar } from "../appBar/NavigationBar.container"
import { ConnectedPeoplePage } from './people/PeoplePage.container'
import { ConnectedProfilePage } from './person/ProfilePage.container'
import { ConnectedWincListPage } from './person/WincListPage.container'

export class HomePage extends Component {

    _showNavBar = () => {
        const publicPaths = ['/', '/login', '/signup', '/verify-email', '/forgot-password', '/reset-password']
        let index = publicPaths.indexOf(this.props.location.pathname)
        return AuthCache.currentToken() && index === -1
    }

    render() {
        return (<div>
            {this._showNavBar() &&
            <ConnectedNavigationBar {...this.props}/>}
            <Switch location={this.props.location}>
                <PrivateRoute exact path='/dashboard' component={ConnectedDashboardPage}/>
                <PrivateRoute exact path='/people' component={ConnectedPeoplePage}/>
                <PrivateRoute exact path='/categories' component={ConnectedTeamCategoriesPage}/>
                <PrivateRoute exact path='/categories/:categoryId'
                              component={ConnectedTeamLevelCategoryPage}/>
                <PrivateRoute path={`/people/:profileId`}
                              component={ConnectedProfilePage}/>
                <PrivateRoute exact path='/settings' component={SettingsPage}/>
                <PrivateRoute path='/settings/category-settings'
                              component={ConnectedCategoriesSettingsPage}/>
                <PrivateRoute path='/settings/swap-wincs'
                              component={ConnectedCategorySwapPage}/>
                <PrivateRoute path='/settings/about' component={AboutPage}/>
                <PrivateRoute path='/settings/account-settings' component={ConnectedAccountPage}/>
                <PrivateRoute exact path='/add-or-edit-winc' component={ConnectedAddEditWincPage}/>
                <PrivateRoute exact path='/add-or-edit-person' component={ConnectedAddPersonPage}/>
                <PrivateRoute path='/show-wincs/:categoryId' component={ConnectedWincListPage}/>
                {AuthCache.currentToken() && <Redirect from={"/*"} to={"/dashboard"}/>}
            </Switch>
        </div>)
    }
}