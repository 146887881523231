import React, { Component } from 'react'
import styled from 'styled-components'
import { Person } from './helpers/Person'
import { AddPerson } from './helpers/AddPerson'
import { Clickable, fontDiv, FullPageContainer, InputTextField, TitleContainer } from "../../common"
import { showError, sortAlphabetically } from '../../../utils'
import { Images } from '../../../assets'
import Cancel from '@material-ui/icons/Cancel'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { media } from '../../../utils'

const iconSize = 30
const iconStyle = {width: iconSize, height: iconSize}
const StyledClose = styled(Cancel).attrs({style: iconStyle})``

const GridContainer = styled.div`
  float: none;
  margin: auto;
  display: flex;
  max-width: 1130px;
  padding:0 0 30px 0 !important;
`

const Header = styled(TitleContainer)`
  margin-bottom: 50px;
  justify-content: space-between;
  ${
    media.phone`
        margin-bottom: unset;
    `
}
`

const Title = styled(fontDiv)`
  font-size: 35px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #450b96;
`
const SearchArea = styled.form`
  display: flex;
  flex: 1;
  margin: -10px 0 0 30px;
`

const SearchIcon = styled.img`
  margin: 5px;
  height: 30px;
  width: 30px;
`
const Grid = styled.div`
  float: none;
  margin: auto;
  display: grid;
  flex: 1;
  grid-template-columns: repeat(auto-fill, minmax(320px, auto));
  grid-template-rows: repeat(auto-fill, minmax(40px, auto));
  grid-column-gap: 41px;
  grid-row-gap: 80px;
  background-color: transparent;
  justify-content: center;
  width: 100%;
`

class PeoplePage extends Component {
    constructor() {
        super()
        // this.peopleCopy = []
        this.state = {people: [], searchText: '', enableSearch: false, peopleCopy: []}
        this.inputRef = React.createRef()
    }

    componentDidMount() {
        this.props.resetSelectedUser()
        this._loadPeople()
    }

    _loadPeople = () => {
        this.props.startLoading()
        this.props.getTeamWincsSummary()
            .then(response => {
                this.props.stopLoading()
                let people = sortAlphabetically(response.payload.data.slice())
                this.setState({
                    people: people,
                    peopleCopy: people
                })
            })
            .catch(e => {
                this.props.stopLoading()
                showError(e)
            })
    }

    _peopleItemsUI = () => {
        return this.state.people.map((p, index) => <Person index={index + 1} key={`${index + 1}`}
                                                           profileInfo={p}
                                                           onClick={this._onClickPerson}/>)
    }

    _onClickPerson = (profileInfo) => {
        // this.props.selectUser(profileInfo)
        const {id} = profileInfo
        this.props.history.push(this.props.location.pathname + `/${id}`)
    }

    _addPerson = () => {
        this.props.history.push('add-or-edit-person')
    }

    _onChangeText = (e) => {
        if (!e.isTrusted) return
        let {name, value} = e.target
        const filterIt = (arr, searchKey) => {
            return arr.filter(obj => obj.name.toLowerCase().includes(searchKey.toLowerCase()))
        }
        let newPeople = value.length > 0 ? filterIt(this.state.people, value) : this.state.peopleCopy
        this.setState({...this.state, [name]: value, people: newPeople})
    }

    _startSearching = () => {
        this.setState({...this.state, searchText: '', enableSearch: true})
    }

    _cancelSearch = () => {
        this.setState({
            ...this.state,
            searchText: '',
            enableSearch: false,
            people: this.state.peopleCopy
        })
    }

    render() {
        const {enableSearch = false, searchText, peopleCopy} = this.state
        return (
            <FullPageContainer>
                <Header>
                    <Title>{'Team'}</Title>
                    {
                        enableSearch &&
                        <SearchArea autoComplete="off">
                            <InputTextField
                                style={{flex: 1, display: 'flex'}}
                                placeholder={'Search here'}
                                name={'searchText'}
                                autoFocus
                                value={searchText}
                                onChange={this._onChangeText}
                                InputProps={{
                                    style: {
                                        backgroundColor: '#f9f7fa',
                                        padding: 5,
                                        color: '#700cff',
                                        fontFamily: 'Ubuntu, monospace'
                                    },
                                    endAdornment:
                                        <InputAdornment
                                            variant="standard"
                                            key={'close'}
                                            position="end">
                                            <IconButton
                                                style={{
                                                    padding: 5,
                                                    margin: 0
                                                }}
                                                onClick={this._cancelSearch}>
                                                <StyledClose/>
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        </SearchArea>
                    }
                    {
                        !enableSearch && peopleCopy.length > 0 &&
                        <Clickable onClick={this._startSearching}>
                            <SearchIcon src={Images.searchIcon}/>
                        </Clickable>
                    }

                </Header>
                <GridContainer>
                    <Grid>
                        {!enableSearch && <AddPerson onClick={this._addPerson}/>}
                        {this._peopleItemsUI()}
                    </Grid>
                </GridContainer>
            </FullPageContainer>)
    }
}

export { PeoplePage }
