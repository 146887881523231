import React, { Component } from 'react'
import styled from 'styled-components'
import { AlertDialog, fontDiv, FullPageContainer } from "../../common"
import { SelectScore } from "./helpers/SelectScore"
import { SelectImpact } from "./helpers/SelectImpact"
import { WincActions } from "./helpers/WincActions"
import { SelectCategory } from "./helpers/SelectCategory"
import { currentDate, showError, sortAlphabetically, utcTimeStamp } from "../../../utils"
import { PersonHeader } from './PersonHeader'

const Grid = styled.div`
  display: grid;
  flex: 1;
  grid-template-columns: 1fr;
  grid-template-rows: 200px;
  grid-row-gap: 32px;
  padding-top: 5px;
`

const GridContainer = styled.div`
  float: none;
  margin: auto;
  display: flex;
  max-width: 600px;
`

export const Title = styled(fontDiv)`
  font-size: 35px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5f5662;
`

export class AddEditWincPage extends Component {
    constructor(props) {
        super(props)
        // this.personView = React.createRef()
        let {profileInfo = {}} = props
        this.isEditMode = profileInfo.selectedWinc !== undefined
        let {selectedWinc = {}, selectedCategory = {}} = profileInfo
        let categorySelected = undefined
        if (this.isEditMode) {
            let {category, categoryId} = selectedCategory
            categorySelected = {name: category, id: categoryId}
        }

        let {
            title = '',
            description = '',
            score,
            impact,
        } = selectedWinc

        this.state = {
            isDataLoaded: false,
            winc: {
                title,
                description
            },
            category: {
                list: [],
                selected: categorySelected
            },
            score: {
                list: [],
                selected: score
            },
            impact: {
                list: [],
                selected: impact
            }
        }
    }

    componentDidMount() {
        console.log('Hello////', this.personView)
        this._loadContent()
    }

    _loadContent = () => {
        const _getSelected = (impacts, selectedValue) => {
            if (!selectedValue) {
                return undefined
            }
            let filteredImpacts = impacts.filter(i => i.value === selectedValue)
            if (filteredImpacts.length > 0) {
                return filteredImpacts[0]
            }
        }

        const {listAllCategories, getAllScores, getAllImpacts, startLoading, stopLoading} = this.props
        startLoading()
        Promise.all([listAllCategories(), getAllScores(), getAllImpacts()])
            .then(values => {
                stopLoading()
                let categories = values[0].payload.data
                let scores = values[1].payload.data
                let impacts = values[2].payload.data
                this.setState({
                    ...this.state,
                    isDataLoaded: true,
                    category: {
                        ...this.state.category,
                        list: sortAlphabetically(categories)
                    },
                    score: {
                        ...this.state.score,
                        list: scores
                    },
                    impact: {
                        ...this.state.impact,
                        list: impacts,
                        selected: _getSelected(impacts, this.state.impact.selected)
                    }
                })
            })
            .catch(e => {
                stopLoading()
                showError(e)
            })
    }

    _onChangeTitle = title => {
        this.setState({...this.state, winc: {...this.state.winc, title}})
    }

    _onChangeDescription = description => {
        this.setState({...this.state, winc: {...this.state.winc, description}})
    }

    _onSelectCategory = category => {
        this.setState({...this.state, category: {...this.state.category, selected: category}})
    }

    _onSelectScore = score => {
        this.setState({...this.state, score: {...this.state.score, selected: score}})
    }

    _onSelectImpact = impact => {
        this.setState({...this.state, impact: {...this.state.impact, selected: impact}})
    }

    _saveWinc = () => {
        const {winc, category, impact, score} = this.state
        let {title, description} = winc
        const {profileInfo = {}} = this.props
        const {selectedWinc = {}} = profileInfo

        if (category.selected && impact.selected && score.selected && title && description) {
            var newWinc = {
                userId: profileInfo.id,
                categoryId: category.selected.id,
                score: score.selected,
                impact: impact.selected.value,
                title,
                description,
                timeMilliSeconds: utcTimeStamp(),
                date: currentDate()
            }

            if (this.isEditMode) {
                newWinc = {...newWinc, id: selectedWinc.id}
            }
            const {addWinc, updateWinc, startLoading, stopLoading} = this.props
            let wincCall = this.isEditMode ? updateWinc : addWinc

            startLoading()
            wincCall(newWinc)
                .then(r => {
                    stopLoading()
                    if (!this.isEditMode) {
                        this.personView.refreshIndividualWincSummary()
                    }
                    this.alert.showContent('Success!', `WinC has been ${this.isEditMode ? 'updated' : 'added'} successfully.`, this.props.history.goBack)
                })
                .catch(e => {
                    stopLoading()
                    showError(e)
                })
            return
        }
        this.alert.showContent('Warning!', 'Please select all the fields to create WinC')
    }

    _cancelEditing = () => {
        this.props.history.goBack()
    }

    render() {
        const {impact, score, category, winc, isDataLoaded} = this.state
        return (
            <FullPageContainer>
                <PersonHeader {...this.props} canAddWinc={false}
                              innerRef={c => this.personView = c}/>
                {isDataLoaded && <GridContainer>
                    <Grid>
                        <SelectCategory list={category.list}
                                        selected={category.selected}
                                        onSelectCategory={this._onSelectCategory}
                                        wincTitle={winc.title}
                                        onChangeTitle={this._onChangeTitle}
                                        wincDescription={winc.description}
                                        onChangeDescription={this._onChangeDescription}
                        />
                        <SelectScore list={score.list}
                                     selected={score.selected}
                                     onSelectScore={this._onSelectScore}/>
                        <SelectImpact list={impact.list}
                                      selected={impact.selected}
                                      onSelectImpact={this._onSelectImpact}/>
                        <WincActions onCancel={this._cancelEditing} onSave={this._saveWinc}/>
                    </Grid>
                </GridContainer>}
                <AlertDialog ref={c => this.alert = c} title={'Hello'} message={'My Message'}/>
            </FullPageContainer>
        )
    }
}