import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { fontDiv } from '../../../common'
import styled from 'styled-components'
import { Images } from '../../../../assets'
import { ActionButton } from './ActionButton'

const Icon = styled.img`
  width: 170px;
  margin: 20px 0 0 0;
  align-self: center;
`

const Title = styled(fontDiv)`
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  padding: 5px 30px 0 30px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3d3d3d;
`

const SubTitle1 = styled(Title)`
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  margin: 8px 40px 10px 40px;
  letter-spacing: normal;
  color: #978b9b;
`

const SubTitle2 = styled(SubTitle1)`
  margin: 0 0 35px 10px;
`

const SubTitle3 = styled(SubTitle1)`
  margin: 0 0 40px 10px;
  font-size: 12px;
`


class ReAssignDialog extends React.Component {
    constructor() {
        super()
        this.state = {open: false, count: 0, category: 'N/A'}
    }

    show = (peopleCount, category) => {
        this.setState({open: true, count: peopleCount, category})
    }

    hide = () => {
        this.setState({open: false})
        if (this.state.onClick) {
            this.state.onClick()
        }
    }

    _dismiss = callback => {
        this.setState({open: false})
        callback()
    }

    render() {
        const {onReassign, onDeleteOnly} = this.props
        const {count = 0, category = 'N/A'} = this.state
        return (
            <Dialog
                maxWidth={'sm'}
                fullWidth={false}
                open={this.state.open}
                scroll={'paper'}
                onClose={this.hide}
                PaperProps={{
                    style: {
                        boxShadow: '0 2px 6px 0 rgba(159, 145, 164, 0.26)',
                        width: 450
                    },
                }}
                BackdropProps={{
                    style: {
                        backgroundColor: 'rgba(255, 255, 255, 0.64)'
                    }
                }
                }
                aria-labelledby="max-width-dialog-title"
            >
                <Icon src={Images.warningIcon}/>
                <Title>{'Re-Assign?'}</Title>
                <SubTitle1>{`Would you like to re-assign the WinCs before deleting “${category}”`}</SubTitle1>
                <SubTitle2>{`${count} team member${count > 1 ? 's have' : ' has'} WinCs with this category`}</SubTitle2>
                <ActionButton
                    onClick={() => this._dismiss(onReassign)}>{'RE-ASSIGN WinCs'}</ActionButton>
                <ActionButton transparent
                              onClick={() => this._dismiss(onDeleteOnly)}>{'DELETE ONLY'}
                </ActionButton>
                <SubTitle3>{'All the information associated will be deleted'}</SubTitle3>
            </Dialog>
        )
    }
}

export { ReAssignDialog }