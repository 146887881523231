import React, { Component } from 'react'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import PropTypes from 'prop-types'
import { GraphContainer } from "./common/GraphContainer"
import { tickStyle, ValueToolTip } from "./common"
import { isDataAvailable } from './util'
import { NoData } from '../../common'
import { roundValue } from '../../../utils'


const defaultData =
    {
        '1': 29.6,
        '2': 10.3,
        '3': 5.3,
        '4': 15.3,
        '5': 14.3,
        '6': 12.3,
        '8': 19.3,
        '9': 20.3,
        '10': 23.3,
        '12': 13,
        '15': 11
    }

class RatioGraph extends Component {
    _transformData = data => {
        return Object.keys(data).map(v => ({
            name: v, value: roundValue(data[v])
        }))
    }

    render() {
        let {data, isFlex} = this.props
        let displayData = this._transformData(data)
        let dataAvailable = isDataAvailable(displayData)
        return (
            <GraphContainer title={'Score Distribution'}
                            isFlex={isFlex}
                            showIndicator={dataAvailable}>
                {
                    dataAvailable ?
                        <ResponsiveContainer height='100%' width='100%' aspect={undefined}>
                            <BarChart data={displayData} label={{position: 'top'}}>
                                <XAxis dataKey="name" axisLine={false} tickLine={false}
                                       tick={tickStyle}/>
                                <Bar dataKey="value" fill={"#700cff"}
                                     isAnimationActive={false}
                                     animationDuration={1000}
                                     radius={2}/>
                                <YAxis scale={'linear'} hide/>
                                <Tooltip cursor={false}
                                         content={<ValueToolTip/>}/>
                            </BarChart>
                        </ResponsiveContainer> :
                        <NoData/>
                }
            </GraphContainer>
        )
    }
}

RatioGraph.propTypes = {
    data: PropTypes.object.isRequired,
    isFlex: PropTypes.bool
}


RatioGraph.defaultProps = {
    data: defaultData,
    isFlex: false
}

export { RatioGraph }
