import {
    RESET_SELECTED_CATEGORY,
    RESET_SELECTED_USER,
    RESET_SELECTED_WINC,
    SELECT_CATEGORY,
    SELECT_USER,
    SELECT_WINC,
    UPDATE_SELECTED_USER,
    UPDATE_WINC_COUNT_BY_FOR_SELECTED_USER,
    UPDATE_WINC_COUNT_FOR_SELECTED_USER
} from '../actions/user'
import { userConstants } from '../constants/userConstants'

export const selectedUser = (state = {}, action) => {
    switch (action.type) {
        case SELECT_USER:
            return {
                ...action.userInfo
            }
        case UPDATE_SELECTED_USER: {
            return {
                ...state, ...action.userInfo
            }
        }
        case SELECT_CATEGORY:
            return {
                ...state, selectedCategory: {...action.selectedCategory}
            }
        case SELECT_WINC:
            return {
                ...state, selectedWinc: {...action.selectedWinc}
            }
        case RESET_SELECTED_WINC: {
            let {selectedWinc, ...restOfState} = state
            return restOfState
        }
        case RESET_SELECTED_CATEGORY: {
            let {selectedCategory, ...restOfState} = state
            return restOfState
        }
        case UPDATE_WINC_COUNT_FOR_SELECTED_USER: {
            return {
                ...state, totalWincs: action.wincCount
            }
        }
        case UPDATE_WINC_COUNT_BY_FOR_SELECTED_USER: {
            return {
                ...state, totalWincs: state.totalWincs + action.count
            }
        }
        case RESET_SELECTED_USER: {
            return {}
        }
        case userConstants.LOGOUT:
            return {}
        default:
            return state
    }
}