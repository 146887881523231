import styled from "styled-components"
import TextField from "@material-ui/core/TextField"

export const SearchTextField = styled(TextField)``

export const InputTextField = styled(TextField)
    .attrs({
        style: {
            marginTop: 10,
        },
        FormHelperTextProps: p => {
            return {
                style: {
                    fontSize: 11,
                    lineHeight: p.error ? undefined : '14px',
                    color: p.error ? undefined : '#9f91a4',
                    fontFamily: 'Ubuntu, monospace'
                }
            }
        },
        InputProps: p => ({
            style: {
                backgroundColor: '#f9f7fa',
                padding: 5,
                fontFamily: 'Ubuntu, monospace'
            }
        }),
        InputLabelProps: {
            style: {
                zIndex: 2,
                padding: 5,
                pointerEvents: 'none',
                fontFamily: 'Ubuntu, monospace'
            }
        }
    })`
      outline: none;
      padding: 0;
      display: flex;
      align-self: stretch;
      box-sizing: border-box;
      border-radius: 0;
      font-size: 15px;
      margin-top: 30px;
`
