export class StringUtils {
    static getIntitials = (string, charCount = 2) => {
        if (!string || string.length === 0) {
            return ""
        }
        let upperCaseString = string.toUpperCase()
        if (charCount === 1) {
            return upperCaseString.charAt(0)
        }
        let words = upperCaseString.split(" ")
        if (words.length > 1) {
            return words[0].charAt(0) + words[1].charAt(0)
        }
        return upperCaseString.charAt(0) + (upperCaseString.length > 1 ? upperCaseString.charAt(1) : "")
    }
}