import React from 'react'
import styled from "styled-components"
import { CategoryHeader } from "../../categories/common/CategoryHeader"
import { SummaryRawGraph } from "../../graphs"

const CategoryContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    border-radius: 4px;
    background-color: white;
    height: 227px;
    box-sizing: border-box;
    box-shadow: 0 1px 5px 0 rgba(179, 168, 181, 0.23);
    &:hover {
        box-shadow: 0 2px 7px 5px rgba(179, 168, 181, 0.33);
        transform: scale(1.0);
        transition-duration: 0.4s;
    }
    &:active {
        transform: scale(0.98);
        transition-duration: 0.4s;
    }
`

export const Category = props => {
    const {onClick, info} = props
    const {category, totalWincs = 0, ...graphData} = info
    return (
        <CategoryContainer onClick={() => onClick(info)}>
            <CategoryHeader name={category} wincCount={totalWincs}/>
            <SummaryRawGraph data={graphData}/>
        </CategoryContainer>
    )
}
