import React, { Component } from 'react'
import styled from 'styled-components'
import {
    AbsoluteFullPageContainer,
    AlertDialog,
    Button,
    CountTitle,
    CustomNumberFormat,
    InputTextField,
    TitleContainer
} from '../../common'
import { getDateFromString, showError, toUTCDate } from '../../../utils'


const Form = styled.form`
  min-height: 531px;
  padding: 0 85px;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
`

const PageContainer = styled(AbsoluteFullPageContainer)`
    background-color: white;
`

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
`

const TitleDisplay = styled(TitleContainer)`
  flex: unset;
  width: 600px;
  padding: 85px 85px 30px 85px;
  background-color: white;
  box-sizing: border-box;
`

export class AccountPage extends Component {

    constructor(props) {
        super(props)
        let {user: {userName, fullName, birthDate, hobbies}} = props
        birthDate = birthDate != null && birthDate.length > 0 ? getDateFromString(birthDate, 'MM/DD/YYYY') : ''
        this.state = {
            email: userName,
            name: fullName,
            birthday: birthDate,
            hobbies: hobbies === null ? '' : hobbies,
            errors: {
                email: '',
                name: '',
                birthday: '',
                hobbies: ''
            }
        }
        let {errors, ...rest} = this.state
        this.stateCopy = rest
    }

    _onChangeText = (e) => {
        if (!e.isTrusted) return
        let {name, value} = e.target
        this.setState({...this.state, [name]: value})
    }

    _onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this._createOrUpdateUser(e)
        }
    }

    _canUpdate = () => {
        const {name, birthday, hobbies} = this.state
        return name !== this.stateCopy.name || birthday !== this.stateCopy.birthday || hobbies !== this.stateCopy.hobbies
    }

    _updateAccount = (e) => {
        e.preventDefault()
        const {name, email, birthday, hobbies} = this.state
        let user = {
            fullName: name,
            id: this.props.user.id,
            userName: email,
            birthDate: birthday !== '' ? toUTCDate(birthday) : '',
            hobbies
        }
        this.props.startLoading()
        this.props.updateAccountUser(user)
            .then(response => {
                this.props.stopLoading()
                this.props.updateLocalAccountUser(response.payload.data)
                this.alert.show(this.props.history.goBack)
            })
            .catch(e => {
                    this.props.stopLoading()
                    showError(e)
                }
            )
    }

    render() {
        const {
            email,
            name,
            birthday,
            hobbies,
            errors
        } = this.state
        return (
            <PageContainer>
                <ContentContainer>
                    <TitleDisplay>
                        <CountTitle title={'Account'}
                                    onClickBack={this.props.history.goBack}
                                    displayCount={false}/>
                    </TitleDisplay>
                    <Form onSubmit={this._updateAccount}
                          onKeyPress={this._onKeyPress}>
                        <InputTextField
                            name={'name'}
                            label={'Full Name*'}
                            value={name}
                            placeholder="Full Name"
                            helperText={errors.name ? errors.name : ' '}
                            onChange={this._onChangeText}
                        />

                        <InputTextField
                            name={'email'}
                            label={'Email*'}
                            value={email}
                            disabled
                            placeholder="Email"
                            helperText={errors.email ? errors.email : ' '}
                            onChange={this._onChangeText}
                        />

                        <InputTextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name={'birthday'}
                            type="date"
                            value={birthday}
                            placeholder="Birthday"
                            label={'Birthday'}
                            helperText={errors.name ? errors.name : ' '}
                            onChange={this._onChangeText}
                            InputProps={{
                                inputComponent: CustomNumberFormat
                            }}
                        />

                        <InputTextField
                            name={'hobbies'}
                            label={'Hobbies'}
                            value={hobbies}
                            placeholder="Hobbies"
                            helperText={errors.name ? errors.name : ' '}
                            onChange={this._onChangeText}
                        />
                        <Button style={{marginTop: 30}}
                                disabled={!this._canUpdate()}
                                width={228}
                                type={'submit'}>{'Save'}</Button>
                        <AlertDialog ref={c => this.alert = c} title={'Success!'}
                                     message={'Your account has been updated successfully'}/>
                    </Form>
                </ContentContainer>
            </PageContainer>
        )
    }
}
