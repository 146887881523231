import styled from 'styled-components'
import { Clickable } from "../../../common"
import { media } from '../../../../utils'

export const DateButton = styled(Clickable)`
  height: 30px;
  width: 108px;
  border-radius: 15px;
  background-color: ${p => p.active ? `#700cff` : `#f9f7fa`};
  color: ${p => p.active ? `white` : `#5b12c2`};
  margin: 0 10px 0 10px;
  font-size: 10px;
  font-weight: 500;
  justify-content: center;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  ${
    media.phone`
        margin: 0 5px;
        padding: 3px;
        width: unset;
        flex: 1;
    `
}
`
