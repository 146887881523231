import React from 'react'
import styled from "styled-components"
import { fontDiv } from "../../../common"
import { getDateFromString, media } from '../../../../utils'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Name = styled(fontDiv)`
  font-size: 55px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #450b96;
  ${
    media.phone`
        font-size: 25px;
    `
}
`
const Birthday = styled(Name)`
  font-size: 18px;
  font-weight: 200;
  color: #8a7c9e;
  padding: 5px 0 0 0;
  ${
    media.phone`
        display: none;
    `
}
  
`

const Hobbies = styled(Birthday)`
`

export const PersonDetails = props => {
    let {profileInfo = {}} = props
    let {name, hobbies, birthDate} = profileInfo
    let date = getDateFromString(birthDate, 'MM/DD')
    return (
        <Container>
            <Name>{name}</Name>
            <Birthday>{`Birthday: ${date}`}</Birthday>
            <Hobbies>{hobbies}</Hobbies>
        </Container>
    )
}
