import React from "react"
import styled from "styled-components"
import { fontText } from "../../../common"

const Label = styled(fontText).attrs({
    textAnchor: "middle"
})`
  fill: white;
  font-size: 11px;
  font-weight: 500;
  padding-bottom: 5px;
  background-color: yellow;
  text-align: center;
`

export const BarLabel = props => {
    const {x, y, value, width} = props
    return (
        value === 0 ? <></> :
            <Label
                x={x + width / 2}
                y={y}
                dx={-2}
                dy={14}
            >{value}</Label>
    )
}
