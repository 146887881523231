import React, { Component } from 'react'
import {
    Area,
    AreaChart,
    CartesianGrid,
    Label,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts'
import { GraphContainer } from "./common/GraphContainer"
import { DateRangeHelper, tickStyle, TimeGraphToolTip, getDefaultSelectedRange } from "./common"
import PropTypes from 'prop-types'
import { getDateFromString, toDate } from '../../../utils'
import { NoData } from '../../common'

const defaultData = []

class WincTimeGraph extends Component {
    constructor(props) {
        super(props)
        this.dataSort = true
        this.state = {data: this._transformData(props.data)}
        this.maxValue = Math.max.apply(Math, props.data.map(e => e.wincCount))
    }

    static getDefaultRange = () => {
        return getDefaultSelectedRange()
    }

    _transformData = (data) => {
        let format = 'MM/DD'
        if (data.length > 30) {
            format = 'MM/YYYY'
        }
        const compare = (obj1, obj2) => {
            if (obj1.date < obj2.date)
                return -1
            if (obj1.date > obj2.date)
                return 1
            return 0
        }
        data.sort(compare)
        return data.map(({date, wincCount}) => ({
            wincCount,
            date: getDateFromString(toDate(date), format)
        }))
    }

    AxisY = () => {
        return (
            <YAxis type="number" dataKey={'wincCount'}
                   tickLine={false}
                   width={50}
                   offset={0}
                   tick={tickStyle}
                   allowDecimals={false}
            >
                <Label fill={'#b1a5c5'}
                       value="WinCs"
                       angle={-90}
                       fontSize={12}
                       fontFamily={"Ubuntu, monospace"}
                       position="insideLeft"/>
            </YAxis>
        )
    }

    AxisX = () => {
        return (
            <XAxis dataKey={'date'}
                   tickLine={false}
                   height={50}
                   tick={tickStyle}
            >
                <Label fill={'#b1a5c5'}
                       value="Days"
                       fontSize={12}
                       fontFamily={"Ubuntu, monospace"}
                       position="insideBottom"/>
            </XAxis>
        )
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ...this.state,
            data: this._transformData(nextProps.data)
        })
    }

    render() {
        let {isFlex, title, onSelectDateRange} = this.props
        const {data} = this.state
        let dataAvailable = data.length > 0
        return (
            <GraphContainer title={title} isFlex={isFlex}
                            showIndicator={false}
                            isAdaptive
                            actionComponent={<DateRangeHelper
                                onSelectRange={onSelectDateRange}/>}>
                {
                    dataAvailable ?
                        <ResponsiveContainer height='100%' width='100%' aspect={undefined}>
                            <AreaChart data={data}
                                       margin={{left: 5, right: 10, top: 10, bottom: 5}}>
                                <defs>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="2%" stopColor={'rgba(163, 18, 194, 0.3)'}
                                              stopOpacity={0.4}/>
                                        <stop offset="98%" stopColor="white" stopOpacity={0}/>
                                    </linearGradient>
                                </defs>
                                {this.AxisX()}
                                {this.AxisY()}
                                <CartesianGrid stroke={'lightgray'} strokeWidth={0.5}/>
                                <Tooltip cursor={false} content={<TimeGraphToolTip/>}/>
                                {/*<Tooltip/>*/}
                                <Area type="monotone" dataKey="wincCount" strokeDasharray="2 2"
                                      stroke="gray"
                                      fillOpacity={1.0}
                                      isAnimationActive={false}
                                      dot={{
                                          hide: false,
                                          r: 4,
                                          fill: '#700cff',
                                          stroke: 'transparent'
                                      }}
                                      activeDot={{r: 5, stroke: 'transparent', fill: '#700cff'}}
                                      fill="url(#colorPv)"/>

                            </AreaChart>
                        </ResponsiveContainer> : <NoData/>
                }
            </GraphContainer>
        )
    }
}

WincTimeGraph.propTypes = {
    data: PropTypes.array.isRequired,
    isFlex: PropTypes.bool,
    title: PropTypes.string.isRequired,
    onSelectDateRange: PropTypes.func.isRequired
}

WincTimeGraph.defaultProps = {
    data: defaultData,
    isFlex: false,
    onSelectDateRange: () => {
    }
}

export { WincTimeGraph }
