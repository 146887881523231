import { bindActionCreators } from "redux"
import * as userActions from "../../../actions/user"
import { connect } from 'react-redux'
import { PeoplePage } from './PeoplePage'
import { WithLoaderContext } from '../../WithLoaderContext'

const mapStateToProps = () => ({})


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(userActions, dispatch)
}

export const ConnectedPeoplePage = connect(mapStateToProps, mapDispatchToProps)(WithLoaderContext(PeoplePage))