import React, { Component } from 'react'
import styled from "styled-components"
import { SummaryRawGraph } from "../../graphs"
import { Clickable, fontDiv } from "../../../common"
import { Images } from "../../../../assets"
import PropTypes from 'prop-types'
import { getDateFromString } from "../../../../utils"

const WincContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    border-radius: 4px;
    background-color: white;
    height: 240px;
    box-sizing: border-box;
    box-shadow: 0 1px 5px 0 rgba(179, 168, 181, 0.23);
`

const WincTitle = styled(fontDiv)`
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #450b96;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 8px 10px 8px 10px;
`

const WincDescription = styled(fontDiv)`
    display: none;
    font-weight: normal;
  ${WincTitle}:hover & {
    z-index: 20;
    background-color: white;
    padding: 10px;
    position: absolute;
    display: unset;
    box-shadow: 0 1px 5px 0 gray;
    border-radius: 3px;
    width: 290px;
  }
`

const ActionsContainer = styled.div`
  height: 68px;
  background-color: #f9f7fa;
  margin: 0 0 20px 0;
  padding: 0 5px 0 10px;
  display: flex;
  align-items: center;
`

const Date = styled(fontDiv)`
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b1a5c5;
`
const Line = styled.div`
  flex-direction: column;
  width: 1px;
  align-self: stretch;
  background-color: #f0e6ff;
  margin: 5px;
`

const Space = styled.div`
  height: 1px;
  flex: 1;
`

const NoteIcon = styled.img`
  height: auto;
  padding: 5px;
  width: 22px;
`
const ButtonContainer = styled(Clickable)`
   width: 40px;
   height: 40px;
   justify-content: center;
   align-items: center;
   border: none;
   :active {
      transform: scale(0.80);
   }
`

const Icon = styled.img`
  width: ${p => p.width}px;
  margin: 0;
  align-self: center;
`


export class WinC extends Component {

    static defaultProps = {
        info: {
            title: 'Great job organizing the design sprint',
            createdDate: '2019-01-13T18:10:58.473Z',
            ratio: 15,
            score: 5,
            impact: 3
        }
    }

    static propTypes = {
        info: PropTypes.object.isRequired,
        editWinc: PropTypes.func.isRequired,
        deleteWinc: PropTypes.func.isRequired
    }

    render() {
        const {info, deleteWinc, editWinc} = this.props
        const {title, createdDate, description} = info
        const data = (({ratio, score, impact}) => ({
            ratio, score, impact
        }))(info)

        let textLimit = 55
        let isExceeded = title.length > textLimit
        let newTitle = title.substring(0, isExceeded ? textLimit : title.length) + (isExceeded ? '...' : '')
        return (
            <WincContainer>
                <WincTitle>
                    {newTitle}
                    <WincDescription>
                        {description}
                    </WincDescription>
                </WincTitle>
                <ActionsContainer>
                    <Date>{getDateFromString(createdDate, 'MM/DD/YYYY')}</Date>
                    <NoteIcon src={Images.noteIcon}/>
                    <Space/>
                    <Line/>
                    <ButtonContainer onClick={() => editWinc(info)}>
                        <Icon width={17} src={Images.editWinc}/>
                    </ButtonContainer>
                    <Line/>
                    <ButtonContainer onClick={() => deleteWinc(info)}>
                        <Icon width={15} src={Images.trash}/>
                    </ButtonContainer>
                </ActionsContainer>
                <SummaryRawGraph data={data}/>
            </WincContainer>
        )
    }
}

/*
{
        "id": 19,
        "createdDate": "2018-12-26T02:29:56.911+0000",
        "modifiedDate": null,
        "modifiedBy": "sreenivas@email.ghostinspector.com",
        "title": "Sample Title",
        "description": "Sample Description",
        "accountId": 20,
        "teamId": 18,
        "categoryId": 15,
        "score": 4,
        "impact": 2,
        "ratio": 8,
        "timeMilliSeconds": 1545750585364,
        "userId": 4
    }

* */
