import React from "react"
import PropTypes from 'prop-types'
import styled from "styled-components"
import { fontDiv } from "./RootFontComponents"
import { Images } from "../../assets"
import { Clickable } from "./Clickable"

export const Title = styled(fontDiv)`
  font-size: ${({titleSize = 35}) => titleSize}px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${p => p.titleColor};
  vertical-align: middle;
`

export const Count = styled(fontDiv)`
    margin-left: 16px;
    height: 26px;
    border-radius: 13px;
    background-color: ${p => p.countBgColor};
    padding: 0 16px;
    align-items: center;
    display: flex;
    color: white;
    font-size: 12px;
    align-self: center;
`

const Button = styled(Clickable)`
  padding: 0 20px 0 0;
`

const Logo = styled.img`
  height: 20px;
  width: auto;
  padding-top: 5px;
  align-self: center;
`

const Container = styled.div`
  display: flex;
  align-items: center;
`

export const CountTitle = props => {
    const {title, onClickBack, count, displayCount, ...rest} = props
    return (
        <Container>
            {
                onClickBack &&
                <Button onClick={onClickBack}>
                    <Logo src={Images.backArrow}/>
                </Button>
            }
            <Title {...rest}>{title}</Title>
            {displayCount && <Count {...rest}>{count}</Count>}
        </Container>
    )
}

CountTitle.defaultProps = {
    title: 'Any Title',
    count: 100,
    titleColor: '#700cff',//'#450b96',
    countBgColor: '#e045cb',
    displayCount: true,
    titleSize: 35
}

CountTitle.propTypes = {
    title: PropTypes.string.isRequired,
    onClickBack: PropTypes.func,
    count: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    titleColor: PropTypes.string,
    countBgColor: PropTypes.string,
    displayCount: PropTypes.bool,
    titleSize: PropTypes.number
}
