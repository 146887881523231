import { userConstants } from "../constants/userConstants"
import { AuthCache } from "../utils/AuthCache"
import { UPDATE_LOCAL_ACCOUNT_USER } from '../actions'
import { utcTimeStamp } from '../utils'

const defaultState = {
    loggedIn: !!AuthCache.currentToken(),
    user: AuthCache.currentToken()
}

export const authentication = (state = defaultState, action) => {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                loggingIn: true
            }
        case userConstants.LOGIN_SUCCESS:
            let tokenData = action.payload.data
            let expiry = utcTimeStamp() + tokenData.tokenExpiryDuration * 1000
            tokenData['willExpireAt'] = expiry
            AuthCache.saveToken(tokenData)
            return {
                loggedIn: true,
                user: action.payload.data
            }
        case userConstants.LOGOUT:
            AuthCache.clear()
            return {}
        case UPDATE_LOCAL_ACCOUNT_USER: {
            const {fullName, birthDate, hobbies} = action.user
            let updatedAuthData = {...state.user, fullName, birthDate, hobbies}
            AuthCache.saveToken(updatedAuthData)
            return {
                ...state,
                user: updatedAuthData
            }
        }
        default:
            return state
    }
}