import { FeedbackService } from '../services'

export const SAVE_FEEDBACK = 'feedback-service/SAVE_FEEDBACK'

export const saveFeedback = feedback => {
    return {
        type: SAVE_FEEDBACK,
        payload: {
            request: FeedbackService.saveFeedback(feedback)
        }
    }
}