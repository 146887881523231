import styled from 'styled-components'
import { fontButton } from '../../../common'
import { media } from '../../../../utils'

export const LoginButton = styled(fontButton)`
    border-radius: 31.5px;
    border: solid 2px #59dfb8;
    background-color: transparent;
    color: white;
    font-size: 13px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    padding: 10px 40px 10px 40px;
    outline: none;
    transform: scale(1.0);
    transition: all 0.3s;
    :active {
      transform: scale(0.95);
    }
    ${
    media.phone`
        padding: 5px 20px;
        font-size: 11px;
        border: solid 1.5px #59dfb8;
            `
    }
`

export const SignUpButton = styled(LoginButton)`
    border: none;
    background-color: #59dfb8;
    color: black;
    padding: 10px 50px 10px 50px;
    display: unset;
    ${
    media.phone`
        display: none;
        padding: 0;
            `
    }
`