import { bindActionCreators } from "redux"
import * as actions from "../../../actions/authentication"
import { connect } from 'react-redux'
import { LoginPage } from "./LoginPage"
import { WithLoaderContext } from '../../WithLoaderContext'

const mapStateToProps = (state) => {
    return {...state.authentication}
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(actions, dispatch)
}

export const ConnectedLoginPage = connect(mapStateToProps, mapDispatchToProps)(WithLoaderContext(LoginPage))