import React, { Component } from 'react'
import { AbsoluteFullPageContainer, CountTitle, TitleContainer } from '../../common'
import styled from 'styled-components'
import { showError, sortAlphabetically } from '../../../utils'
import { CategoryDropdown } from '../../dashboard/person/helpers/CategoryDropdown'
import { ActionButton, WincPerson } from './common'

const PageContainer = styled(AbsoluteFullPageContainer)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 0 0 0;
  background-color: white;
`

const ContentContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 600px;
`

const DropdownContainer = styled.div`
    display: flex;
    background-color: #f9f7fa;
    border-bottom: ${p => `1px solid ${p.theme.primary}`};
    padding: 5px 10px 5px 10px;
    box-sizing: border-box;
`

const ActionsContainer = styled.div`
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
`

const Action = styled(ActionButton)`
  flex: 1;
  justify-content: ${p => p.transparent ? 'flex-start' : 'center'};
  margin: unset;
`

const PeopleList = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #f0eff1;
  margin: 0 0 40px 0;
`

const TitleDisplay = styled(TitleContainer)`
  flex: unset;
  padding: unset;
  width: 600px;
  height: 100px;
  align-items: flex-end;
  background-color: white;
  margin-bottom: 50px;
`

export class CategorySwapPage extends Component {
    constructor(props) {
        super(props)
        const {state = {}} = props.history.location
        const {people = [], category = {}} = state
        console.log(people)
        this.state = {
            list: [],
            selectedCategory: undefined,
            people,
            exclude: category,
            selectedPeople: []
        }
    }

    componentDidMount() {
        if (this.state.people.length === 0) {
            this.props.history.goBack()
            return
        }
        this._loadCategories()
    }

    _loadCategories = () => {
        this.props.listAllCategories()
            .then(response => {
                let loadedCategories = response.payload.data.slice().filter(c => c.id !== this.state.exclude.id)
                this.setState({...this.state, list: sortAlphabetically(loadedCategories)})
            })
            .catch(showError)
    }

    _onSelectCategory = (selectedCategory) => {
        this.setState({...this.state, selectedCategory})
    }

    _selectOrDeselectPerson = id => {
        let selectedPeople = this.state.selectedPeople.slice()
        if (selectedPeople.indexOf(id) === -1) {
            selectedPeople.push(id)
        } else {
            selectedPeople = selectedPeople.filter(personId => personId !== id)
        }
        this.setState({...this.state, selectedPeople})
    }

    _swapWincsToCategory = () => {
        const {selectedCategory, selectedPeople, exclude} = this.state
        let swapData = {
            sourceCategoryId: exclude.id,
            targetCategoryId: selectedCategory.id,
            userIds: selectedPeople
        }
        this.props.startLoading()
        this.props.transferWincsToCategory(swapData)
            .then(() => {
                this.props.stopLoading()
                this.props.history.goBack()
            })
            .catch(e => {
                this.props.stopLoading()
                showError(e)
            })
    }


    render() {
        const {list, selectedCategory, people, selectedPeople, exclude: {name = 'N/A'}} = this.state
        let disableButton = !(selectedPeople.length !== 0 && selectedCategory)
        if (people.length === 0) {
            return (<div/>)
        }
        return (
            <PageContainer>
                <TitleDisplay>
                    <CountTitle title={name} displayCount={false}/>
                </TitleDisplay>
                <ContentContainer>
                    <PeopleList>
                        {
                            people.map((p, index) => <WincPerson key={`${index}`}
                                                                 person={p}
                                                                 index={index}
                                                                 selected={selectedPeople.indexOf(p.id) !== -1}
                                                                 onClick={this._selectOrDeselectPerson}/>)
                        }

                    </PeopleList>
                    <DropdownContainer>
                        <CategoryDropdown list={list} selected={selectedCategory}
                                          onSelectCategory={this._onSelectCategory}/>
                    </DropdownContainer>
                    <ActionsContainer>
                        <Action transparent
                                onClick={this.props.history.goBack}>{'CANCEL'}</Action>
                        <Action disabled={disableButton}
                                onClick={this._swapWincsToCategory}>{'RE-ASSIGN WinCs'}</Action>
                    </ActionsContainer>
                </ContentContainer>
            </PageContainer>
        )
    }
}