import React, { Component } from 'react'
import styled from 'styled-components'
import { PersonContainer } from "./PersonContainer"
import { fontDiv } from "../../../common"
import { SummaryRawGraph } from "../../graphs/SummaryRawGraph"
import { PersonInitial } from "./PersonInitial"
import PropTypes from 'prop-types'

const Name = styled(fontDiv)`
    padding: 20px 0 0 20px; 
    font-size: 15px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #450b96;   
    margin-bottom: 20px;
`

class Person extends Component {
    render() {
        let {index, onClick, profileInfo} = this.props
        const {name, totalWincs, ...data} = profileInfo
        return (
            <PersonContainer onClick={() => onClick(profileInfo)}>
                <Name>{name}</Name>
                <SummaryRawGraph data={data} enableAnimation={false}/>
                <PersonInitial name={name} index={index} wincCount={totalWincs}/>
            </PersonContainer>
        )
    }
}

Person.propTypes = {
    profileInfo: PropTypes.object.isRequired
}

export { Person }
