import React from "react"
import styled from "styled-components"
import AppBar from "@material-ui/core/AppBar"
import MuiButton from "@material-ui/core/Button"
import { Images } from "../../assets"
import SettingsIcon from "@material-ui/icons/Settings"
import IconButton from '@material-ui/core/IconButton'
import { AlertDialog, fontDiv, fontNavLink } from "../common"
import MenuItem from "@material-ui/core/MenuItem"
import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import MenuList from '@material-ui/core/MenuList'
import Divider from '@material-ui/core/Divider'

const TopBar = styled(AppBar).attrs({
    elevation: 0
})`
  display: flex;
  height: 50px;
  padding-left: 40px;
  align-items: center;
`
const Logo = styled.img`
  width: 90px;
  height: auto;
`

const VerticalLine = styled.div`
  background-color: #8835ff;
  width: 1px;
  align-self: stretch;
  margin: 12px 20px 12px 20px;
`

const Space = styled.div`
  height: 0;
  align-self: stretch;
  flex: 1;
`
const StyledNavLink = styled(fontNavLink)`
    border-bottom: none;
    color: #d3b5fe;
    text-decoration: none;
    font-weight: 500;
    font-size: 15px;
    &.active {
      color: white;
    }
`

const ItemLogo = styled.img`
  width: ${p => p.size ? p.size : 20}px;
  height: auto;
`
const MenuItemText = styled(fontDiv)`
    margin-left: 35px;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #5f5662;
    padding-right: 85px;
`

const CustomMenuItem = ({src, title, onClick, size}) => {
    return (
        <MenuItem onClick={onClick}>
            <ItemLogo src={src} size={size}/>
            <MenuItemText>{title}</MenuItemText>
        </MenuItem>
    )
}


export class NavigationBar extends React.Component {
    constructor() {
        super()
        this.state = {
            anchorEl: null,
            open: false
        }
    }

    _showMainDashboard = () => {
        this.props.history.push('/dashboard')
    }

    _openMenu = event => {
        this.setState({anchorEl: event.currentTarget, open: true})
    }

    _closeMenu = () => {
        this.setState({...this.state, open: false})
    }

    _goToAccountSettings = () => {
        this._closeMenu()
        this.props.history.push('/settings/account-settings')
    }

    _logOut = () => {
        this._closeMenu()
        this.props.logout()
        this.props.history.push('/login')
    }

    _alertActions = [
        {
            title: 'Cancel'
        },
        {
            title: 'Log Out',
            positive: false,
            onPress: this._logOut
        }
    ]

    _goToSettings = () => {
        this._closeMenu()
        // this.props.history.push('/settings/category-settings')
        this.props.history.push('/settings')
    }

    render() {
        const {anchorEl, open} = this.state
        return (
            <TopBar position="fixed"
                    style={{flexDirection: 'row'}}>
                <MuiButton style={{marginLeft: -50}}
                           onClick={this._showMainDashboard}>
                    <Logo src={Images.wincLogo}/>
                </MuiButton>
                <Space/>
                <StyledNavLink exact to='/people'>PEOPLE</StyledNavLink>
                <VerticalLine/>
                <StyledNavLink exact to='/categories'>CATEGORIES</StyledNavLink>
                <VerticalLine/>
                <IconButton style={{marginLeft: -20}}
                            onClick={this._openMenu}>
                    <SettingsIcon style={{color: 'white'}}/>
                </IconButton>
                <Popper open={open} anchorEl={anchorEl} transition disablePortal>
                    {({TransitionProps, placement}) => (
                        <Grow
                            {...TransitionProps}
                            id="menu-list-grow"
                        >
                            <Paper style={{marginRight: 15}}>
                                <ClickAwayListener onClickAway={this._closeMenu}>
                                    <MenuList>
                                        <CustomMenuItem src={Images.settingsMenu} size={17}
                                                        title={'Settings'}
                                                        onClick={this._goToSettings}
                                        />
                                        <Divider style={{backgroundColor: '#f8f8f8'}}/>
                                        <CustomMenuItem src={Images.logout} size={21}
                                                        title={'Logout'}
                                                        onClick={() => this.alert.show()}
                                        />
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                <AlertDialog ref={c => this.alert = c} title={'Log Out'}
                             actions={this._alertActions}
                             message={'Are you sure you want to log out?'}/>
            </TopBar>
        )
    }

}
