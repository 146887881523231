import { accountId, teamId } from './utils'

export class TeamService {
    static getTotalsOfPeopleInTheTeam() {
        let url = `/teams/wincs/total/${accountId()}/${teamId()}`
        return {
            url, method: 'GET'
        }
    }

    static getTeamTotals() {
        let url = `/teams/wincs/${accountId()}/${teamId()}`
        return {
            url, method: 'GET'
        }
    }

    static getTeamTotalWincCount() {
        let url = `/teams/wincs/count/${accountId()}/${teamId()}`
        return {
            url, method: 'GET'
        }
    }

    static getTotalWincAndRatioByPerson() {
        let url = `/teams/wincs/ratio/overtime/${accountId()}/${teamId()}`
        return {
            url, method: 'GET'
        }
    }

    static getTeamWincsOverTime(fromDate, toDate) {
        let url = `/teams/wincs/overtime/${accountId()}/${teamId()}?start=${fromDate}&end=${toDate}`
        return {
            url, method: 'GET'
        }
    }

    static getCumulativeTeamWincsOverTime(fromDate, toDate) {
        ///teams/wincs/overtime/{accountId}/{teamId}
        let url = `/teams/wincs/overtime/cummulative/${accountId()}/${teamId()}?start=${fromDate}&end=${toDate}`
        return {
            url, method: 'GET'
        }
    }
}