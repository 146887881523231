import { AuthCache } from './AuthCache'
import { history, store } from '../store'


export const showError = (e) => {
    if (e && e.error && e.error.message) {
        alert(e.error.message)
        if (store.getState().authentication.loggedIn && !AuthCache.currentToken()) {
            history.go()
        }
        return
    }
    if (e) {
        alert(e.toString())
        return
    }
    alert('Unknown Error')
}