import React, { Component } from 'react'
import styled from 'styled-components'
import { Button, fontDiv, FormContainer, OnBoardContainer } from "../../common/index"
import { media } from '../../../utils'

const Form = styled(FormContainer)`
  min-height: 300px;
  padding: 50px;
  ${
    media.phone`
        padding: 50px;
        width: 100%;
        min-height: unset;
        display: flex;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        `
    }
`

const Title = styled(fontDiv)`
  font-size: 35px;
  text-align: center;
  padding: 2px;
  color: #700cff;
`
const SubTitle = styled(Title)`
  font-size: 18px;
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
  color: black;
`

export class VerifyEmailPage extends Component {

    _login = (e) => {
        e.preventDefault()
        this.props.history.push('/login')
    }

    render() {
        return (
            <OnBoardContainer>
                <Form onSubmit={this._login}>
                    <Title>{'Account Verified!'}</Title>
                    <SubTitle>{'Your email has been verified successfully'}</SubTitle>
                    <Button style={{
                        alignSelf: 'stretch',
                        marginTop: 20,
                        marginLeft: 40,
                        marginRight: 40
                    }}
                            type={'submit'}> {'CONTINUE TO LOGIN'}</Button>
                </Form>
            </OnBoardContainer>
        )
    }
}