import React, { Component } from 'react'
import styled from 'styled-components'
import { AbsoluteFullPageContainer, NoData } from './common'

const PageContainer = styled(AbsoluteFullPageContainer)`
    display: flex;
    padding: 0;
`

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background-color: crimson;
`


export class InvalidRoutePage extends Component {

    render() {
        return (
            <PageContainer>
                <ContentContainer>
                    <NoData/>
                </ContentContainer>
            </PageContainer>
        )
    }
}