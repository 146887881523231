import React, { Component } from 'react'
import {
    ConnectedForgotPasswordPage,
    ConnectedLoginPage,
    ConnectedResetPasswordPage,
    ConnectedSignUpPage,
    ConnectedWelcomePage,
    HomePage,
    VerifyEmailPage
} from "./components"
import { HashRouter as Router, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { AlertDialog, Clickable, FeedbackDialog, Loader } from './components/common'
import { AppContext } from './context'
import { saveFeedback } from './actions'
import { showError, media } from './utils'

const FeedbackButton = styled(Clickable)`
  align-self: center;
  transform: rotateZ(-90deg);
  border-radius: 4px;
  box-shadow: 0 3px 4px 0 rgba(154, 100, 230, 0.32);
  background-color: #e045cb;
  padding: 15px 30px;
  position: fixed;
  color: white;
  top: 20vh;
  left: -50px;
  font-size: 15px;
  z-index: 10;
  transform-origin: 50% 50%;
  ${
    media.phone`
        background-color: #5a0acc;
        font-size: 12px;
        left: -29px;
        padding: 10px 15px;
        top: 12vh;
        display: none;
    `
}
  ${
    media.phoneL`
        display: none;
    `
}
  :active {
     transform: rotateZ(-90deg) scale(0.9);
  }
`

const Container = styled.div`
  //overflow: hidden
`

const Overlay = styled.div`
  background-color: rgba(0,0,0,0.2);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0; 
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 100;
`

class App extends Component {
    constructor() {
        super()
        this.state = {
            loading: false,
            showFeedback: true,
            showOverlay: false,
            contextValue: {
                stopLoading: this._stopLoading,
                startLoading: this._startLoading,
                showFeedback: this._showFeedback,
                hideFeedback: this._hideFeedback,
                showOverlay: this._showOverlay,
                hideOverlay: this._hideOverlay
            }
        }
    }

    componentDidMount() {
        if ('onorientationchange' in window) {
            window.addEventListener("orientationchange",
                () => {
                    console.log('Reloading the page....')
                    window.location.reload()
                }
                , false)
        }
    }

    _startLoading = () => {
        this.setState({loading: true})
    }

    _stopLoading = () => {
        this.setState({loading: false})
    }

    _hideFeedback = () => {
        this.setState(state => ({...state, showFeedback: false}))
    }

    _showFeedback = () => {
        this.setState(state => ({...state, showFeedback: true}))
    }

    _dashboardRoute = () => {
        return <Route render={(props) => {
            return (<div>
                <HomePage {...props}/>
            </div>)
        }}/>
    }

    _showDialog = () => {
        this.feedback.showFeedbackDialog(this._saveFeedback)
    }

    _saveFeedback = (feedbackInfo) => {
        const {saveFeedback, userName} = this.props
        let feedback = {...feedbackInfo, userName}
        this._startLoading()
        saveFeedback(feedback)
            .then(() => {
                this._stopLoading()
                this.alert.show()
            })
            .catch(e => {
                this._stopLoading()
                showError(e)
            })
    }

    _showOverlay = () => {
        this.setState({showOverlay: true})
    }

    _hideOverlay = () => {
        this.setState({showOverlay: false})
    }

    render() {
        const {showFeedback, showOverlay} = this.state
        return (
            <AppContext.Provider value={this.state.contextValue}>
                <>
                    <Container>
                        <Router>
                            <div>
                                <Route exact path="/" component={ConnectedWelcomePage}/>
                                <Route exact path="/login" component={ConnectedLoginPage}/>
                                <Route exact path="/signup" component={ConnectedSignUpPage}/>
                                <Route exact path="/forgot-password"
                                       component={ConnectedForgotPasswordPage}/>
                                <Route exact path="/reset-password"
                                       component={ConnectedResetPasswordPage}/>
                                <Route exact path="/verify-email" component={VerifyEmailPage}/>
                                {/*<Route component={InvalidRoutePage}/>*/}
                                {/*<PrivateRoute exact path="/dashboard" component={ConnectedDashboardPage}/>*/}
                                {this._dashboardRoute()}
                            </div>
                        </Router>
                        {this.props.loggedIn && showFeedback &&
                        <FeedbackButton onClick={this._showDialog}>FEEDBACK</FeedbackButton>}
                        {
                            this.state.loading &&
                            <Loader/>
                        }
                        <FeedbackDialog ref={c => this.feedback = c}/>
                        <AlertDialog ref={c => this.alert = c} title={'Feedback'}
                                     message={'Your feedback has been saved successfully'}/>
                    </Container>
                    {showOverlay && <Overlay/>}
                </>
            </AppContext.Provider>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.authentication.loggedIn,
        userName: state.authentication.user ? state.authentication.user.userName : ""
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveFeedback: feedback => dispatch(saveFeedback(feedback))
    }
}

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App)

export { ConnectedApp }
