import React from 'react'
import styled from 'styled-components'
import { media } from '../../../../utils'
import { SignUpButton } from '../common/buttons'
import { Title } from '../common/Title'


const SubTitle = styled(Title)`
  font-size: 25px;
  ${
    media.phone`
       font-size: 20px
    `
}
`

const VSpace = styled.div`
  height: ${p => p.space ? p.space : 40}px;
`

const GetStartedButton = styled(SignUpButton)`
    padding: 24px 72px;
    border-radius: 31.5px;
    font-size: 15px;
    max-width: 320px;
    ${
    media.phone`
       font-size: 13px;
       padding: 12px 40px;
       display: unset;
    `
}
`

export const Home = (props) => (
    <>
        <Title>{'More WinCs, More Smiles in Your Team'}</Title>
        <VSpace/>
        <SubTitle>{'The power of recognition to your team and your success.'}</SubTitle>
        <VSpace space={60}/>
        <GetStartedButton
            onClick={props.onGetStarted}>{'GET STARTED FOR FREE'}</GetStartedButton>
    </>
)
