import React, { Component } from 'react'
import styled from "styled-components"
import { DateButton } from "./DateButton"
import PropType from 'prop-types'
import { dateFromToday, toUTCTimestamp, utcTimeStamp } from '../../../../utils'
import { CustomDatePicker } from './CustomDatePicker'
import { withAppContext } from '../../../../context'
import moment from 'moment'

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const VContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const DateRangeConstants = {
    day: 'day',
    month: 'month',
    week: 'week',
    custom: 'custom'
}

const getDateRange = (state) => {
    const {customRange, selectedRangeType} = state
    let from = '', to = ''
    const {month, week} = DateRangeConstants
    switch (selectedRangeType) {
        case week:
            to = utcTimeStamp()
            from = dateFromToday(7)
            break
        case month:
            to = utcTimeStamp()
            from = dateFromToday(30)
            break
        default:
            const {fromDate, toDate} = customRange
            console.log(fromDate, toDate)
            to = toUTCTimestamp(moment(toDate), true)
            from = toUTCTimestamp(moment(fromDate))
            break
    }
    console.log('Range Selected: ', from, to)
    return {
        from,
        to
    }
}

export const getDefaultSelectedRange = () => {
    return getDateRange({selectedRangeType: DateRangeConstants.month})
}

class DateRangeHelperComponent extends Component {
    static propTypes = {
        selectedRangeType: PropType.oneOf([
            DateRangeConstants.week,
            DateRangeConstants.month,
            DateRangeConstants.custom]).isRequired,
        onSelectRange: PropType.func.isRequired
    }

    static defaultProps = {
        selectedRangeType: DateRangeConstants.month,
    }

    constructor(props) {
        super(props)
        this.defaultState = {
            selectedRangeType: props.selectedRangeType,
            customRange: {
                fromDate: null,
                toDate: null,
                isOpen: false
            }
        }
        this.state = {
            ...this.defaultState
        }
    }

    componentDidUpdate() {
        const {customRange: {isOpen}} = this.state
        setTimeout(() => {
            if (isOpen) {
                window.addEventListener('click', this._close)
            } else {
                window.removeEventListener('click', this._close)
            }
        }, 0)
    }

    _close = () => {
        this.props.hideOverlay()
        this.setState({...this.defaultState}, () => this._onClick(DateRangeConstants.month))
    }

    _onClick = type => {
        const {custom} = DateRangeConstants
        if (type === custom) {
            this.props.showOverlay()
            this.setState({
                selectedRangeType: type,
                customRange: {
                    ...this.state.customRange,
                    isOpen: true
                }
            })
            return
        }
        this.setState({...this.defaultState, selectedRangeType: type,}, this._onSelectRange)
    }

    _setCustomDateRange = (fromDate, toDate) => {
        this.props.hideOverlay()
        this.setState({
            customRange: {
                fromDate,
                toDate,
                isOpen: false
            }
        }, this._onSelectRange)
    }

    onDateChange = (update) => {
        if (update.fromDate || update.toDate) {
            let _closeMenu = update.toDate !== undefined
            this.setState({
                ...this.state, customRange: {
                    ...this.state.customRange,
                    ...update,
                }
            }, () => {
                const {customRange: {fromDate, toDate}} = this.state
                if (fromDate !== null && toDate !== null) {
                    this._onSelectRange()
                }
                if (_closeMenu) {
                    setTimeout(() => {
                        this.setState({
                            ...this.state,
                            customRange: {...this.state.customRange, isOpen: false}
                        })
                    }, 1000)
                }
            })
        }
    }

    _onSelectRange = () => {
        // const {selectedRangeType} = this.state
        let {from, to} = getDateRange(this.state)
        this.props.onSelectRange(from, to)
        // console.log(`___DATE_RANGE_SELECTED___type: ${selectedRangeType}, from: ${from}, to: ${to}`)
    }

    _clearDateRange = () => {
        this.setState({...this.state, customRange: {fromDate: null, toDate: null}})
    }

    _cancelCustomDateRange = () => {
        this.props.hideOverlay()
        this.setState(s => ({
            selectedRangeType: DateRangeConstants.month,
            customRange: {
                ...s.customRange,
                isOpen: false
            }
        }), this._onSelectRange)
    }

    render() {
        const {selectedRangeType, customRange: {fromDate, toDate, isOpen}} = this.state
        const {month, week, custom} = DateRangeConstants
        return (
            <VContainer>
                <ButtonsContainer>
                    {/*<DateButton active={selectedRangeType === day}*/}
                    {/*onClick={() => this._onClick(day)}>{'DAY'}</DateButton>*/}
                    <DateButton active={selectedRangeType === week}
                                onClick={() => this._onClick(week)}>{'WEEK'}</DateButton>
                    <DateButton active={selectedRangeType === month}
                                onClick={() => this._onClick(month)}>{'MONTH'}</DateButton>
                    <DateButton active={selectedRangeType === custom}
                                onClick={() => this._onClick(custom)}>{'CUSTOM'}</DateButton>
                </ButtonsContainer>
                <CustomDatePicker {...{
                    isOpen,
                    fromDate,
                    toDate,
                    onSave: this._setCustomDateRange,
                    onCancel: this._cancelCustomDateRange,
                }}/>
                {/*<DateRangePicker*/}
                {/*    fromDate={fromDate}  //from date*/}
                {/*    toDate={toDate}  //to Date*/}
                {/*    onChange={this.onDateChange}*/}
                {/*    clearDateRange={this._clearDateRange}*/}
                {/*    isOpen={isOpen}*/}
                {/*    closeDialogOnSelection    //close date dialog after selecting both from and to date*/}
                {/*/>*/}
            </VContainer>
        )
    }
}

export const DateRangeHelper = withAppContext(DateRangeHelperComponent)
