import styled from "styled-components"
import { media } from '../../utils'

export const TitleContainer = styled.div`
  float: none;
  display: flex;
  margin: auto;
  flex: 1;
  max-width: 1050px;
  padding: 30px 0 30px 5px;
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  background-color: #fbf8ff;
  z-index: 2;
  box-sizing: border-box;
  ${
    media.phone`
        justify-content: center;
        padding: 15px;
        width: 100%;
        position: unset;
    `
}
  ${
    media.phoneL`
    `
}
`
//        margin-top: 50px;

//      position: unset;
