import { bindActionCreators } from "redux"
import * as actions from "../../../actions/index"
import { connect } from 'react-redux'
import { SignupPage } from "./SignupPage"
import { WithLoaderContext } from '../../WithLoaderContext'

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(actions, dispatch)
}

export const ConnectedSignUpPage = connect(mapStateToProps, mapDispatchToProps)(WithLoaderContext(SignupPage))