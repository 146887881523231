import styled from 'styled-components'
import { fontDiv } from "../../../common"
import React from 'react'


const Background = styled.div`
  background-color: #514366;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border-radius: 3px;
`

const Name = styled(fontDiv)`
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
`

const Count = styled(Name)`
  font-size: 11px;
  font-weight: normal;
`

export const MemberToolTip = (props) => {
    let payload = {}
    if (props.payload && props.payload.length > 0) {
        payload = props.payload[0].payload
    }
    let {name = 'N/A', value = 0} = payload
    return (
        props.active ?
            <Background point={props.coordinate}>
                <Name>{name}</Name>
                <Count>{value + ` ${props.tooltip}`}</Count>
            </Background> : null
    )
}