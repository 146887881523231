export const Images = {
    // wincLogo: require('./images/logo@3x.png'),
    wincLogo: require('./images/winc-logo.png'),
    settings: require('./images/settings.png'),
    lockIcon: require('./images/group-4@3x.png'),
    bgImage: require('./images/background-image.png'),
    plusIcon: require('./images/plus.png'),
    expandIcon: require('./images/expand-icon@3x.png'),
    backArrow: require('./images/back-arrow@3x.png'),
    sort: require('./images/sort@3x.png'),
    checkmark: require('./images/checkmark.png'),
    account: require('./images/account-con@3x.png'),
    settingsMenu: require('./images/settings-icon@3x.png'),
    logout: require('./images/logout-icon@3x.png'),
    editWinc: require('./images/edit-winc-icon.png'),
    deleteWinc: require('./images/trash-icon@3x.png'),
    trash: require('./images/trash-winc-icon.png'),
    noteIcon: require('./images/note-icon.png'),
    warningIcon: require('./images/warning-icon@3x.png'),
    searchIcon: require('./images/search-icon.png'),
    rightArrow: require('./images/right_arrow.png'),
    signup: require('./images/signup@3x.png'),
    upArrow: require('./images/up_arrow.png'),
    downArrow: require('./images/down_arrow.png'),
    whatIsWinc: require('./images/Picture1.png'),
    how: require('./images/Picture2.png'),
    how2: require('./images/Picture3.png'),
    why: require('./images/Picture4.png'),
    developmentPolicy: require('./images/Picture5.png'),
    contact: require('./images/Picture6.png'),
}
