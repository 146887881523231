import moment from "moment"

const applicationDateFormat = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'

export const currentDate = () => {
    return moment.utc().format(applicationDateFormat)
}

export const utcTimeStamp = () => {
    return Date.now()
}

export const toUTC = (dateTime, format = applicationDateFormat) => {
    return moment(dateTime).utc().format(format)
}

export const toUTCDate = (dateTime, format = applicationDateFormat) => {
    return moment(dateTime, 'MM/DD/YYYY').utc().format(format)
}

export const toUTCTimestamp = (dateTime, endOfDay = false) => {
    let date = endOfDay ? getDateFromString(dateTime, 'YYYY-MM-DD[T]23:59:59.000') : dateTime
    return moment(date).utc().format('x')
}

export const dateFromToday = (days, timestamp = true) => {
    //Local date
    let date = moment().subtract(days, 'd').format('YYYY-MM-DD')
    if (timestamp) {
        return toUTCTimestamp(date)
    }
    return toUTC(date)
}

export const toDate = (timestamp) => {
    return moment(timestamp).format()
}

export const getDateFromString = (dateString, toFormat = 'MM-DD-YYYY') => {
    let localTime = moment.utc(dateString, applicationDateFormat).toDate()
    return moment(localTime).format(toFormat)
}
