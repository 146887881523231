import { UserService } from "../services/UserService"

export const SELECT_USER = 'user-service/SELECT_USER'
export const UPDATE_SELECTED_USER = 'user-service/UPDATE_SELECTED_USER'
export const UPDATE_WINC_COUNT_FOR_SELECTED_USER = 'user-service/UPDATE_WINC_COUNT_FOR_SELECTED_USER'
export const UPDATE_WINC_COUNT_BY_FOR_SELECTED_USER = 'user-service/UPDATE_WINC_COUNT_BY_FOR_SELECTED_USER'
export const RESET_SELECTED_USER = 'user-service/RESET_SELECTED_USER'

export const UPDATE_WINC_SUMMARY_FOR_SELECTED_USER = 'user-service/UPDATE_WINC_SUMMARY_FOR_SELECTED_USER'

export const SELECT_CATEGORY = 'user-service/SELECT_CATEGORY'
export const RESET_SELECTED_CATEGORY = 'user-service/RESET_SELECTED_CATEGORY'

export const SELECT_WINC = 'user-service/SELECT_WINC'
export const RESET_SELECTED_WINC = 'user-service/RESET_SELECTED_WINC'
export const UPDATE_LOCAL_ACCOUNT_USER = 'user-service/UPDATE_LOCAL_ACCOUNT_USER'

export const updateLocalAccountUser = user => {
    return {
        type: UPDATE_LOCAL_ACCOUNT_USER,
        user
    }
}
export const updateAccountUser = user => {
    return {
        type: 'user-service/UPDATE_ACCOUNT_USER',
        payload: {
            request: UserService.updateAccountUser(user)
        }
    }
}

export const createUser = user => {
    return {
        type: 'user-service/CREATE_USER',
        payload: {
            request: UserService.createUser(user)
        }
    }
}

export const updateUser = user => {
    return {
        type: 'user-service/UPDATE_USER',
        payload: {
            request: UserService.updateUser(user)
        }
    }
}

export const deleteUser = user => {
    return {
        type: 'user-service/DELETE_USER',
        payload: {
            request: UserService.deleteUser(user)
        }
    }
}


export const getTeamWincsSummary = () => {
    return {
        type: 'user-service/GET_TEAM_WINCS_SUMMARY',
        payload: {
            request: UserService.getTeamWincsSummary()
        }
    }
}

export const getIndividualWincSummary = (userId) => {
    return {
        type: 'user-service/GET_INDIVIDUAL_WINCS_SUMMARY',
        payload: {
            request: UserService.getIndividualWincSummary(userId)
        }
    }
}

export const getIndividualWincsInfo = (userId) => {
    return {
        type: 'user-service/GET_INDIVIDUAL_WINCS_INFO',
        payload: {
            request: UserService.getIndividualWincDetails(userId)
        }
    }
}

// Categories per user.
export const getIndividualCategoriesInfo = (userId) => {
    return {
        type: 'user-service/GET_INDIVIDUAL_CATEGORIES_INFO',
        payload: {
            request: UserService.getIndividualCategoriesInfo(userId)
        }
    }
}

// User wincs per category
export const getIndividualWincsForCategory = (userId, categoryid) => {
    return {
        type: 'user-service/GET_INDIVIDUAL_WINCS_PER_CATEGORY_INFO',
        payload: {
            request: UserService.getIndividualWincsForCategory(userId, categoryid)
        }
    }
}

export const getIndividualWincsOvertime = (userId, from, to) => {
    return {
        type: 'user-service/GET_INDIVIDUAL_WINCS_OVERTIME',
        payload: {
            request: UserService.getIndividualWincsOvertime(userId, from, to)
        }
    }
}


export const selectUser = (userInfo) => {
    return dispatch => dispatch({
        type: SELECT_USER,
        userInfo
    })
}

export const updateSelectedUser = (userInfo) => {
    return dispatch => dispatch({
        type: UPDATE_SELECTED_USER,
        userInfo
    })
}

export const resetSelectedUser = () => {
    return dispatch => dispatch({
        type: RESET_SELECTED_USER,
    })
}


export const selectCategory = (selectedCategory) => {
    return dispatch => dispatch({
        type: SELECT_CATEGORY,
        selectedCategory
    })
}

export const resetSelectedCategory = () => {
    return dispatch => dispatch({
        type: RESET_SELECTED_CATEGORY,
    })
}


export const selectWincToEdit = (selectedWinc) => {
    return dispatch => dispatch({
        type: SELECT_WINC,
        selectedWinc
    })
}

export const resetSelectedWinc = () => {
    return dispatch => dispatch({
        type: RESET_SELECTED_WINC,
    })
}