import React from 'react'
import styled from "styled-components"
import { fontDiv } from "../../common"
import { media } from '../../../utils'

export const Header = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  ${
    media.phone`
        display: none
    `
}
`

export const DisplayText = styled(fontDiv)`
  font-size: 40px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5b12c2;
  padding-left: 15px;
`

export const Count = styled(DisplayText)`
  font-weight: normal;
  color: #e045cb;
  padding-left: 0;
`


const DashboardTitle = props => {
    const {count = 0} = props
    return (
        <Header>
            <Count>{count}</Count>
            <DisplayText>{'TOTAL WinCs'}</DisplayText>
        </Header>
    )
}

export { DashboardTitle }
