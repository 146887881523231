import styled from "styled-components"
import { media } from "../../utils/Media"

export const FormContainer = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 400px;
  min-height: 531px;
  padding: 30px;
  background-color: white;
  margin-top: 100px;
  box-shadow: 0 2px 6px 0 rgba(159, 145, 164, 0.26);
  transition: all 0.5s ease-in;
  box-sizing: border-box;
  ${
    media.phone`
      width: 320px;
      margin-top: 60px;
      padding: 20px;
      `
    }
`