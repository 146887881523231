import React, { Component } from 'react'
import { WincCategory } from "./common/WincCategory"
import { CountTitle, FullPageContainer, TitleContainer } from "../../common"
import { Grid, GridContainer } from "./common"
import { showError } from '../../../utils'

export class TeamCategoryListPage extends Component {
    constructor() {
        super()
        this.state = {categories: [], dataLoaded: false}
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.props.resetSelectedTeamCategory()
        this._getWincInfoForAllCategories()
    }

    _getWincInfoForAllCategories = () => {
        this.props.startLoading()
        this.props.getWincInfoForAllCategories()
            .then(response => {
                this.props.stopLoading()
                this.setState({categories: response.payload.data, dataLoaded: true})
            })
            .catch(e => {
                this.props.stopLoading()
                showError(e)
            })
    }

    _categoriesUI = () => {
        return this.state.categories.map((category, index) =>
            <WincCategory
                key={`${index}`}
                info={category}
                onClick={this._onClickCategory}
                onClickPerson={this._onClickPerson}
            />)
    }

    _onClickCategory = (category) => {
        this.props.selectTeamCategory(category)
        this.props.history.push(this.props.location.pathname + `/${category.categoryId}`)
    }

    _onClickPerson = (id) => {
        this.props.history.push(`/people/${id}`)
    }

    render() {
        const {categories, dataLoaded} = this.state
        if (!dataLoaded) {
            return <div/>
        }
        return (
            <FullPageContainer>
                <TitleContainer>
                    <CountTitle title={'Categories'} count={categories.length}/>
                </TitleContainer>
                <GridContainer>
                    <Grid>
                        {
                            this._categoriesUI()
                        }
                    </Grid>
                </GridContainer>
            </FullPageContainer>
        )
    }
}

