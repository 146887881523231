process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0"

export class AuthenticationService {
    static login(userName, password) {
        let url = '/public/login'
        return {
            url,
            data: {
                userName,
                password
            },
            method: 'post'
        }
    }

    static resetPassword(userName) {
        let url = '/public/resetpassword'
        return {
            url,
            data: {
                userName
            },
            method: 'patch'
        }
    }

    static register(user) {
        const {firstName, lastName, email, password} = user
        console.log(user)
        let url = '/public/registration'
        return {
            url,
            data: {
                firstName,
                lastName,
                password,
                userName: email
            },
            method: 'post'
        }
    }

    static forceChangePassword(user) {
        const {userName, oldPassword, newPassword} = user
        let url = '/public/forcechangepassword'
        return {
            url,
            data: {
                userName,
                oldPassword,
                newPassword
            },
            method: 'patch'
        }
    }

    static logout() {
        localStorage.removeItem('token')
    }
}