import styled from 'styled-components'
import { fontLi } from '../../../common'
import { media } from '../../../../utils'
import React from 'react'
import { Title } from '../common/Title'


const ContentText = styled(Title)`
  font-size: 22px;
  font-weight: ${({bold = false}) => bold ? `400` : `300`};
  padding: 5px 2px;
  align-self: flex-start;
  text-decoration: ${({underline = false}) => underline ? `underline` : `unset`};
  text-align: start;
  min-width: 75px;
  overflow-wrap: break-word;
  ${
    media.phone`
       font-size: 13px;
       min-width: 45px;
    `
}
`

const ListItem = styled(fontLi)`
  list-style-type: circle;
  list-style-position: inside;
  text-align: left;
  align-self: stretch;
  font-size: 21px;
  color: white;
  line-height: 1.5em;
  font-weight: 300;
  ${
    media.phone`
       font-size: 15px
    `
}
`

const VSpace = styled.div`
  height: ${({height = 50}) => height}px;
  width: 0;
  ${
    media.phone`
       height: unset;
       flex: 1;
       display: flex;
       align-self: stretch;
    `
}
`

const HBox = styled.div`
  display: flex;
  align-self: flex-start;
  padding-left: 35px;
  ${
    media.phone`
       padding-left: 10px;
    `
}
`

const VBox = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`

export const HowItWorks = () => {
    const content = [
        'Identify and Recognize what your team members achieved.',
        'Open your WinC.',
        'Register what you recognized.',
        'Save and Repeat it.',
        'Check and Analyze the results.',
        'Communicate them to your members.'
    ]
    return (
        <div>
            <Title>{'How WinC Works?'}</Title>
            {
                content.map((c, i) => <ListItem key={i}>{c}</ListItem>)
            }
            <VSpace height={20}/>
            <ContentText bold>{'Evaluation KPIs: Grade, Impact and Score'}</ContentText>
            <HBox>
                <ContentText bold underline>{'Grade: '}</ContentText>
                <VBox>
                    <ContentText>{'5 Grades evaluation.'}</ContentText>
                    <ContentText>{'* 1 Lowest, 5 Highest'}</ContentText>
                </VBox>
            </HBox>
            <HBox>
                <ContentText bold underline>{'Impact: '}</ContentText>
                <VBox>
                    <ContentText>{'3 Levels of contributions to your organization.'}</ContentText>
                    <ContentText>{'* High, Mid and Low'}</ContentText>
                </VBox>
            </HBox>
            <HBox>
                <ContentText bold underline>{'Score: '}</ContentText>
                <VBox>
                    <ContentText>Compounded evaluation results showing the<br/>true value of the
                        what you recognize</ContentText>
                    <ContentText>{'* Score = Grade x Impact'}</ContentText>
                </VBox>
            </HBox>
            <VSpace/>
        </div>
    )
}
