import { alertConstants } from "../constants/alertConstants"

export const alert = (state = {}, action) => {
    switch (action.type) {
        case alertConstants.SUCCESS:
            return {
                type: 'success',
                message: action.message
            }
        case alertConstants.ERROR:
            return {
                type: 'failure',
                message: action.message
            }
        case alertConstants.CLEAR:
            return {}
        default:
            return state
    }
}