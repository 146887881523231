import axios from 'axios'
import { config } from "../../config"
import { transformError } from "./NetworkHelpers"
import { getActionTypes } from 'redux-axios-middleware'

const {protocol, domain, apiBasePath} = config.api
const client = axios.create({
    baseURL: `${protocol}://${domain}/${apiBasePath}`, responseType: 'json',
})

const options = {
    returnRejectedPromiseOnError: true, interceptors: {
        request: [{
            success: ({getState, dispatch}, req) => {
                const {user} = getState().authentication
                // req.headers['Authorization'] = 'A41FF2AA18B6150E718FE3F97A95F668'
                if (user && user.token) {
                    req.headers['Authorization'] = user.token
                }
                return req
            }
        }]
    }, onError: function onError(_ref2, options) {
        let {action, next, error} = _ref2
        let nextAction = {
            type: getActionTypes(action, options)[2], error: transformError(error), meta: {
                previousAction: action
            }
        }
        next(nextAction)
        return nextAction
    }
}

const cloudBackend = {
    client: client, options: options
}

export default cloudBackend
