import React, { Component } from 'react'
import styled from 'styled-components'
import { FullPageContainer, NoData, TitleContainer } from "../../common"
import { DashboardTitle } from "./DashboardTitle"
import { media, showError } from '../../../utils'
import {
    ScatterGraph,
    RatioGraph,
    MemberGraph,
    ImpactScoreGraph,
    WincTimeGraph
} from '../graphs'
import { ScoreGraph } from '../graphs/ScoreGraph'
import { MobileTitleDisplay } from './mobile-title/MobileTitleDisplay'

const Wrapper = styled(FullPageContainer)`
  ${
    media.phone`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 0;
        width: 100%;
        box-sizing: border-box;
    `
}
`

const GridContainer = styled.div`
  float: none;
  margin: auto;
  display: flex;
  max-width: 1040px;
  padding-bottom: 30px;
  box-sizing: border-box;
  ${
    media.phone`
        width: 100%;
        justify-content: center;
        padding: 10px;
    `
}
  
`

const Grid = styled.div`
  float: none;
  margin: auto;
  display: grid;
  flex: 1;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 300px repeat(3, 262px) repeat(2, 302px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: transparent;
  justify-content: center;
  ${
    media.phone`
        grid-template-columns: 1fr;
        grid-template-rows: 300px repeat(6, 262px) repeat(2, 302px);
        grid-column-gap: unset;
    `
}
`

const Header = styled(TitleContainer)`
  ${
    media.phone`
        margin-top: 50px;
    `
}
`

const FullView = styled.div`
  display: flex;
  grid-column: 1 / -1;
`

const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh
`

export class DashboardPage extends Component {
    constructor() {
        super()
        this.state = {
            wincTotals: undefined,
            wincTotalsByPerson: undefined,
            wincCount: undefined,
            wincRatioTotalsByPerson: undefined,
            wincCountOverTime: undefined,
            cummulativeWincCountOverTime: undefined,
        }
    }

    componentDidMount() {
        this._loadAllData()
    }

    _onClickPerson = (id) => {
        this.props.history.push(`/people/${id}`)
    }

    _loadAllData = () => {
        this.props.startLoading()
        let teamWincsTotalPromise = this.props.getTeamTotals()
        let teamWincsTotalByPersonPromise = this.props.getTeamTotalsByPerson()
        let wincCountPromise = this.props.getTeamTotalWincCount()
        let wincRatioTotalsByPersonPromise = this.props.getTotalWincAndRatioByPerson()
        let {from, to} = WincTimeGraph.getDefaultRange()
        let wincsOvertimePromise = this.props.getTeamWincsOverTime(from, to)
        let cumulativeWincsOvertimePromise = this.props.getCumulativeTeamWincsOverTime(from, to)

        Promise.all([
            teamWincsTotalPromise,
            teamWincsTotalByPersonPromise,
            wincCountPromise,
            wincRatioTotalsByPersonPromise,
            wincsOvertimePromise,
            cumulativeWincsOvertimePromise
        ])
            .then(values => {
                console.log(values[4].payload.data)
                this.props.stopLoading()
                this.setState({
                    wincTotals: values[0].payload.data,
                    wincTotalsByPerson: values[1].payload.data,
                    wincCount: values[2].payload.data,
                    wincRatioTotalsByPerson: values[3].payload.data,
                    wincCountOverTime: this._timeGraphData(values[4].payload.data),
                    cumulativeWincCountOverTime: this._timeGraphData(values[5].payload.data),
                })
            })
            .catch(e => {
                this.props.stopLoading()
                showError(e)
            })
    }

    _onSelectRangeForOvertime = (from, to) => {
        this.props.getTeamWincsOverTime(from, to)
            .then(response => {
                console.log(response.payload.data)
                this.setState({
                    ...this.state,
                    wincCountOverTime: this._timeGraphData(response.payload.data)
                })
            })
            .catch(showError)
    }

    _timeGraphData = (data) => {
        return data.map(({totalWinc, time}) => ({
            date: time,
            wincCount: totalWinc
        }))
    }

    _onSelectRangeForCumulative = (from, to) => {
        this.props.getCumulativeTeamWincsOverTime(from, to)
            .then(response => {
                this.setState({
                    ...this.state,
                    cumulativeWincCountOverTime: this._timeGraphData(response.payload.data)
                })
            })
            .catch(showError)
    }

    _onCustomDateSelect = () => {

    }

    render() {
        const {
            wincTotalsByPerson,
            wincTotals,
            wincCount,
            wincRatioTotalsByPerson,
            wincCountOverTime,
            cumulativeWincCountOverTime
        } = this.state
        let dataExists = wincTotals &&
            wincTotalsByPerson &&
            wincCount > 0 &&
            wincRatioTotalsByPerson &&
            wincCountOverTime &&
            cumulativeWincCountOverTime
        return (
            <Wrapper>
                {
                    !dataExists &&
                    <NoDataContainer>
                        <NoData/>
                    </NoDataContainer>
                }
                {
                    dataExists &&
                    <Header>
                        <DashboardTitle count={wincCount}/>
                        <MobileTitleDisplay count={wincCount}/>
                    </Header>
                }
                {
                    dataExists &&
                    <GridContainer>
                        <Grid>
                            <FullView>
                                <ScatterGraph data={wincRatioTotalsByPerson}
                                              onClickPerson={this._onClickPerson}/>
                            </FullView>
                            <RatioGraph isFlex data={wincTotals.ratios}/>
                            <MemberGraph isFlex title={'Score per team member'}
                                         data={wincTotalsByPerson.memberTotals}
                                         tooltip={'Score'}
                                         valueKey={'toatlRatio'}
                                         onClickPerson={this._onClickPerson}
                            />
                            <ScoreGraph isFlex data={wincTotals.scores}/>
                            <MemberGraph isFlex
                                         title={'Grade per team member'}
                                         tooltip={'Grade'}
                                         data={wincTotalsByPerson.memberTotals}
                                         onClickPerson={this._onClickPerson}
                                         valueKey={'toatlScore'}/>
                            <ImpactScoreGraph isFlex data={wincTotals.impacts}/>
                            <MemberGraph isFlex
                                         title={'Impact per team member'}
                                         data={wincTotalsByPerson.memberTotals}
                                         tooltip={'Impact'}
                                         onClickPerson={this._onClickPerson}
                                         valueKey={'toatlImpact'}/>
                            <FullView>
                                <WincTimeGraph title={'Wincs over time trend'}
                                               isFlex
                                               onSelectDateRange={this._onSelectRangeForOvertime}
                                               data={wincCountOverTime}
                                />
                            </FullView>
                            <FullView>
                                <WincTimeGraph title={'Wincs over time'}
                                               isFlex
                                               data={cumulativeWincCountOverTime}
                                               onSelectDateRange={this._onSelectRangeForCumulative}
                                />
                            </FullView>
                        </Grid>
                    </GridContainer>
                }
            </Wrapper>
        )
    }
}
