import { css } from 'styled-components'

/*
const allSizes = {
    phone: {
        portrait: {
            min: 320,
            max: 480
        },
        landscape: {
            min: 320,
            max: 480
        }
    },
    tablet: {
        portrait: {
            min: 768,
            max: 1024
        },
        landscape: {
            min: 768,
            max: 1024
        }
    },
    desktop: {
        portrait: {
            min: 1025,
            max: undefined
        },
        landscape: {
            min: 1025,
            max: undefined
        }
    }
}
*/

const sizes = {
    phone: {
        min: 320,
        max: 480
    },
    tablet: {
        min: 768,
        max: 1024
    },
    desktop: {
        min: 1025
    }
}

/*
const media = {
    phone: (...args) => {
        return css`
        @media only screen and (max-width: 600px) {
          ${css(...args)}
        }`
    },
    largePhone: (...args) => {
        return css`
        @media only screen and (min-width: 600px) {
          ${css(...args)}
        }`
    },
    tablet: (...args) => {
        return css`
        @media only screen and (min-width: 768px) {
          ${css(...args)}
        }`
    },
    desktop: (...args) => {
        return css`
        @media only screen and (min-width: 992px) {
          ${css(...args)}
        }`
    },
    giant: (...args) => {
        return css`
        @media only screen and (min-width: 1200px) {
          ${css(...args)}
        }`
    },
}
*/

const media = Object.keys(sizes).reduce((finalMedia, size) => {
    return {
        ...finalMedia,
        [size]: (...args) => {
            const {min, max} = sizes[size]
            return css`
                @media (min-width: ${min ? min : `unset`}px) and (max-width: ${max ? max : `unset`}px) {
                    ${css(...args)}
                }
`
        }
    }
}, {})

media.phoneL = (...args) => css`
  @media (orientation:landscape) and (min-device-width: 320px) {
                  ${css(...args)}
  }
`

export { media }
