import { bindActionCreators } from "redux"
import { connect } from 'react-redux'
import * as actions from '../../actions/authentication'
import { NavigationBar } from "./NavigationBar";

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(actions, dispatch)
}

export const ConnectedNavigationBar = connect(mapStateToProps, mapDispatchToProps)(NavigationBar)