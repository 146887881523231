import React, { Component } from 'react'
import styled from 'styled-components'
import { AbsoluteFullPageContainer, CountTitle, fontDiv, TitleContainer } from "../common"
import { Images } from '../../assets'

const PageContainer = styled(AbsoluteFullPageContainer)`
    background-color: white;
`

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`

const MainContent = styled.div`
  min-height: 531px;
  padding: 50px 0 0 0;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`

const TitleDisplay = styled(TitleContainer)`
  flex: unset;
  width: 600px;
  padding: 85px 0 30px 0;
  background-color: white;
  box-sizing: border-box;
`

const Line = styled.div`
  background-color: #e0dde4;
  height: 1px;
  align-self: stretch;
`

const Action = styled.div`
  display: flex;
  padding: 18px 25px 18px 25px;
  align-self: stretch;
  justify-content: space-between;
`

const Icon = styled.img`
  width: 7px;
  height: auto;
  margin: 0;
  align-self: center;
`

const Title = styled(fontDiv)`
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #5f5662;
`


export class SettingsPage extends Component {
    _navigateToPath = path => {
        this.props.history.push(this.props.location.pathname + path)
    }

    _goToAboutPage = () => {
        this._navigateToPath('/about')
    }

    _goToCategoriesSettingsPage = () => {
        this._navigateToPath('/category-settings')
    }

    _goToAccountSettings = () => {
        this._navigateToPath('/account-settings')
    }

    render() {
        return (
            <PageContainer>
                <ContentContainer>
                    <TitleDisplay>
                        <CountTitle title={'Settings'}
                                    titleSize={50}
                                    displayCount={false}/>
                    </TitleDisplay>
                    <MainContent>
                        <Line/>
                        <Action onClick={this._goToAccountSettings}>
                            <Title>Account</Title>
                            <Icon src={Images.rightArrow}/>
                        </Action>
                        <Line/>
                        <Action onClick={this._goToCategoriesSettingsPage}>
                            <Title>Categories</Title>
                            <Icon src={Images.rightArrow}/>
                        </Action>
                        <Line/>
                    </MainContent>
                </ContentContainer>
            </PageContainer>
        )
    }
}