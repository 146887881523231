import styled from "styled-components"
import { fontDiv } from "../../../common"

export const GridContainer = styled.div`
  float: none;
  margin: auto;
  display: flex;
  flex: 1;
  background-color: transparent;
  max-width: 1130px;
  padding-bottom: 30px;
`

export const Grid = styled.div`
  float: none;
  margin: auto;
  display: grid;
  flex: 1;
  grid-template-columns: repeat(auto-fill, minmax(320px, auto));
  grid-template-rows: repeat(auto-fill, minmax(50px, auto));
  grid-column-gap: 41px;
  grid-row-gap: 33px;
  background-color: transparent;
  justify-content: center;
  padding-top: 5px;
`

export const Title = styled(fontDiv)`
  font-size: 35px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5f5662;
`