import { bindActionCreators } from "redux"
import * as actions from "../../../actions/authentication"
import { connect } from 'react-redux'
import { ResetPasswordPage } from "./ResetPasswordPage"
import { WithLoaderContext } from '../../WithLoaderContext'

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(actions, dispatch)
}

export const ConnectedResetPasswordPage = connect(mapStateToProps, mapDispatchToProps)(WithLoaderContext(ResetPasswordPage))