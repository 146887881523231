import { WincService } from "../services/WincService"

export const addWinc = winc => {
    return {
        type: 'winc-service/ADD_WINC',
        payload: {
            request: WincService.addWinc(winc)
        }
    }
}

export const updateWinc = winc => {
    return {
        type: 'winc-service/UPDATE_WINC',
        payload: {
            request: WincService.updateWinc(winc)
        }
    }
}

export const deleteWinc = winc => {
    return {
        type: 'winc-service/DELETE_WINC',
        payload: {
            request: WincService.deleteWinc(winc)
        }
    }
}

export const deleteCategory = () => {

}

export const getAllImpacts = () => {
    return {
        type: 'GET_WINC_IMPACTS',
        payload: {
            request: WincService.getAllImpacts()
        }
    }
}

export const getAllScores = () => {
    return {
        type: 'GET_WINC_SCORE',
        payload: {
            request: WincService.getAllScores()
        }
    }
}