import React from 'react'
import styled from 'styled-components'
// import { HexagonText } from './HexagonText'
import { Clickable, HexagonText } from '../../../common'

const Footer = styled.div`
    height: 60px !important;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 8px;
    margin: 0 10px 2px 10px;
    box-sizing: content-box;
    display: flex;
    justify-content: flex-start;
    ::-webkit-scrollbar-thumb {
      background-color: #700cff;
      outline: 0.5px solid grey;
      border-radius: 2px;
    }
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 2px;
    }
    ::-webkit-scrollbar {
        width: 1em;
        height: 5px;
    }

`

const ButtonBase = styled(Clickable)`
  align-items: center;
  padding: ${({pv = 0, ph = 3}) => `${pv}px ${ph}px`};
`

const Person = (props) => {
    const {name, index, onClickPerson} = props
    return (
        <ButtonBase ph={index === 0 ? 0 : undefined} onClick={onClickPerson}>
            <HexagonText name={name} index={index} size={30}
                         fontSize={10}/>
        </ButtonBase>
    )
}


export const CategoryFooter = (props) => {
    let {people, onClickPerson} = props
    return (
        <Footer count={people.length}>
            {
                people.map(({name, id}, index) => <Person name={name} index={index} key={`${index}`}
                                                          onClickPerson={(e) => {
                                                              e.stopPropagation()
                                                              onClickPerson(id)
                                                          }}/>)
            }
        </Footer>
    )
}

CategoryFooter.defaultProps = {
    people: [
        {name: 'a b'},
        {name: 'bc'},
        {name: 'x y z'},
        {name: 'a b'},
        {name: 'bc'},
        {name: 'x y z'},
        {name: 'a b'},
        {name: 'bc'},
        {name: 'x y z'},
        {name: 'a b'},
        {name: 'bc'},
        {name: 'x y z'},
    ]
}