import React from 'react'
import styled from 'styled-components'
import { fontDiv } from "../../../common"

const Header = styled.div`
    height: 42px;
    border-bottom: 2px solid #f9f7fa;
    display: flex;
    align-items: center;
    padding: 15px;
    box-sizing: border-box;
    margin: 5px 0 15px 0;
`

const Name = styled(fontDiv)`
  font-size: 13px;
  font-weight: 400;
  color: #5f5662;
`
const Count = styled(fontDiv)`
  height: 20px;
  border-radius: 12px;
  background-color: #f6f6f6;
  font-size: 12px;
  margin-left: 12px;
  padding: 0 16px 0 16px;
  color: #5f5662;
  align-items: center;
  display: flex;
`

/*
const Space = styled.div`
  align-self: stretch;
  flex: 1;
`

const ExpandLogo = styled.img`
  width: 25px;
  height: 25px;
`

const ButtonContainer = styled(Clickable)`
   width: 40px;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   margin-right: -10px;
   border: none;
   :active {
      transform: scale(0.80);
   }
`
*/

export const CategoryHeader = (props) => {
    const {name = 'N/A', wincCount = 0} = props
    return (
        <Header>
            <Name>{name}</Name>
            <Count>{wincCount}</Count>
            {/*<Space/>*/}
            {/*<ButtonContainer onClick={onClick}>*/}
            {/*    <ExpandLogo src={Images.expandIcon}/>*/}
            {/*</ButtonContainer>*/}
        </Header>
    )
}