import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import { multiClientMiddleware } from 'redux-axios-middleware'
import { reducers } from '../reducers'
import { routerMiddleware } from 'react-router-redux'
import { clients } from "../services/clients"
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { history } from './history'
import logger from 'redux-logger'

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
    whitelist: ['selectedUser', 'selectedTeamCategory']
}

const reduxRouterMiddleware = routerMiddleware(history)
const pReducer = persistReducer(persistConfig, reducers)

let middleware = [thunk, reduxRouterMiddleware, multiClientMiddleware(clients)]
if (process.env.IS_DEV) {
    middleware = [...middleware, logger]
}

export const store = createStore(pReducer, applyMiddleware(...middleware))
export const persistor = persistStore(store)