import React, { Component } from 'react'
import styled from 'styled-components'

const PageContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
`

const Title = styled.div`
  font-size: 40px;
  text-align: center;
  padding: 20px;
`

export class AboutPage extends Component {
    render() {
        return (
            <PageContainer>
                <Title>{'Welcome to About Page'}</Title>
            </PageContainer>
        )
    }
}