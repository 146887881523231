import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from "prop-types"

// const styles = {
//     dialogPaper: {
//         height: 200
//     },
// }

class DefaultAlertDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: props.title,
            message: props.message,
            open: false,
        }
    }

    showContent = (title, message, onClick) => {
        this.setState({title, message, open: true, onClick})
    }

    show = (dismissCallback) => {
        this.setState({open: true, onClick: dismissCallback})
    }

    hide = () => {
        this.setState({open: false})
        if (this.state.onClick) {
            this.state.onClick()
        }
    }

    render() {
        const {title, message} = this.state
        const {actions} = this.props
        return (<Dialog
                maxWidth={'sm'}
                fullWidth
                open={this.state.open}
                scroll={'paper'}
                onClose={this.hide}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        actions.map(({
                                         title,
                                         positive = true,
                                         onPress = () => {
                                         }
                                     }, index) =>
                            <Button key={`${index}`}
                                    onClick={() => {
                                        this.hide()
                                        onPress()
                                    }}
                                    color={positive ? "primary" : "secondary"}
                                    autoFocus>
                                {title}
                            </Button>)
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

DefaultAlertDialog.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    actions: PropTypes.instanceOf(Array)
}

DefaultAlertDialog.defaultProps = {
    actions: [{
        title: 'OK',
        positive: true,
        onPress: () => {
        }
    }]
}

//const AlertDialog = withStyles(styles)(DefaultAlertDialog)
const AlertDialog = DefaultAlertDialog

export { AlertDialog }