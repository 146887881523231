import React from 'react'
import styled from 'styled-components'
import { Hexagon } from './Hexagon'
import { fontDiv } from '../../../common'
import { Images } from '../../../../assets'

const Container = styled.div`
  display: flex;
  border-bottom: 1px solid #f0eff1;
  padding: 15px 35px 15px 35px;
  align-items: center;
`

const Selection = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 11px;
  background-color: ${p => p.selected ? p.theme.primary : 'white'};
  border: ${p => p.selected ? `2px solid ${p.theme.primary}` : '2px solid #e0dde4'};
  margin: 0 18px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Name = styled(fontDiv)`
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #5f5662;
  margin: 0 0 0 36px;
`

const Icon = styled.img`
  width: 18px;
  margin: 0;
  align-self: center;
`

export const WincPerson = props => {
    const {onClick, person, selected, index} = props
    const {name, id} = person
    return (
        <Container onClick={() => onClick(id)}>
            <Selection selected={selected}>
                {selected && <Icon src={Images.checkmark}/>}
            </Selection>
            <Hexagon size={38} index={index}/>
            <Name>{name}</Name>
        </Container>
    )
}