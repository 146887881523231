import React, { Component } from 'react'
import { AbsoluteFullPageContainer } from './FullPageContainer'
import { BeatLoader } from 'react-spinners'
import styled from 'styled-components'

const Container = styled(AbsoluteFullPageContainer)`
  background-color: rgba(0, 0, 0, 0.1);
  display: flex; 
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 9999;
  elevation: 20;
  padding: 0;
`

export class Loader extends Component {
    render() {
        return (
            <Container>
                <div>
                    <BeatLoader
                        sizeUnit={"px"}
                        size={15}
                        margin={'5px'}
                        color={'#700cff'}
                        loading={true}
                    />
                </div>
            </Container>
        )
    }
}