import React, { Component } from "react"
import { PersonContainer } from "./PersonContainer"
import { Images } from "../../../../assets"
import styled from "styled-components"

const CircleContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Circle = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background-color: rgba(112, 12, 255, 0.15);
  align-items: center;
  justify-content: center;
  display: flex;
`
const PlusIcon = styled.img`
  height: 30px;
  width: 30px;
`

export class AddPerson extends Component {
    render() {
        return (
            <PersonContainer>
                <CircleContainer onClick={this.props.onClick}>
                    <Circle>
                        <PlusIcon src={Images.plusIcon}/>
                    </Circle>
                </CircleContainer>
            </PersonContainer>
        )
    }
}