import React from 'react'
import ReactDOM from 'react-dom'
import registerServiceWorker from './registerServiceWorker'
import { persistor, store } from "./store"
import { Provider } from 'react-redux'
import { MuiThemeProvider } from "@material-ui/core"
import { appTheme, materialTheme } from "./theme"
import { ThemeProvider } from 'styled-components'
import './index.css'
import { ConnectedApp } from './App'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { setDefaultBreakpoints } from 'react-socks'
import "./global"
// import 'core-js/fn/number/is-nan'
import 'core-js'
// import 'core-js/es6'
import 'raf/polyfill'
// LogRocket.init('cbiq7a/winc-dashboard')
//element.removeAttribute('title')
// import LogRocket from 'logrocket'

setDefaultBreakpoints([
    {xs: 0},
    {s: 376},
    {m: 426},
    {l: 769},
    {xl: 1025}
])

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <ThemeProvider theme={appTheme}>
                <MuiThemeProvider theme={materialTheme}>
                    <ConnectedApp/>
                </MuiThemeProvider>
            </ThemeProvider>
        </PersistGate>
    </Provider>, document.getElementById('root'))

registerServiceWorker()