import React from "react"

export const AppContext = React.createContext("app-context")

export const withAppContext = Component => {
    return componentProps => (
        <AppContext.Consumer>
            {
                contextProps => (
                    <Component {...componentProps} {...contextProps}/>
                )
            }
        </AppContext.Consumer>
    )
}
