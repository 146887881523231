import React, { Component } from 'react'
import styled from 'styled-components'
import { AbsoluteFullPageContainer, CountTitle, TitleContainer } from "../../common"
import { RatioGraph } from "../graphs"
import { ScoreGraph } from "../graphs/ScoreGraph"
import { ImpactScoreGraph } from "../graphs/ImpactScoreGraph"
import { Grid, GridContainer } from "./common"
import { showError } from '../../../utils'

const CategoryGridContainer = styled(GridContainer)`
  padding-bottom: 0;
`
const CatGrid = styled(Grid)`
  grid-template-rows: repeat(auto-fill, 263px);
`

export class CategoryPage extends Component {
    constructor(props) {
        super(props)
        console.log(props.selectedCategory)
        this.state = {graphData: undefined}
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this._loadTeamLevelWincInfoForSingleCategory()
    }

    _loadTeamLevelWincInfoForSingleCategory = () => {
        let {categoryId} = this.props.match.params
        this.props.startLoading()
        this.props.getWincsInfoForIndividualCategory(categoryId)
            .then(response => {
                this.props.stopLoading()
                this.setState({graphData: response.payload.data})
            })
            .catch(e => {
                this.props.stopLoading()
                showError(e)
            })
    }

    render() {
        const {graphData} = this.state
        const {selectedCategory: {category = 'N/A', totalWincs = 0}} = this.props
        return (
            <AbsoluteFullPageContainer>
                <TitleContainer>
                    <CountTitle title={category}
                                count={totalWincs}
                                onClickBack={this.props.history.goBack}/>
                </TitleContainer>
                {
                    graphData &&
                    <CategoryGridContainer>
                        <CatGrid>
                            <RatioGraph isFlex data={graphData.ratios}/>
                            <ScoreGraph isFlex data={graphData.scores}/>
                            <ImpactScoreGraph isFlex data={graphData.impacts}/>
                        </CatGrid>
                    </CategoryGridContainer>
                }

            </AbsoluteFullPageContainer>
        )
    }
}