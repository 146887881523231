import React, { Component } from 'react'
import styled from "styled-components"
import { Clickable, fontDiv } from "../../../common"
import { SeverityIndicator } from "../../graphs/common"
import { Images } from "../../../../assets"
import PropTypes from 'prop-types'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  height: 210px;
  box-shadow: 0 1px 5px 0 rgba(179, 168, 181, 0.23);
  background-color: #ffffff;
  padding: 15px;
  box-sizing: border-box;
`

const Title = styled(fontDiv)`
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5f5662;
`
const ScoreContainer = styled.div`
  display: flex;
  padding: 10px;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  box-sizing: border-box;
`

const Score = styled(Clickable)`
  display: flex;
  width: ${p => p.selected ? 96 : 58}px;
  height: ${p => p.selected ? 96 : 58}px;
  border-radius: ${p => p.selected ? 48 : 29}px;
  background-color: #700cff;
  justify-content: center;
  transition-duration: 0.4s;
  transform: none;
  :active {
      border: none;
  }
`
const ScoreTitle = styled(fontDiv)`
    font-size: 18px;
    color: #ffffff;
`

const Logo = styled.img`
  height: 50px;
  width: auto;
  transition-duration: 0.4s;
`

export class SelectScore extends Component {

    _renderContent = () => {
        const {list, selected, onSelectScore} = this.props
        return list.map(s => {
            let isSelected = s === selected
            return (
                <Score selected={isSelected} key={s}
                       onClick={() => onSelectScore(s)}>
                    {
                        isSelected ? <Logo src={Images.checkmark}/> :
                            <ScoreTitle>{s.toString()}</ScoreTitle>
                    }
                </Score>
            )
        })
    }

    render() {
        return (
            <Container>
                <Title>{'Select a grade'}</Title>
                <ScoreContainer>
                    {
                        this._renderContent()
                    }
                </ScoreContainer>
                <SeverityIndicator leftLabel={'Lowest'} rightLabel={'highest'} height={2}
                                   fontWeight={400}/>
            </Container>
        )
    }
}

SelectScore.propTypes = {
    list: PropTypes.array.isRequired,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}