const colors = [
    '#e045cb', 'rgba(149, 231, 157, 1.0)', '#8a96e4', '#d2dd84', '#fc8c9b'
]

export const randomColor = () => {
    let rand = Math.floor((Math.random() * colors.length))
    return colors[rand]
}

export const getColor = (index) => {
    if (!index || (index && index < 0)) {
        return colors[0]
    }

    if (index > colors.length - 1) {
        index = index % colors.length
    }
    return colors[index]
}