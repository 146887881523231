import { createMuiTheme } from "@material-ui/core"
import { appTheme } from "./appTheme"

// const color = "#f00"
export const materialTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: appTheme.primary
        },
        secondary: {
            main: appTheme.secondary
        },
        error: {
            main: appTheme.error
        }
    },
    // overrides: {
    //     MuiInput: {
    //         underline: {
    //             "&:disabled": {
    //                 borderBottom: `2px solid green`
    //             }
    //         }
    //     }
    // }
    // shadows: Array(25).fill('none')
})