import { RESET_SELECTED_CATEGORY, SELECT_CATEGORY } from '../actions'
import { userConstants } from '../constants/userConstants'

export const selectedTeamCategory = (state = {}, action) => {
    switch (action.type) {
        case SELECT_CATEGORY: {
            return {...action.selectedCategory}
        }
        case RESET_SELECTED_CATEGORY: {
            return {}
        }
        case userConstants.LOGOUT:
            return {}
        default:
            return state
    }
}