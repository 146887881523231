import React, { Component } from 'react'
import styled from 'styled-components'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import InputAdornment from '@material-ui/core/InputAdornment'
import {
    AbsoluteFullPageContainer,
    AlertDialog,
    CountTitle,
    InputTextField,
    TitleContainer
} from "../../common"
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import Close from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Add from '@material-ui/icons/Add'
import { showError, sortAlphabetically } from '../../../utils'
import { ReAssignDialog } from './common/ReAssignDialog'

const PageContainer = styled(AbsoluteFullPageContainer)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 0 0 0;
`

const ContentContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 500px;
`

const TitleDisplay = styled(TitleContainer)`
  flex: unset;
  padding: unset;
  width: 500px;
  height: 100px;
  align-items: flex-end;
  background-color: #fbf8ff;
`

const CategoryList = styled(List)``

const CategoryListItem = styled(ListItem).attrs({
    style: {
        paddingLeft: 0,
        paddingRight: 0
    }
})``

const CategoryInput = styled(InputTextField)``

const iconSize = 18
const iconStyle = {width: iconSize, height: iconSize}
const StyledEdit = styled(Edit).attrs({style: iconStyle})``
const StyledDelete = styled(Delete).attrs({style: iconStyle})``
const StyledClose = styled(Close).attrs({style: iconStyle})``
const StyledAdd = styled(Add).attrs({style: iconStyle})``

export class CategoriesPage extends Component {
    constructor() {
        super()
        this.inputs = []
        this.state = {
            currentIndex: -1,
            categories: [],
            newCategory: '',
        }

        this.categoryToBeDeleted = undefined
        this.peopleWithWincs = undefined
        this.alertActions = [
            {
                title: 'No',
                positive: true,
            },
            {
                title: 'Yes',
                positive: false,
                onPress: this._findOutIfAnyWincsAssociated
            }
        ]
    }

    componentDidMount() {
        this._loadCategories()
    }

    _loadCategories = () => {
        this.props.startLoading()
        this.props.listAllCategories()
            .then(response => {
                this.props.stopLoading()
                let loadedCategories = response.payload.data.slice()
                this.setState({...this.state, categories: sortAlphabetically(loadedCategories)})
            })
            .catch(e => {
                this.props.stopLoading()
                showError(e)
            })
    }

    _startEditingCategory = index => {
        this.setState({...this.state, currentIndex: index}, () => this.inputs[index].focus())
    }

    _clearText = (index) => {
        let newCategories = this.state.categories.slice()
        newCategories[index].name = ''
        sortAlphabetically(newCategories)
        this.setState({categories: newCategories}, () => this.inputs[index].focus())
    }

    _onChangeText = (text, index) => {
        let newCategories = this.state.categories.slice()
        newCategories[index].name = text
        sortAlphabetically(newCategories)
        this.setState({categories: newCategories})
        this._updateCategory(newCategories[index])
    }

    _addCategory = () => {
        let {newCategory} = this.state
        if (newCategory.length <= 5 || newCategory.length > 50) {
            showError('Category length should be between 5, 50 characters')
            return
        }
        let newCat = {id: Date.now(), name: this.state.newCategory}
        let newCategories = this.state.categories.slice()
        newCategories.push(newCat)
        sortAlphabetically(newCategories)
        this.setState({categories: newCategories, currentIndex: -1, newCategory: ''})
        this.props.addCategory(newCat)
    }

    _updateCategory = (category) => {
        this.props.updateCategory(category)
    }

    _onBlur = () => {
        this.setState({...this.state, currentIndex: -1})
    }

    _adorment = (key, icon, index, action) => (
        <InputAdornment
            variant="standard"
            key={key}
            position="end">
            <IconButton style={{
                padding: 5,
                margin: 0
            }} onClick={() => action(index)}>
                {icon}
            </IconButton>
        </InputAdornment>)

    _adorments = index => {
        return index === this.state.currentIndex ? this._adorment('close',
            <StyledClose/>, index, this._clearText) :
            [
                this._adorment('edit', <StyledEdit/>, index, this._startEditingCategory),
                this._adorment('delete', <StyledDelete/>, index, this._showDeleteConfirmation),
            ]
    }

    _newTextInputAdorments = () => {
        return this.state.newCategory.length > 0 ? this._adorment('add',
            <StyledAdd/>, 0, this._addCategory) : []
    }

    _showDeleteConfirmation = index => {
        this.categoryToBeDeleted = this.state.categories[index]
        this.alert.showContent('Confirmation!', `Are you sure, you want to delete category '${this.categoryToBeDeleted.name}'`)
    }

    _findOutIfAnyWincsAssociated = () => {
        console.log('_findOutIfAnyWincsAssociated', JSON.stringify(this.categoryToBeDeleted))
        const {id, name} = this.categoryToBeDeleted
        this.props.getPeopleWithWincsFromCategory(id)
            .then(response => {
                let people = response.payload.data
                this.peopleWithWincs = people
                if (people.length > 0) {
                    this.reassignAlert.show(people.length, name)
                } else {
                    this._deleteCategory()
                }
            })
            .catch(e => {
                this.props.stopLoading()
                showError(e)
            })
    }

    _deleteCategory = () => {
        let newCategories = this.state.categories.filter(c => c.id !== this.categoryToBeDeleted.id)
        this.setState({...this.state, categories: sortAlphabetically(newCategories)})
        this.props.deleteCategory(this.categoryToBeDeleted)
        this.categoryToBeDeleted = undefined
        this.peopleWithWincs = undefined
    }

    _reassignAllWincsBeforeDelete = () => {
        this.props.history.push({
            pathname: '/settings/swap-wincs',
            state: {
                category: this.categoryToBeDeleted,
                people: this.peopleWithWincs
            }
        })
    }

    _categoryUI = () => {
        const {categories, newCategory, currentIndex} = this.state
        return (
            <CategoryList>
                {
                    categories.map((cat, index) => {
                            return (
                                <CategoryListItem key={cat.id.toString()} dense>
                                    <CategoryInput fullWidth
                                                   inputRef={c => this.inputs.push(c)}
                                                   value={cat.name}
                                                   name={'username'}
                                                   disabled={index !== currentIndex}
                                                   onChange={e => this._onChangeText(e.target.value, index)}
                                                   onBlur={this._onBlur}
                                                   InputProps={{
                                                       style: {
                                                           margin: 0,
                                                           fontSize: 15,
                                                           paddingBottom: 5,
                                                           color: 'black',
                                                           fontFamily: 'Ubuntu, monospace'
                                                       },
                                                       endAdornment: this._adorments(index)
                                                   }}
                                    />
                                </CategoryListItem>
                            )
                        }
                    )
                }
                <CategoryListItem key={'newItem'} dense>
                    <CategoryInput fullWidth
                                   value={newCategory}
                                   placeholder={'New Category'}
                                   onChange={event => this.setState({
                                       ...this.state,
                                       currentIndex: this.state.categories.length,
                                       newCategory: event.target.value
                                   })}
                                   InputProps={{
                                       style: {
                                           margin: 0,
                                           fontSize: 15,
                                           paddingBottom: 5,
                                           color: 'black',
                                           fontFamily: 'Ubuntu, monospace'
                                       },
                                       endAdornment: this._newTextInputAdorments()
                                   }}
                    />
                </CategoryListItem>
            </CategoryList>
        )
    }

    render() {
        return (
            <PageContainer>
                <TitleDisplay>
                    <CountTitle title={'Categories'} onClickBack={this.props.history.goBack}
                                displayCount={false}/>
                </TitleDisplay>
                <ContentContainer>

                    {
                        this._categoryUI()
                    }
                </ContentContainer>
                <ReAssignDialog ref={c => this.reassignAlert = c}
                                onDeleteOnly={this._deleteCategory}
                                onReassign={this._reassignAllWincsBeforeDelete}/>
                <AlertDialog ref={c => this.alert = c} title={'Are you sure?'}
                             message={'My Message'} actions={this.alertActions}/>
            </PageContainer>
        )
    }
}
