const authService = 'auth-service/'
export const userConstants = {
    REGISTER_REQUEST: authService + 'USERS_REGISTER_REQUEST',

    LOGIN_REQUEST: authService + 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: authService + 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: authService + 'USERS_LOGIN_FAILURE',

    FORGOT_PASSWORD_REQUEST: authService + 'FORGOT_PASSWORD_REQUEST',

    FORCE_RESET_PASSWORD: authService + 'FORCE_RESET_PASSWORD',

    LOGOUT: authService + 'USERS_LOGOUT',

    GETALL_REQUEST: authService + 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: authService + 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: authService + 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: authService + 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: authService + 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: authService + 'USERS_DELETE_FAILURE'
}