import { bindActionCreators } from "redux"
import * as actions from "../../../actions/categories"
import { connect } from 'react-redux'
import { CategoriesPage } from "./CategoriesPage"
import { WithLoaderContext } from '../../WithLoaderContext'

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(actions, dispatch)
}

export const ConnectedCategoriesSettingsPage = connect(mapStateToProps, mapDispatchToProps)(WithLoaderContext(CategoriesPage))