import { accountId, teamId } from './utils'

export class WincService {

    /****** CRUD *********/

    /*** CREATE *****/
    static addWinc(winc) {
        let url = '/wincs'
        let wincData = {...winc, teamId: teamId(), accountId: accountId()}
        return {
            url, data: wincData, method: 'POST'
        }
    }

    /*** UPDATE *****/
    static updateWinc(winc) {
        let url = '/wincs'
        let wincData = {...winc, teamId: teamId(), accountId: accountId()}
        return {
            url, data: wincData, method: 'PUT'
        }
    }

    /*** DELTE *****/
    static deleteWinc(winc) {
        const {userId, id} = winc
        let url = `/wincs/${accountId()}/${teamId()}/${userId}/${id}`
        return {
            url, method: 'DELETE'
        }
    }

    static getAllImpacts() {
        let url = '/wincs/impacts'
        return {
            url, method: 'GET'
        }
    }

    static getAllScores() {
        let url = '/wincs/scores'
        return {
            url, method: 'GET'
        }
    }
}