import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { fontDiv } from '../../../common'
import { Images } from '../../../../assets'
import { media } from '../../../../utils'

const Wrapper = styled.div`
  user-select: none;
  position: relative;
  width: 180px;
  padding: 10px 10px;
  ${
    media.phone`
        width: 130px;
    `
}

`

const SelectedWrapper = styled(fontDiv)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  color: white;
  font-size: 20px;
  ${
    media.phone`
        font-size: 12px;
    `
}
  ${
    media.tablet`
        font-size: 16px;
    `
}
`

const SelectedItem = styled(fontDiv)`
  
`
const ListItem = styled(fontDiv)`
  font-weight: 300;
  color: ${({isSelected = false}) => isSelected ? `white` : `rgba(255, 255, 255, 0.4)`};
  position: relative;
  padding: 5px 0;
  ${
    media.phone`
        font-size: 12px;
    `
}
  ${
    media.tablet`
        font-size: 16px;
    `
}
`

const List = styled(fontDiv)`
    z-index: 10;
    position: absolute;
    background-color: ${({theme}) => theme.secondary};
    width: 180px;
    margin-left: -10px;
    padding: 0 10px;
    box-shadow: 0 3px 5px 0 rgba(179, 168, 181, 0.23);
    ${
    media.phone`
        width: 130px;
    `
}
`

const Icon = styled.img`
  height: auto;
  max-width: 20px;
`


export class HelpMenu extends Component {
    constructor(props) {
        super(props)
        const {topics, currentIndex} = props
        this.state = {
            topics,
            currentIndex,
            listOpen: false
        }
    }

    // componentDidMount() {
    //     setTimeout(this.close, 300)
    // }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.currentIndex !== nextProps.currentIndex) {
            this.setState(state => ({...state, currentIndex: nextProps.currentIndex}))
        }
    }

    close = () => {
        this.setState({
            listOpen: false
        })
    }

    componentDidUpdate() {
        const {listOpen} = this.state
        setTimeout(() => {
            if (listOpen) {
                window.addEventListener('click', this.close)
            } else {
                window.removeEventListener('click', this.close)
            }
        }, 0)
    }

    toggleMenu = () => {
        this.setState(prevState => ({
            listOpen: !prevState.listOpen
        }))
    }

    render() {
        const {listOpen, topics, currentIndex, width} = this.state
        return (
            <Wrapper>
                <SelectedWrapper onClick={this.toggleMenu}>
                    <SelectedItem>{topics[currentIndex]}</SelectedItem>
                    <Icon src={listOpen ? Images.upArrow : Images.downArrow}/>
                </SelectedWrapper>
                {
                    listOpen &&
                    <List width={width}>
                        {
                            topics.map((f, i) => (
                                <ListItem key={i}
                                          isSelected={i === currentIndex}
                                          onClick={(e) => {
                                              e.stopPropagation()
                                              this.props.onSelectFeature(i)
                                              this.close()
                                          }}
                                >{f}</ListItem>))
                        }
                    </List>
                }
            </Wrapper>
        )
    }
}

HelpMenu.propTypes = {
    topics: PropTypes.array.isRequired,
    currentIndex: PropTypes.number.isRequired,
    onSelectFeature: PropTypes.func.isRequired,
}
