import React, { Component } from 'react'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import PropTypes from 'prop-types'
import { BarLabel, tickStyle, ValueToolTip } from "./common"
import { NoData } from '../../common'
import { isDataAvailable } from './util'
import { roundValue } from '../../../utils'

const defaultData = {
    totalRatio: 10, totalImpact: 2, totalScore: 5
}

class SummaryRawGraph extends Component {

    _transformedData = (data) => {
        const {totalImpact, totalRatio, totalScore, totalImpacts, totalRatios, totalScores, score, impact, ratio} = data
        return [
            {name: 'Score', value: roundValue(totalRatio | totalRatios | ratio)},
            {name: 'Grade', value: roundValue(totalScore | totalScores | score)},
            {name: 'Impact', value: roundValue(totalImpact | totalImpacts | impact)}
        ]
    }

    render() {
        let {data, theme} = this.props
        let transformedData = this._transformedData(data)
        if (!isDataAvailable(transformedData)) {
            return (
                <NoData/>
            )
        }
        return (
            <ResponsiveContainer height='100%' width='100%' aspect={undefined}>
                <BarChart data={transformedData}
                          barSize={80}>
                    <XAxis dataKey="name"
                           axisLine={false}
                           textAnchor={'start'}
                           tickLine={false}
                           height={30}
                           dx={-40}
                           tick={{
                               ...tickStyle, position: "left",
                           }}/>
                    <YAxis scale={'linear'} hide/>
                    <Bar dataKey="value" fill={theme}
                         isAnimationActive={false}
                         radius={2}
                         label={<BarLabel/>}
                    />
                    <Tooltip cursor={false}
                             content={<ValueToolTip/>}/>
                </BarChart>
            </ResponsiveContainer>)
    }
}

SummaryRawGraph.propTypes = {
    data: PropTypes.object.isRequired, theme: PropTypes.string,
    enableAnimation: PropTypes.bool
}

SummaryRawGraph.defaultProps = {
    data: defaultData, theme: '#700cff',
    enableAnimation: true
}

export { SummaryRawGraph }
