import React, { Component } from 'react'
import { ProfilePhoto } from './helpers/ProfilePhoto'
import { PersonDetails } from './helpers/PersonDetails'
import styled from 'styled-components'
import { AlertDialog, Clickable, fontDiv, TitleContainer } from '../../common'
import { connect } from 'react-redux'
import { Images } from '../../../assets'
import PropTypes from 'prop-types'
import { showError } from '../../../utils'
import { WithLoaderContext } from '../../WithLoaderContext'
import { deleteUser } from '../../../actions'
import { media } from '../../../utils'
import { MobileSelection } from './helpers/MobileSelection'

const DetailsContainer = styled.div`
    margin: 0 0 0 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    ${
    media.phone`
        margin: 20px 0 0 0;
        flex-direction: column;
    `
}
`

const FullWidthContainer = styled(TitleContainer)`
  max-width: unset;
  justify-content: center;
  ${
    media.phone`
       margin-bottom: 0;
       padding: 10px 0 10px 0;
    `
}
`

const Header = styled(fontDiv)`
    display: flex;
    flex: 1;
    max-width: 1040px;
    ${
    media.phone`
       flex-direction: column;
       align-items: center;
       margin-bottom: 0;
       max-width: unset;
    `
}
`

const Button = styled(Clickable)`
  width: 124px;
  height: 40px;
  border-radius: 20px;
  background-color: #e045cb;
  color: white;
  font-weight: 500;
  justify-content: center;
  font-size: 12px;
  ${
    media.phone`
       margin-top: 20px;
    `
}
`


const ActionContainer = styled.div`
  display: flex;
  position: absolute;
  right: 30px;
  flex-direction: column;
  ${
    media.phone`
    right: 10px;
    `
}
`

const ButtonContainer = styled(Clickable)`
   width: 40px;
   height: 40px;
   justify-content: center;
   align-items: center;
   border: none;
   :active {
      transform: scale(0.80);
   }
   ${
    media.phone`
    `
}
`

const Icon = styled.img`
  width: ${p => p.width}px;
  margin: 0;
  align-self: center;
`

const ActionButton = props => {
    const {src, onClick, size = 20} = props
    return (
        <ButtonContainer onClick={onClick}>
            <Icon width={size} src={src}/>
        </ButtonContainer>
    )
}

class PersonHeaderComponent extends Component {
    constructor() {
        super()
        this.alertActions = [
            {
                title: 'CANCEL',
                positive: true,
            },
            {
                title: 'DELETE',
                positive: false,
                onPress: this._deletePerson
            }
        ]
    }

    _addOrEditWinc = () => {
        this.props.history.push('/add-or-edit-winc')
    }

    _editPerson = () => {
        this.props.history.push('/add-or-edit-person')
    }

    _showDeleteConfirmation = () => {
        this.alert.showContent('Delete Person', 'Are you sure you want to delete this person?')
    }

    _deletePerson = () => {
        const {startLoading, deleteUser, stopLoading, profileInfo} = this.props
        startLoading()
        deleteUser(profileInfo)
            .then(() => {
                stopLoading()
                this.props.history.replace('/people')
            })
            .catch(e => {
                stopLoading()
                showError(e)
            })
    }

    componentDidMount() {
        this.refreshIndividualWincSummary()
    }

    refreshIndividualWincSummary = () => {
        const {profileInfo = {}} = this.props
        if (!profileInfo.id) {
            return
        }
        this.props.getIndividualWincSummary(this.props.profileInfo.id)
            .then(response => {
                this.props.updateSelectedUser(response.payload.data)
            })
            .catch(showError)
    }

    render() {
        let {profileInfo = {}, canAddWinc = true, canEditPerson = true, ...rest} = this.props
        const {totalWincs = 0, name = 'N/A'} = profileInfo
        return (
            <FullWidthContainer>
                <Header>
                    <ProfilePhoto name={name} wincCount={totalWincs}/>
                    <DetailsContainer>
                        <PersonDetails {...this.props}/>
                        {canAddWinc && <Button onClick={this._addOrEditWinc}>{'ADD WINC'}</Button>}
                    </DetailsContainer>
                    <MobileSelection {...rest}/>
                </Header>
                {canEditPerson &&
                <ActionContainer>
                    <ActionButton src={Images.editWinc}
                                  onClick={this._editPerson}/>
                    <ActionButton size={25}
                                  src={Images.deleteWinc}
                                  onClick={this._showDeleteConfirmation}/>
                </ActionContainer>
                }
                <AlertDialog ref={c => this.alert = c} title={''}
                             message={''}
                             actions={this.alertActions}/>
            </FullWidthContainer>
        )
    }
}


const mapStateToProps = (state) => ({
    profileInfo: state.selectedUser
})


const mapDispatchToProps = (dispatch) => {
    return {
        deleteUser: user => dispatch(deleteUser(user))
    }
}

export const PersonHeader = connect(mapStateToProps, mapDispatchToProps, null)(WithLoaderContext(PersonHeaderComponent))

PersonHeader.propTypes = {
    canEditPerson: PropTypes.bool,
    canAddWinc: PropTypes.bool
}
