import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
// import DialogContent from '@material-ui/core/DialogContent'
// import DialogTitle from '@material-ui/core/DialogTitle'
import styled from 'styled-components'
import { fontDiv, fontInput, fontTextArea } from './RootFontComponents'

const Title = styled(fontDiv)`
  font-size: 25px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #700cff;
  text-align: center;
  padding: 10px 0;
`

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px;
  height: 500px;
`

const TitleInput = styled(fontInput).attrs({
    autocomplete: "false"
})`
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #5f5662;
  margin: 10px 0;
  padding: 5px 10px;
  align-self: stretch;
  border: 1px solid #f4f3f4;
  border-radius: 2px;
  ::placeholder {
    color: #b1a5c5;
  }
`

const DescriptionInput = styled(fontTextArea).attrs({
    autocomplete: "false"
})`
  flex: 1;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #5f5662;
  box-sizing: border-box;
  border: 1px solid #f4f3f4;
  border-radius: 2px;
  padding: 10px;
  resize: none;
  ::placeholder {
    color: #b1a5c5;
  }
`

export class FeedbackDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            title: '',
            description: ''
        }
    }

    showFeedbackDialog = (onSaveCallback) => {
        this.setState({open: true, onSaveCallback})
    }

    hideAndReset = () => {
        this.setState({
            open: false,
            title: '',
            description: ''
        })
    }

    _onSave = () => {
        this.hideAndReset()
        const {onSaveCallback, open, ...feedbackInfo} = this.state
        if (onSaveCallback && feedbackInfo.title.length > 0 && feedbackInfo.description.length > 0) {
            onSaveCallback(feedbackInfo)
        }
    }

    _onChangeText = (e) => {
        if (!e.isTrusted) return
        let {name, value} = e.target
        this.setState({...this.state, [name]: value})
    }

    render() {
        const {title, description} = this.state
        return (<Dialog
                maxWidth={'md'}
                fullWidth
                open={this.state.open}
                scroll={'paper'}
                onClose={this.hide}
            >
                <DialogContent>
                    <Title>Feedback</Title>
                    <input type="hidden" value="anyValue"/>
                    <TitleInput onChange={this._onChangeText}
                                placeholder={'Enter title here..'}
                                name={'title'}
                                value={title}/>
                    <DescriptionInput onChange={this._onChangeText}
                                      value={description}
                                      name={'description'}
                                      placeholder={'Description...'}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.hideAndReset}
                            color={"secondary"}
                            autoFocus>
                        {"Cancel"}
                    </Button>
                    <Button onClick={this._onSave}
                            color={"primary"}
                            autoFocus>
                        {"Save"}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}