import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { fontDiv } from "../../../common"
import { SeverityIndicator } from "./SeverityIndicator"
import { media } from '../../../../utils'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    ${
    p => p.isFlex ?
        css`
            flex: 1; 
            align-self: stretch;
        ` :
        css`
          height: 257px;
        `
};
    border-radius: 4px;
    box-shadow: 0 1px 5px 0 rgba(179, 168, 181, 0.23);
    background-color: white;
    box-sizing: border-box;
    ${
    media.phone`
        padding: 10px;
    `
}
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${
    media.phone`
        flex-direction: ${({isAdaptive}) => isAdaptive ? 'column' : 'row'};
    `
}
`

const GraphTitle = styled(fontDiv)`
  font-size: 13px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5f5662;
  padding: 0 0 0 10px;
  margin: 8px 0 8px 0;
  ${
    media.phone`
        font-size: 11px;
    `
}
`

const MainGraphContainer = styled.div`
  display: flex;
  flex: 1;
`

export class GraphContainer extends Component {
    render() {
        const {
            showIndicator,
            actionComponent,
            title = '', isFlex, children,
            isAdaptive
        } = this.props

        return (
            <Container isFlex={isFlex}>
                {title.length > 0 &&
                <TitleContainer isAdaptive={isAdaptive}>
                    <GraphTitle>{title.toUpperCase()}</GraphTitle>
                    {
                        actionComponent
                    }
                </TitleContainer>
                }
                <MainGraphContainer>
                    {children}
                </MainGraphContainer>
                {
                    showIndicator && <SeverityIndicator/>
                }
            </Container>
        )
    }
}

GraphContainer.defaultProps = {
    isFlex: false,
    showIndicator: true,
    isAdaptive: false //No need to customize for mobile by default.
}
