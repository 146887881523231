import React, { Component } from 'react'
import styled from 'styled-components'
import queryString from 'query-string'
import { Validator } from "../../../utils/Validator"
import { InputTextField } from "../../common/InputTextField"
import { Button, fontDiv, FormContainer, OnBoardContainer } from "../../common"
import { media } from '../../../utils'

const Form = styled(FormContainer)`
  min-height: 450px;
  ${
    media.phone`
        padding: 20px;
        width: 100%;
        min-height: unset;
        display: flex;
        flex: 1;
        justify-content: flex-start;
        margin-top: 0;
        `
    }
`

const Title = styled(fontDiv)`
  font-size: 25px;
  text-align: center;
  padding: 2px;
  color: #700cff;
`
const SubTitle = styled(Title)`
  font-size: 15px;
  text-align: center;
  padding: 10px;
  color: black;
`

export class ResetPasswordPage extends Component {
    constructor() {
        super()
        this.state = {
            password: '',
            retypedPassword: '',
            errors: {
                password: '',
                retypedPassword: ''
            }
        }
    }

    _errorMessage = (inputType, value) => {
        if (value.length === 0) {
            return ''
        }
        switch (inputType) {
            case 'retypedPassword':
                if (value !== this.state.password) {
                    return 'Passwords did not match'
                }
                break
            default:
                if (!Validator.validatePassword(value)) {
                    return 'Please enter valid password'
                }
        }
        return ''
    }

    _onChangeText = (e) => {
        if (!e.isTrusted) return
        let {name, value} = e.target
        let errors = this.state.errors
        errors[name] = this._errorMessage(name, value)
        this.setState({...this.state, [name]: value, errors: {...errors}})
    }

    _onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this._resetPassword(e)
        }
    }

    _resetPassword = (e) => {
        e.preventDefault()
        const {password, retypedPassword} = this.state

        if (!Validator.validatePassword(password)) {
            alert('Please enter password')
            return
        }

        if (password !== retypedPassword) {
            alert("Passwords don't match")
            return
        }
        const values = queryString.parse(this.props.location.search)
        let {userName = '', oldPassword = ''} = values
        let user = {
            newPassword: password,
            userName,
            oldPassword
        }
        this.props.startLoading()
        this.props.forceResetPassword(user)
            .then(() => {
                this.props.stopLoading()
                alert('Password has been changed successfully. You will now be redirected to Login Page.')
                this.props.history.push('/login')
            })
            .catch(e => {
                this.props.stopLoading()
                alert(e.error.message)
            })
    }

    _areAllFieldsValid = () => {
        const {
            password,
            retypedPassword,
        } = this.state
        const {validatePassword} = Validator
        return validatePassword(password) && password === retypedPassword
    }

    render() {
        const {
            password,
            retypedPassword,
            errors
        } = this.state
        return (
            <OnBoardContainer solidBackgroundOnMobile>
                <Form onSubmit={this._resetPassword} onKeyPress={this._onKeyPress}>
                    <Title>{'Reset Password'}</Title>
                    <SubTitle>{'Enter your details below'}</SubTitle>
                    <InputTextField
                        type={'password'}
                        name={'password'}
                        error={Boolean(errors.password)}
                        label={'Password'}
                        value={password}
                        placeholder="New Password"
                        helperText={errors.password ? errors.password : ' '}
                        onChange={this._onChangeText}
                    />

                    <InputTextField
                        type={'password'}
                        name={'retypedPassword'}
                        error={Boolean(errors.retypedPassword)}
                        label={'Re-type new Password'}
                        value={retypedPassword}
                        placeholder={'Re-type new Password'}
                        helperText={errors.retypedPassword ? errors.retypedPassword : ' '}
                        onChange={this._onChangeText}
                    />
                    <Button type={'submit'}
                            style={{
                                alignSelf: 'stretch',
                                marginLeft: 30,
                                marginRight: 30,
                                marginTop: 30
                            }}
                            disabled={!this._areAllFieldsValid()}>
                        {'RESET PASSWORD'}
                    </Button>
                </Form>
            </OnBoardContainer>
        )
    }
}