import styled from "styled-components"
import { NavLink } from "react-router-dom"

export const fontDiv = styled.div`
  font-family: Ubuntu, monospace;
  font-weight: 500;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
`

export const fontLi = styled.li`
  font-family: Ubuntu, monospace;
  font-weight: 500;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
`


export const fontLink = styled.a`
  font-family: Ubuntu, monospace;
  font-weight: 500;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
`

export const fontText = styled.text`
  font-family: Ubuntu, monospace;
  font-weight: 500;
`

export const fontButton = styled.button`
  font-family: Ubuntu, monospace;
  font-weight: 500;
  :hover {
    cursor:pointer;
  }
`

export const fontNavLink = styled(NavLink)`
  font-family: Ubuntu, monospace;
  font-weight: 500;
`
export const fontInput = styled.input`
    font-family: Ubuntu, monospace;
    font-weight: 300;
    outline: none;
    border: none;
`

export const fontTextArea = styled.textarea`
    font-family: Ubuntu, monospace;
    font-weight: 400;
    outline: none;
    border: none;
`
