import { bindActionCreators } from "redux"
import * as actions from "../../../actions/index"
import { connect } from 'react-redux'
import { WelcomePage } from "./WelcomePage"

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(actions, dispatch)
}

export const ConnectedWelcomePage = connect(mapStateToProps, mapDispatchToProps)(WelcomePage)