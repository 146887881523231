import { bindActionCreators } from "redux"
import * as catActions from "../../../actions/categories"
import * as wincActions from "../../../actions/wincs"
import * as userActions from "../../../actions/user"
import { connect } from 'react-redux'
import { AddEditWincPage } from "./AddEditWincPage"
import { WithLoaderContext } from '../../WithLoaderContext'

const mapStateToProps = (state) => ({
    profileInfo: state.selectedUser
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({...catActions, ...wincActions, ...userActions}, dispatch)
}

export const ConnectedAddEditWincPage = connect(mapStateToProps, mapDispatchToProps)(WithLoaderContext(AddEditWincPage))