import React, { Component } from 'react'
import styled from 'styled-components'
import { CountTitle, fontDiv, FullPageContainer } from "../../common"
import { ImpactScoreGraph, RatioGraph, SummaryGraph, WincTimeGraph } from "../graphs"
import { ScoreGraph } from "../graphs/ScoreGraph"
import { Category } from "./helpers/Category"
import { PersonHeader } from './PersonHeader'
import { media, showError, sortAlphabetically } from '../../../utils'


const Grid = styled.div`
  display: grid;
  flex: 1;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(2, 262px) 80px repeat(auto-fill, 227px);
  grid-column-gap: 34px;
  grid-row-gap: 32px;
  background-color: transparent;
  box-sizing: border-box;
  ${
    media.phone`
        grid-template-columns: 1fr;
        grid-template-rows: ${({index = 0}) => index === 0 ? `repeat(5, 262px)` : `repeat(auto-fill, 227px)`};
        grid-column-gap: unset;
        grid-row-gap: 15px;
    `
}
`

const NonMobileGridContainer = styled.div`
  float: none;
  margin: auto;
  display: flex;
  max-width: 1040px;
  padding: 30px 0 30px 0 !important;
  box-sizing: border-box;
  ${
    media.phone`
        padding: 15px !important;
        padding-top: 0 !important;
        display: none;
    `
}
`

const MobileGridContainer = styled.div`
  float: none;
  margin: auto;
  max-width: 1040px;
  padding: 30px 0 30px 0 !important;
  box-sizing: border-box;
  display: none;
  ${
    media.phone`
        padding: 15px !important;
        padding-top: 0 !important;
        display: flex;
    `
}
`


const FullView = styled.div`
  display: flex;
  grid-column: 2 / span 2;
  ${
    media.phone`
        grid-column: unset;
    `
}
`

export const Title = styled(fontDiv)`
  font-size: 35px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5f5662;
`

export const CatHeader = styled.div`
  display: flex;
  grid-column: 1 / -1;
  align-items: center;
`

const CategoryHeader = props => {
    return (
        <CatHeader>
            <CountTitle title={'Categories'} count={props.count} countBgColor={'#700cff'}/>
        </CatHeader>
    )
}

// const mobileViews = ['overview', 'categories']

export class ProfilePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categories: [],
            graphData: undefined,
            wincCountOverTime: undefined,
            profileInfo: undefined,
            mobile: {
                index: 0
            }
        }
    }

    componentDidMount() {
        this.props.resetSelectedCategory()
        this._loadAllData()
    }

    _loadAllData = () => {
        let {profileId = 0} = this.props.match.params
        if (profileId === 0) {
            return
        }
        this.props.startLoading()
        let wincsInfoPromise = this.props.getIndividualWincsInfo(profileId)
        let categoryInfoPromise = this.props.getIndividualCategoriesInfo(profileId)
        let profileSummaryPromise = this.props.getIndividualWincSummary(profileId)
        let {from, to} = WincTimeGraph.getDefaultRange()
        let wincsOvertimePromise = this.props.getIndividualWincsOvertime(profileId, from, to)
        Promise.all([wincsInfoPromise, categoryInfoPromise, wincsOvertimePromise, profileSummaryPromise])
            .then(values => {
                this.props.stopLoading()
                let graphData = values[0].payload.data
                let categories = values[1].payload.data
                let wincCountOverTime = this._timeGraphData(values[2].payload.data)
                let profileInfo = values[3].payload.data
                this.props.selectUser(profileInfo)
                this.setState({
                    ...this.state,
                    graphData,
                    categories: sortAlphabetically(categories, 'category'),
                    wincCountOverTime,
                    profileInfo
                })
            })
            .catch(e => {
                this.props.stopLoading()
                showError(e)
            })
    }

    _categoriesUI = () => {
        return this.state.categories.map((categoryInfo, index) => <Category index={index}
                                                                            key={`${index}`}
                                                                            info={categoryInfo}
                                                                            onClick={this._onClickCategory}/>)
    }

    _onClickCategory = (cat) => {
        this.props.selectCategory(cat)
        this.props.history.push(`/show-wincs/${cat.categoryId}`)
    }

    _timeGraphData = (data) => {
        return data.map(({totalWinc, time}) => ({
            date: time,
            wincCount: totalWinc
        }))
    }

    _onSelectRangeForOvertime = (from, to) => {
        let {profileId = 0} = this.props.match.params
        if (profileId === 0) {
            return
        }

        this.props.getIndividualWincsOvertime(profileId, from, to)
            .then(response => {
                this.setState({
                    ...this.state,
                    wincCountOverTime: this._timeGraphData(response.payload.data)
                })
            })
            .catch(showError)
    }

    _onChangeMobileView = index => {
        this.setState(state => ({...state, mobile: {index}}))
    }

    _graphsUI = () => {
        const {profileInfo, graphData, wincCountOverTime} = this.state
        return (
            <>
                <SummaryGraph title={'Summary'} isFlex
                              theme={'#e045cb'}
                              data={profileInfo}/>
                <FullView>
                    <WincTimeGraph title={'wincs over time'}
                                   isFlex
                                   data={wincCountOverTime}
                                   onSelectDateRange={this._onSelectRangeForOvertime}/>
                </FullView>
                <RatioGraph isFlex data={graphData.ratios}/>
                <ScoreGraph isFlex data={graphData.scores}/>
                <ImpactScoreGraph isFlex data={graphData.impacts}/>
            </>
        )
    }

    render() {
        const {profileInfo, graphData, categories, wincCountOverTime, mobile: {index}} = this.state
        let dataExists = profileInfo && graphData && wincCountOverTime
        return (
            <FullPageContainer>
                {dataExists &&
                <PersonHeader {...this.props}
                              onSelectMobileView={this._onChangeMobileView}
                              selectedIndex={index}
                              categoryCount={categories.length}
                              showSelection
                />}
                {
                    dataExists &&
                    <NonMobileGridContainer>
                        <Grid>
                            {this._graphsUI()}
                            <CategoryHeader count={categories.length}/>
                            {this._categoriesUI()}
                        </Grid>
                    </NonMobileGridContainer>
                }

                {
                    dataExists &&
                    <MobileGridContainer>
                        <Grid index={index}>
                            {
                                index === 0 && this._graphsUI()
                            }
                            {
                                index === 1 && this._categoriesUI()
                            }
                        </Grid>
                    </MobileGridContainer>
                }
            </FullPageContainer>
        )
    }
}
