import { bindActionCreators } from "redux"
import * as actions from "../../../actions/categories"
import { connect } from 'react-redux'
import { TeamCategoryListPage } from './TeamCategoryListPage'
import { WithLoaderContext } from '../../WithLoaderContext'

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(actions, dispatch)
}

export const ConnectedTeamCategoriesPage = connect(mapStateToProps, mapDispatchToProps)(WithLoaderContext(TeamCategoryListPage))