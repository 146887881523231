import { ForgotPasswordPage } from "./ForgotPasswordPage"
import { bindActionCreators } from "redux"
import * as actions from "../../../actions/index"
import { connect } from 'react-redux'
import { WithLoaderContext } from '../../WithLoaderContext'

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(actions, dispatch)
}

export const ConnectedForgotPasswordPage = connect(mapStateToProps, mapDispatchToProps)(WithLoaderContext(ForgotPasswordPage))