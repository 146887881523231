import { bindActionCreators } from "redux"
import * as actions from "../../../actions/user"
import { connect } from 'react-redux'
import { ProfilePage } from './ProfilePage'
import { WithLoaderContext } from '../../WithLoaderContext'

const mapStateToProps = (state) => ({
    profileInfo: state.selectedUser
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(actions, dispatch)
}

export const ConnectedProfilePage = connect(mapStateToProps, mapDispatchToProps)(WithLoaderContext(ProfilePage))