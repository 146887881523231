import React from 'react'
import styled from "styled-components"
import { Clickable, fontDiv } from "../../../common"
import { Images } from "../../../../assets"
import PropTypes from 'prop-types'

const ActionContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 30px 0 30px 0;
`
const SaveWinc = styled(Clickable)`
  height: 60px;
  border-radius: 30px;
  background-color: #e045cb;
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: center;
`
const CancelWinc = styled(SaveWinc)`
  background-color: transparent;
  flex: 1;
`
const Logo = styled.img`
  height: 25px;
  width: auto;
  transition-duration: 0.4s;
  padding-right: 5px;
`

const Title = styled(fontDiv)`
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${p => p.default ? `#e045cb` : `white`};
`

export const WincActions = (props) => {
    const {onCancel, onSave, cancelTitle, successTitle} = props
    return (
        <ActionContainer>
            <CancelWinc onClick={onCancel}>
                <Title default>{cancelTitle}</Title>
            </CancelWinc>
            <SaveWinc onClick={onSave}>
                <Logo src={Images.checkmark}/>
                <Title>{successTitle}</Title>
            </SaveWinc>
        </ActionContainer>
    )
}

WincActions.propTypes = {
    successTitle: PropTypes.string,
    cancelTitle: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

WincActions.defaultProps = {
    successTitle: 'SAVE WINC!',
    cancelTitle: 'CANCEL'
}