import React from 'react'
import styled from 'styled-components'
import { fontLi } from '../../../common'
import { media } from '../../../../utils'
import { Title } from '../common/Title'


const SubTitle = styled(Title)`
  font-size: 25px;
  font-weight: 400;
  padding: 5px 0;
  align-self: flex-start;
  text-align: left;
  ${
    media.phone`
       font-size: 14px
    `
    }
`

const ListItem = styled(fontLi)`
  list-style-type: circle;
  list-style-position: inside;
  text-align: left;
  align-self: stretch;
  font-size: 21px;
  color: white;
  line-height: 1.7em;
  font-weight: 300;
  padding-left:30px;
   ${
    media.phone`
       font-size: 13px
       padding-left:15px;
    `
    }
`

export const Why = () => {
    const content = [
        "The manager's success is the result of the success of his/her team.",
        'Motivation drives the team to be successful.',
        'Recognitions and their communications to the team members will increase their motivations.',
        'Manager can also improve the team through the power of recognition.'
    ]
    return (
        <>
            <Title>{'Why we developed WinC?'}</Title>
            <SubTitle>{'We wanted to come up with a tool which helps Managers to be Successful in more efficient and effective manner. And we believe:'}</SubTitle>
            {
                content.map((c, i) => <ListItem key={i}>{c}</ListItem>)
            }
        </>
    )
}