export class Validator {
    static validateName = function (anyString) {
        return anyString.length > 0
    }

    static validatePassword = function (anyString) {
        let regex = /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9]+$)/
        return regex.test(anyString)
    }

    static validateEmail = function (anyString) {
        // eslint-disable-next-line
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(anyString.toString().toLowerCase())
    }

    static validateDate = function (anyDateString) {
        let regex = /^((((0[13578])|(1[02]))[/]?(([0-2][0-9])|(3[01])))|(((0[469])|(11))[/]?(([0-2][0-9])|(30)))|(02[/]?[0-2][0-9]))[/]?\d{4}$/
        return regex.test(anyDateString)
    }

    static validatePhone = function (anyString) {
        let regex = /^\d{10}$|^\d{3}-\d{3}-\d{4}$/
        return regex.test(anyString)
    }

    static capitalized = (anyString) => {
        let str = anyString.replace('-', ' ')
        return str.capitalizedSentence()
    }

    static capitalizedSentence = function (anyString) {
        let words = []
        let elements = anyString.split(' ')
        for (let anyElement of elements) {
            words.push(anyElement.charAt(0).toUpperCase() + anyElement.slice(1).toLowerCase())
        }
        return words.join(' ')
    }
}
