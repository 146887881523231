import { CategoriesService } from "../services"

export const SELECT_CATEGORY = 'team-level-category-service/SELECT_CATEGORY'
export const RESET_SELECTED_CATEGORY = 'team-level-category-service/RESET_SELECTED_CATEGORY'

export const listAllCategories = () => {
    console.log('Fetching Category...')
    return {
        type: 'category-service/LIST_CATEGORIES',
        payload: {
            request: CategoriesService.listCategories()
        }
    }
}

export const addCategory = (category) => {
    console.log('Adding Category...' + category)
    return {
        type: 'category-service/ADD_CATEGORY',
        payload: {
            request: CategoriesService.addCategory(category)
        }
    }
}

export const updateCategory = (category) => {
    console.log('Updating Category...' + category)
    return {
        type: 'category-service/UPDATE_CATEGORY',
        payload: {
            request: CategoriesService.updateCategory(category)
        }
    }
}

export const deleteCategory = (category) => {
    console.log('Deleting Category...' + category)
    return {
        type: 'category-service/DELETE_CATEGORY',
        payload: {
            request: CategoriesService.deleteCategory(category)
        }
    }
}

export const transferWincsToCategory = (transferInfo) => {
    return {
        type: 'category-service/TRANSFER_WINCS',
        payload: {
            request: CategoriesService.transferWincsToCategory(transferInfo)
        }
    }
}

export function getWincInfoForAllCategories() {
    return {
        type: 'category-service/WINC_INFO_ALL_CATEGORES',
        payload: {
            request: CategoriesService.getWincInfoForAllCategories()
        }
    }
}

export function getWincsInfoForIndividualCategory(categoryId) {
    return {
        type: 'category-service/WINC_INFO_INDIVIDUAL_CATEGORY',
        payload: {
            request: CategoriesService.getWincsInfoForIndividualCategory(categoryId)
        }
    }
}

export function getPeopleWithWincsFromCategory(categoryId) {
    return {
        type: 'category-service/PEOPLE_WITH_WINCS_FROM_CATEGORY',
        payload: {
            request: CategoriesService.getPeopleWithWincsFromCategory(categoryId)
        }
    }
}

export function selectTeamCategory(category) {
    return dispatch => dispatch({
        type: SELECT_CATEGORY,
        selectedCategory: category
    })
}

export function resetSelectedTeamCategory() {
    return dispatch => dispatch({
        type: RESET_SELECTED_CATEGORY,
    })
}

export const apiTest = () => {
    return {
        type: 'API_TEST',
        payload: {
            request: CategoriesService.testGetApi()
        }
    }
}