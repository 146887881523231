import React from 'react'
import { fontDiv, HexagonText } from "../../../common"
import styled from "styled-components"
import PropTypes from 'prop-types'
import { media } from '../../../../utils'

const Container = styled.div`
  display: flex;
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  box-sizing: border-box;
  position: relative;
`

const MobileOnly = styled.div`
  display: none;
  ${
    media.phone`
       display: unset;
    `
}
`

const NonMobileOnly = styled.div`
  display: unset;
  ${
    media.phone`
       display: none;
    `
}
`

const WincIndicator = styled(fontDiv)`
    width: ${p => p.size}px;
    height: ${p => p.size}px;
    background-color: #59dfb8;
    position: absolute;
    align-self: flex-end;
    border-radius: ${p => p.size / 2}px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
    font-size: 30px;
    box-sizing: border-box;
    bottom: 0;
    right: 0;
    ${
    media.phone`
        font-size: 18px;
        `
}
`


export const ProfilePhoto = (props) => {
    const {name = 'Sriram Reddy', wincCount = 50} = props
    return (
        <>
            <MobileOnly>
                <Container size={120}>
                    <HexagonText name={name} size={120} fontSize={30} fill={'#700cff'}/>
                    <WincIndicator size={40}>{wincCount}</WincIndicator>
                </Container>
            </MobileOnly>
            <NonMobileOnly>
                <Container size={200}>
                    <HexagonText name={name} size={200} fontSize={50} fill={'#700cff'}/>
                    <WincIndicator size={80}>{wincCount}</WincIndicator>
                </Container>
            </NonMobileOnly>
        </>

    )
}

ProfilePhoto.propTypes = {
    name: PropTypes.string.isRequired,
    wincCount: PropTypes.number.isRequired
}
