import { utcTimeStamp } from './DateUtil'

const userKey = 'user'

export class AuthCache {
    static saveToken = (token) => {
        localStorage.setItem(userKey, JSON.stringify(token))
    }

    static currentToken = () => {
        let token = localStorage.getItem(userKey)
        if (token) {
            token = JSON.parse(token)
            if (utcTimeStamp() > token.willExpireAt) {
                token = undefined
                AuthCache.clear()
            }
        }
        return token
    }

    static clear = () => {
        localStorage.removeItem(userKey)
    }
}