import React, { Component } from 'react'
import styled from "styled-components"
import { Clickable, fontDiv } from "../../../common"
import { Images } from "../../../../assets"
import PropTypes from "prop-types"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  box-shadow: 0 1px 5px 0 rgba(179, 168, 181, 0.23);
  background-color: #ffffff;
  padding: 15px;
  box-sizing: border-box;
`
const Title = styled(fontDiv)`
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5f5662;
`
const ImpactContainer = styled.div`
  display: flex;
  padding: 30px 0 30px 0;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  box-sizing: border-box;
  transition-duration: 2s;
`
const Impact = styled(Clickable)`
  display: flex;
  width: 156px;
  height: 60px;
  border-radius: 30px;
  background-color: #700cff;
  justify-content: center;
  transition-duration: 0.4s;
  transform: none;
  :active {
      border: none;
  }
`
const ImpactTitle = styled(fontDiv)`
    font-weight: 400;
    font-size: 13px;
    color: #ffffff;
`

const Logo = styled.img`
  height: 50px;
  width: auto;
  transition-duration: 0.4s;
`

export class SelectImpact extends Component {
    _renderContent = () => {
        const {list = [], selected = {}, onSelectImpact} = this.props
        return list.map(s => {
            let isSelected = s.impact === selected.impact
            return (
                <Impact key={s.impact}
                        onClick={() => onSelectImpact(s)}>
                    {
                        isSelected ? <Logo src={Images.checkmark}/> :
                            <ImpactTitle>{s.impact.toUpperCase()}</ImpactTitle>
                    }
                </Impact>
            )
        })
    }

    render() {
        return (
            <Container>
                <Title>{'Select an impact'}</Title>
                <ImpactContainer>
                    {
                        this._renderContent()
                    }
                </ImpactContainer>
            </Container>
        )
    }
}

SelectImpact.propTypes = {
    list: PropTypes.array.isRequired,
    selected: PropTypes.object,
    onSelectImpact: PropTypes.func.isRequired
}