import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GraphContainer } from "./common/GraphContainer"
import { SummaryRawGraph } from "./SummaryRawGraph"

const defaultData = undefined

class SummaryGraph extends Component {
    render() {
        let {data, isFlex, title, theme} = this.props
        return (
            <GraphContainer title={title} isFlex={isFlex} showIndicator={false}>
                <SummaryRawGraph data={data} theme={theme} enableAnimation={false}/>
            </GraphContainer>
        )
    }
}

SummaryGraph.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    isFlex: PropTypes.bool,
    theme: PropTypes.string
}

SummaryGraph.defaultProps = {
    title: undefined, data: defaultData, isFlex: false,
}

export { SummaryGraph }
