import React, { Component } from 'react'
import styled from 'styled-components'
import { CategoryHeader } from "./CategoryHeader"
import { SummaryRawGraph } from "../../graphs/SummaryRawGraph"
import { CategoryFooter } from "./CategoryFooter"

const CategoryContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    border-radius: 4px;
    background-color: white;
    height: 227px;
    width: 300px;
    box-sizing: border-box;
    box-shadow: 0 1px 5px 0 rgba(179, 168, 181, 0.23);
`

class WincCategory extends Component {
    render() {
        let {onClick, info, onClickPerson} = this.props
        const {category, totalWincs, people = [], totalImpact, totalRatio, totalScore} = info
        return (
            <CategoryContainer
                onClick={() => onClick(info)}>
                <CategoryHeader name={category}
                                wincCount={totalWincs}
                />
                <SummaryRawGraph data={{totalImpact, totalRatio, totalScore}}/>
                <CategoryFooter people={people} onClickPerson={onClickPerson}/>
            </CategoryContainer>
        )
    }
}

export { WincCategory }
