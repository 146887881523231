import React from 'react'
import styled from 'styled-components'
import { media } from '../../../../utils'

const MobileOnlyWrapper = styled.div`
  display: none;
  ${
    media.phone`
        display: unset;
        display: flex;
    `
}
`

export const MobileTitleDisplay = ({count}) => {
    return (
        <MobileOnlyWrapper>
            <svg x={0} y={0} width={100} height={100} fill={'#e045cb'}
                 alignmentBaseline={'middle'}>
                <svg viewBox="0 0 115 103">
                    <path
                        d="M2.006 47.457l23.651-42.08A10 10 0 0 1 34.375.275h47.063A10 10 0 0 1 90.1 5.279l23.577 40.87a10 10 0 0 1 0 9.993L90.1 97.012a10 10 0 0 1-8.662 5.003H34.222a10 10 0 0 1-8.603-4.903L2.121 57.454a10 10 0 0 1-.115-9.997z"/>
                </svg>
                <text x="50%" y="50%"
                      textAnchor="middle"
                      fill="white"
                      alignmentBaseline={'middle'}
                      fontFamily={'Ubuntu, monospace'} fontSize={12}
                      fontWeight={500}
                >
                    <tspan x="50%" y="50%" fontSize={25} fontWeight={'bold'}>{count}</tspan>
                    <tspan x="50%" y="68%">WinCs</tspan>
                </text>
            </svg>
        </MobileOnlyWrapper>
    )
}
