import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AuthCache } from "./AuthCache"

export const PrivateRoute = ({component: Component, ...rest}) => {
    return <Route {...rest} render={props => (
        AuthCache.currentToken()
            ? <Component {...props} />
            : <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
    )}/>
}