import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Clickable, CustomNumberFormat, fontDiv, InputTextField } from '../../../common'
import moment from 'moment'
import { Validator } from '../../../../utils/Validator'

const Container = styled.div`
  position: absolute;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 0 2px 2px rgba(154, 100, 230, 0.32);
  -webkit-box-shadow: 0 0 2px 2px rgba(154, 100, 230, 0.32);
  -moz-box-shadow: 0 0 2px 2px rgba(154, 100, 230, 0.32);
  z-index: 1002;
  max-width: 300px;
  display: ${({isOpen = false}) => isOpen ? `flex` : `none`};
  flex-direction: column;
  padding: 15px;
  margin: 45px 0 20px 0;
`

const HContainer = styled.div`
  height: 30px;
  display: flex;
  margin-top: 20px;
`

export const Title = styled(fontDiv)`
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5f5662;
`

const DateInput = styled(InputTextField)`
`

const Button = styled(Clickable)`
    border-radius: 31.5px;
    background-color: #700cff;
    color: white;
    font-size: 13px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    justify-content: center;
    transform: scale(1.0);
    transition: all 0.3s;
    flex: 1;  
    :active {
      transform: scale(0.95);
    }
    :disabled {
      opacity: 0.5;
    }
`

const SaveButton = styled(Button)`
  margin-left: 10px;
`
const CancelButton = styled(Button)`
  background-color: #f9f7fa;
  color: #700cff;
`
const dateFormat = "MM/DD/YYYY"
const maxYearsForReports = 5

export class CustomDatePicker extends Component {
    constructor(props) {
        super(props)
        const {toDate, fromDate} = props
        this.state = {
            toDate,
            fromDate,
            errors: {}
        }
    }

    _isValidDate = (date) => {
        return Validator.validateDate(date)
    }

    _isPastDate = date => {
        return moment(date, dateFormat).isBefore(new Date(), 'day')
    }

    _isDateWithInRange = (date, maxYears) => {
        let diff = moment().diff(moment(date, dateFormat), 'year')
        console.log(diff)
        return diff < maxYears
    }

    _findDiff = (from, to) => {
        return moment(to, dateFormat).diff(moment(from), 'day', true)
    }

    _errorMessage = (inputType, value) => {
        if (value.length === 0) {
            return ''
        }

        if (!this._isValidDate(value)) {
            return 'Please enter valid date'
        }

        if (!this._isPastDate(value)) {
            return 'Date cannot be in the future'
        }

        if (!this._isDateWithInRange(value, maxYearsForReports)) {
            let currentYear = (new Date()).getFullYear()
            return `Date cannot be before ${currentYear - maxYearsForReports + 1}`
        }

        const {fromDate} = this.state
        if (inputType === 'toDate') {
            let diff = this._findDiff(fromDate, value)
            if (Number.isNaN(diff)) {
                return ''
                //return 'Please enter valid from Date.'
            }

            if (diff < 0) {
                return "To date cannot be before From date."
            }
        }
        return ''
    }

    _onChangeText = e => {
        if (!e.isTrusted) return
        let {name, value} = e.target
        let errors = this.state.errors
        errors[name] = this._errorMessage(name, value)
        this.setState({
            [name]: value,
            errors: {...errors}
        })
    }

    _onSaveDateRange = () => {
        const {onSave} = this.props
        const {fromDate, toDate} = this.state
        onSave(fromDate, toDate)
    }

    render() {
        const {errors, fromDate, toDate} = this.state
        const {isOpen, onCancel} = this.props
        const disableSave = !Boolean(fromDate && toDate && !errors.fromDate && !errors.toDate)
        // console.log(fromDate, toDate, errors.fromDate, errors.toDate, disableSave)
        return (
            <Container isOpen={isOpen} onClick={e => e.stopPropagation()}>
                <Title>{'Please select any date range with in last 5 years.'}</Title>
                <DateInput
                    InputLabelProps={{
                        shrink: true,
                    }}
                    name={'fromDate'}
                    error={Boolean(errors.fromDate)}
                    label={'From'}
                    helperText={errors.fromDate ? errors.fromDate : ' '}
                    onChange={this._onChangeText}
                    value={fromDate}
                    InputProps={{
                        inputComponent: CustomNumberFormat,
                    }}
                />
                <DateInput
                    InputLabelProps={{
                        shrink: true,
                    }}
                    name={'toDate'}
                    error={Boolean(errors.toDate)}
                    label={'To'}
                    helperText={errors.toDate ? errors.toDate : ' '}
                    onChange={this._onChangeText}
                    value={toDate}
                    InputProps={{
                        inputComponent: CustomNumberFormat,
                    }}
                />
                <HContainer>
                    <CancelButton onClick={onCancel}>Cancel</CancelButton>
                    <SaveButton
                        disabled={disableSave}
                        onClick={this._onSaveDateRange}>Save
                    </SaveButton>
                </HContainer>
            </Container>
        )
    }
}

CustomDatePicker.propTypes = {
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}
