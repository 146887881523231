import { bindActionCreators } from "redux"
import * as userActions from "../../../actions/user"
import { connect } from 'react-redux'
import { AddEditPersonPage } from "./AddEditPersonPage"
import { WithLoaderContext } from '../../WithLoaderContext'

const mapStateToProps = (state) => ({
    selectedUser: state.selectedUser
})


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(userActions, dispatch)
}

export const ConnectedAddPersonPage = connect(mapStateToProps, mapDispatchToProps)(WithLoaderContext(AddEditPersonPage))