import styled from 'styled-components'
import { Clickable } from '../../../common'

export const ActionButton = styled(Clickable)`
  height: 40px;
  border-radius: 20px;
  background-color: ${p => p.transparent ? 'transparent' : p.theme.primary};
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${p => p.transparent ? p.theme.primary : `#fff`};
  display: flex;
  justify-content: center;
  align-self: stretch;
  margin: 5px 30px;
  opacity: ${p => p.disabled ? 0.3 : 1.0};
  pointer-events: ${p => p.disabled ? 'none' : 'unset'};
  
`