export * from './StringUtils'
export * from './DateUtil'
export * from './showError'
export * from './Media'
export * from './sorting'

export const roundValue = (number, decimals = 1) => {
    return Number(Math.round(number + 'e' + decimals) + 'e-' + decimals)
}

export const IS_DEV = process.env.NODE_ENV === 'development'

