import { getColor } from '../../../../theme'
import React from 'react'

export const Hexagon = ({index, size = 40}) => {
    return (
        <svg x={0} y={0} width={size} height={size} fill={getColor(index)} strokeWidth={10}>
            <svg viewBox="0 0 31 35">
                <path
                    d="M17.158 1.442l11.343 6.443a4 4 0 0 1 2.024 3.478v12.863a4 4 0 0 1-1.985 3.455l-11 6.413a4 4 0 0 1-4.03 0l-11-6.413a4 4 0 0 1-1.985-3.455V11.302A4 4 0 0 1 2.47 7.87l10.657-6.382a4 4 0 0 1 4.031-.046z"/>
            </svg>
        </svg>
    )
}
